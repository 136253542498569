import { post } from "../adapters/apiAdapter";
import { SQLDate } from "../shared/types/SQLDate";

export type PostProjectRequestRequestBody = {
    name: string,
    date: SQLDate,
    projectId: string,
    projectName: string,
    projectType: string,
    additionalInformation: string
}

export async function postProjectRequest(requestBody: PostProjectRequestRequestBody) {
    return await post(`/api/request-project`, requestBody);
}