import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import '../../../styles/calendar.scss';
import { addMonth, subtractMonth, firstOfMonth, getStartDate, getEndDate } from '../../../shared/functions/dateAndTime';
import { getLogs, GetLogsRequestBody } from "../../../api/logs";


import CalendarDay from './CalendarDay';
import CustomToolbar from '../../calendar/CustomToolbar';
import { ProjectCollection } from "../../../shared/types/Project";
import { Logs } from "../../../shared/types/Logs";

const localizer = momentLocalizer(moment);

interface EmployeeCalendarProps {
    employeeId: number,
    allProjects: ProjectCollection
}

const EmployeeCalendar: React.FC<EmployeeCalendarProps> = ({ employeeId, allProjects }) => {
    const [ date, setDate ] = useState<Date>(firstOfMonth(new Date()));
    const [ logs, setLogs ] = useState<Logs>({})

    useEffect(() => {
        const getLogsAsync = async () => {
            const requestBody: GetLogsRequestBody = {
                id: employeeId,
                startDate: getStartDate(date),
                endDate: getEndDate(date)
            }

            const result = await getLogs(requestBody);
            setLogs(result);
        }
        console.log("Getting logs")
        getLogsAsync();
    }, [date, employeeId]);

    const goToPrevMonth = () => {
        console.log(`SETTING VIEW TO PREVIOUS MONTH: ${subtractMonth(date)}`)
        setDate(subtractMonth(date));
    }

    const goToNextMonth = () => {
        console.log(`SETTING VIEW TO NEXT MONTH: ${addMonth(date)}`)
        setDate(addMonth(date));
    }

    return (
        <Calendar
            localizer={localizer}
            date={date}
            onNavigate={() => {}}
            defaultView="month"
            events={[]}
            components={{
                dateCellWrapper: (props) => {
                    return (
                        <CalendarDay props={props} logs={logs} calendarMonth={date.getMonth()} allProjects={allProjects} />
                    );
                },
                month: {
                    dateHeader: () => {
                        return <></>
                    }
                },
                toolbar: (props) => {
                    return (
                        <CustomToolbar props={props} goToPrevMonth={goToPrevMonth} goToNextMonth={goToNextMonth} />
                    )
                }
            }}
            className="employee-calendar"
        />
    )
}

export default EmployeeCalendar;