import React, { FunctionComponent } from "react";
import styled from 'styled-components';
import { Dayjs } from "dayjs";

import Column from "../atoms/Column";
import { EntryHeading } from "../atoms/EntryHeading";
import { FLUIDRA_COLORS } from "../../values/colors";
import { SQLTime, convertStringToSQLTime } from "../../shared/types/SQLTime";


interface TimePickerProps {
    time: SQLTime
    setTime: React.Dispatch<React.SetStateAction<SQLTime>>,
    heading: string
}

const TimePicker: FunctionComponent<TimePickerProps> = ({ time, setTime, heading="Time" }) => {

    const onChange = (time: string): void => {
        time += ':00'
        console.log(time)
        setTime(time as SQLTime)
    }

    return (
        <Column gap={4}>
            <EntryHeading showHeading={true} headingText={heading} />
            <TimePickerStyle type="time" value={time} onChange={(e) => onChange(e.target.value)} />
        </Column>
    )
}

//#region Styles
const TimePickerStyle = styled.input`
    height: 40px;
    width: 288px;
    border: none;
    border-radius: 20px;
    outline: 1px solid ${FLUIDRA_COLORS.darkblue_100};
    padding: 0px 16px;
    &:hover {
        outline: 1px solid ${FLUIDRA_COLORS.darkblue_500};
    }
    &:focus-visible {
        outline: 2px solid ${FLUIDRA_COLORS.teal_500};
    }
`

//#endregion

export default TimePicker;