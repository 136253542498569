import React from "react";
import styled from 'styled-components';

import { COLORS } from '../../values/colors';

interface CustomToolbarProps {
    props: any,
    goToPrevMonth: any,
    goToNextMonth: any
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({props, goToPrevMonth, goToNextMonth}) => {
    // const leftArrow = require('../../resources/left-arrow.png');
    // const rightArrow = require('../../resources/right-arrow.png');

    const leftArrow = require('../../resources/left-arrow-primary.png');
    const rightArrow = require('../../resources/right-arrow-primary.png');

    const prevButtonPressed = () => {
        goToPrevMonth();
    }

    const nextButtonPressed = () => {
        goToNextMonth();
    }

    return (
        <Toolbar>
            <NavigateMonthsButton onClick={() => prevButtonPressed()}>
                <LeftArrow src={leftArrow} />
            </NavigateMonthsButton>
            
            <Month>{props.label}</Month>
            
            <NavigateMonthsButton onClick={() => nextButtonPressed()}>
                <RightArrow src={rightArrow} />
            </NavigateMonthsButton>
        </Toolbar>
    );
}


//#region Styles
const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    height: 50px;
`;

const Month = styled.span`
    font-size: 32px;
    font-weight: bold;
    width: 300px;
    text-align: center;
`;

const NavigateMonthsButton = styled.button`
    height: 40px;
    width: 40px;
    border-radius: 100px;
    border: 0px;
    background-color: ${COLORS.secondaryGray3};
    align-self: center;
    cursor: pointer;
    &:hover {    
        background-color: ${COLORS.secondaryGray2};
    }
`;

const Arrow = styled.img`
    height: 15px;
    width: 15px;
    margin-top: 2px;
`;

const LeftArrow = styled(Arrow)`
    margin-left: -3px;
`;

const RightArrow = styled(Arrow)`
    margin-left: 3px;
`;
//#endregion

export default CustomToolbar;