import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

interface LargeSwitchProps {
    id: string,
    checked: boolean,
    onChange: any,
    label: string
}

export const LargeSwitch: React.FC<LargeSwitchProps> = ( {id, checked, onChange, label} ) => {
    return (
        <SwitchContainer>
            <SwitchLabel>{label}</SwitchLabel>
            <Switch
                id={id}
                checked={checked}
                onChange={onChange}
                checkedIcon={<></>}
                uncheckedIcon={<></>}
                onColor="#44AF69"
                height={40}
                width={70}
                handleDiameter={30} />
        </SwitchContainer>
    );
}

// #region Styles
const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 30px;
`;

const SwitchLabel = styled.span`
    font-size: 16pt;
    margin-right: 20px;
`;
// #endregion