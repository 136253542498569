import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Employee, EmployeeCollection } from '../../shared/types/Employee';
import { Project, ProjectCollection } from '../../shared/types/Project';
import { CapitalisationGroup, CapitalisationGroupCollection } from '../../shared/types/CapitalisationGroup';
import { COLORS } from '../../values/colors'
import { Card, Separator, ScrollableAreaFadeTop, ScrollableAreaFadeBottom } from './CommonStyles';
import { BlueButton } from './Buttons';
import '../../styles/scrollbar.scss';
import { ItemListTypes } from '../../shared/enums/itemListTypes';
import { sortByFluidraProjectId } from '../../shared/functions/projects';
import { sortByFluidraEmployeeId } from '../../shared/functions/employees';
import { sortByFluidraCapitalisationGroupId } from '../../shared/functions/capitalisationGroups';

interface ItemListProps {
    itemType: ItemListTypes,
    items: ProjectCollection | EmployeeCollection | CapitalisationGroupCollection,
    itemClicked: (id: any) => void,
    selectedItem: any,
    heightAdjustment: number,
    hasBottomButton: boolean,
    bottomButtonClicked: any
}

const ItemList: React.FC<ItemListProps> = ({ itemType, items, itemClicked, selectedItem, heightAdjustment, hasBottomButton, bottomButtonClicked }) => {
    const scrollableArea = useRef(null);
    const [ scrollFadeTopPosition, setScrollFadeTopPosition ] = useState(-20);

    const listHeight = hasBottomButton ? 
        `calc(100vh - ${heightAdjustment}px)` : 
        `calc(100vh - ${heightAdjustment - 81}px)`

    // Add an event listener to track the scroll position
    useEffect(() => {
        const handleScroll = () => {
            const scrollable: any = scrollableArea.current;
            if (scrollable) setScrollFadeTopPosition(Math.min(0, scrollable.scrollTop - 20));
        };

        const scrollable: any = scrollableArea.current;
        if (scrollable) scrollable.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollable) scrollable.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const sortById = (items: ProjectCollection | EmployeeCollection | CapitalisationGroupCollection) => {
        switch(itemType) {
            case 'Employee':
                return sortByFluidraEmployeeId(Object.values(items as EmployeeCollection))
            case 'Project':
                return sortByFluidraProjectId(Object.values(items as ProjectCollection))
            case 'Capitalisation Group':
                return sortByFluidraCapitalisationGroupId(Object.values(items as CapitalisationGroupCollection))
        }
    } 

    const getFluidraId = (item: any) => {
        switch(itemType) {
            case 'Employee':
                return item.fluidraEmployeeId;
            case 'Project':
                return item.fluidraProjectId;
            case 'Capitalisation Group':
                return item.fluidraCapitalisationGroupId;
        }
    }
    
    const getName = (item: any) => {
        return (itemType === 'Employee') ? `${item.firstName} ${item.lastName}` : `${item.name}`
    }

    return (
        <ItemListCard className='scrollableList'>
            <ItemListArea $listHeight={listHeight}>
                <ScrollableAreaFadeTop $position={scrollFadeTopPosition} />
                <ItemListStyle ref={scrollableArea}>
                    {sortById(items).map((item, index) => (
                    <li key={index}>
                        <ItemButton 
                            $isSelected={selectedItem === item.id.toString()}
                            onClick={() => {itemClicked(item.id)}}>
                            <ItemId>{getFluidraId(item)}</ItemId>
                            <ItemName>{getName(item)}</ItemName>
                        </ItemButton>
                    </li>
                    ))}
                    <Spacer />
                </ItemListStyle>
                <ScrollableAreaFadeBottom />
            </ItemListArea>
            {hasBottomButton ? 
                <Separator />
            : null }
            {hasBottomButton ? 
            <BlueButton 
                onClick={bottomButtonClicked} 
                buttonText={`Add new ${itemType}`} />
            : null }
        </ItemListCard>
    );
}

//#region Styles
const ItemListCard = styled(Card)`
    padding: 20px 6px 20px 20px;
`;

const ItemListArea = styled.div<{$listHeight: string}>`
    position: relative;
    left: 0px;
    width: 284px;
    height: ${props => props.$listHeight};
`;

const ItemListStyle = styled.ul`
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    background-color: white;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
`;

const ItemButton = styled.div<{$isSelected: boolean}>`
    border-radius: 20px;
    margin-bottom: 8px;
    height: 40px;
    width: 250px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${COLORS.secondaryGray3};
    border: ${props => 
        props.$isSelected
            ? `3px solid ${COLORS.primary}`
            : `0px solid ${COLORS.primary}`};
    padding: ${props => 
        props.$isSelected
            ? '5px 7px'
            : '8px 10px'};
    &:hover {
        padding: ${props => 
            props.$isSelected
                ? '5px 7px'
                : '6px 8px'};
        border: ${props => 
            props.$isSelected
                ? `3px solid ${COLORS.primary}`
                : `2px solid ${COLORS.primary}`};
    }
`;

const ItemId = styled.span`
  font-weight: bold;
  margin-right: 10px;
  user-select: none;
`;

const ItemName = styled.span`
  user-select: none;
  text-align: left;
`;

const Spacer = styled.li`
    height: 10px;
`;

//#endregion

export default ItemList;