import React from 'react';
import styled from 'styled-components';
import '../../../styles/table.scss';

interface TableProps {
    // tHeadData: {id: string, name: string, hours: string},
    tHeadData: string[],
    tBodyData: {id: string, name: string, hours: number}[]
    tColumnWidths: {id: number, name: number, hours: number}
}

const Table: React.FC<TableProps> = ({tHeadData, tBodyData, tColumnWidths}) => {

    const capsHeading = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <table id="table">
            <colgroup>
                <col style={{width: `${tColumnWidths.id}px`}}></col>
                <col style={{width: `${tColumnWidths.name}px`}}></col>
                <col style={{width: `${tColumnWidths.hours}px`}}></col>
            </colgroup>
            <thead>
                <tr>
                    {tHeadData.map(heading => (
                        <th key={heading}>{capsHeading(heading)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {tBodyData.map((row, index) => (
                    <tr key={index}>
                        <td key="id">{row.id}</td>
                        <td key="name">{row.name}</td>
                        <td key="hours">{row.hours}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default Table;