import React, { useState, useEffect, useReducer, FunctionComponent } from "react";
import { DragDropContext } from '@hello-pangea/dnd';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getAllProjects } from "../api/projects";
import { getFavouriteProjects } from "../api/favourites";
import { GetLogsRequestBody, PostLogsRequestBody, getLogs, postLog } from "../api/logs";
import { generateDatesList, formatDateForSQL, isWeekend, getMonthName } from '../shared/functions/dateAndTime';
import { delay } from '../shared/functions/functions';
import DateList from "../components/pages/LoggingPageComponents/DateList";
import LoggingColumn from '../components/pages/LoggingPageComponents/LoggingColumn';
import FavouriteProjectsList from '../components/pages/LoggingPageComponents/FavouriteProjectsList';
import ConfirmNavigationPopup from "../components/common/ConfirmNavigationPopup";
import { useAuth } from '../authprovider'
import { Logs, LogItem } from "../shared/types/Logs";
import { ProjectCollection } from "../shared/types/Project";
import { ActionType, initialState, reducer } from '../components/pages/LoggingPageComponents/logging.reducer'
import { GLOBALS } from "../values/globals";
import { SQLDate } from "../shared/types/SQLDate";

interface LoggingPageProps {
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    actingAs: number | null;
}

const LoggingPage: FunctionComponent<LoggingPageProps> = ({ setUnsavedChanges, actingAs }) => {
    const auth = useAuth();
    const { date } = useParams();

    const [ state, dispatch ] = useReducer(reducer, initialState);

    const [ datesList, setDatesList ] = useState<Date[]>([]);
    const [ allProjects, setAllProjects ] = useState<ProjectCollection>({});
    const [ favouriteProjects, setFavouriteProjects ] = useState<number[]>([]);
    const [ logs, setLogs ] = useState<Logs>({});
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ shouldPostLog, setShouldPostLog ] = useState<boolean>(false);

    const navigate = useNavigate();

    // Runs on first load
    const loadPage = () => {
        const datesList = generateDatesList(new Date(date!));
        setDatesList(datesList);

        getAllProjectsAsync();
        getFavouriteProjectsAsync();
        getLogsAsync(datesList);
    }

    useEffect(() => {
        loadPage();

        // Cleanup function
        return () => setUnsavedChanges(false)
    }, [actingAs]);

    // Updates the state
    useEffect(() => {
        dispatch({
            type: ActionType.populate_state,
            allProjects: allProjects, 
            favouriteProjects: favouriteProjects, 
            logs: logs, 
            date: new Date(date!)
        })
    }, [allProjects, favouriteProjects, logs, date, datesList]);

    // Check that the dates list is correct for the selected date
    useEffect(() => {
        if (date && datesList.length > 0) {
            const formattedDatesList = datesList.map(date => String(formatDateForSQL(date)))
            if (!formattedDatesList.includes(date)) loadPage();
        }
    }, [date, datesList])


    // Tells App.js that there are unsaved changes
    useEffect(() => {
        setUnsavedChanges(state.unsavedChanges)
    }, [state.unsavedChanges, setUnsavedChanges])

    // #region API Calls
    const getAllProjectsAsync = async () => {
        try {
            setAllProjects(await getAllProjects());
        } catch (error) {
            console.log(error);
        }
    }
    const getFavouriteProjectsAsync = async () => {
        try {  
            const id = Number(actingAs ? actingAs : auth.user)
            var favouriteProjects = await getFavouriteProjects({id: id});
            setFavouriteProjects(favouriteProjects);
        } catch (error) {
            console.log(error);
        }
    }
    const getLogsAsync = async (datesList: Date[]): Promise<Logs> => {
        try {
            const id = Number(actingAs ? actingAs : auth.user)
            const startDate = datesList[datesList.length - 1];
            const endDate = datesList[0];
            const requestBody: GetLogsRequestBody = {
                id: id, 
                startDate: formatDateForSQL(startDate), 
                endDate: formatDateForSQL(endDate)
            }
            const logs: Logs = await getLogs(requestBody);
            setLogs(logs);
            return logs;
        } catch (error) {
            console.error(error);
            return {}
        }
    }
    // #endregion
  
    // #region Button Handlers
    const handleSelectDate = async (date: Date) => {
        const url = `/logging/${formatDateForSQL(date)}`
        ConfirmNavigationPopup(url, state.unsavedChanges, navigate)
    };

    const goToCalendar = (skipConfirmation: boolean = false) => {
        const dateObj = new Date(date!);
        const month = getMonthName(dateObj.getMonth() + 1);
        const year = dateObj.getFullYear();
        const url = `/logging/calendar/${month}/${year}`
        if (skipConfirmation) navigate(url)
        else ConfirmNavigationPopup(url, state.unsavedChanges, navigate)
    };

    const updateNoLog = () => {
        dispatch({
            type: ActionType.toggle_no_log
        })
        setShouldPostLog(true)
    };

    useEffect(() => {
        if (shouldPostLog) {
            console.log()
            postLogAsync().then(() => {
                setShouldPostLog(false)
            });
        }
    }, [shouldPostLog])

    const updateLeave = () => {
        dispatch({
            type: ActionType.toggle_leave
        })
    };

    const updatePublicHoliday = () => {
        dispatch({
            type: ActionType.toggle_public_holiday
        })
    }
    
    const postLogAsync = async () => {
        var loggedProjects = [...state.columns.logging_column.projectIds];
        loggedProjects = loggedProjects.filter(projectId => state.projects[Number(projectId)].hours !== 0 || Number(projectId) === 0 ||  Number(projectId) === -2)

        const employeeId = Number(actingAs ? actingAs : auth.user)
        const logDate: SQLDate = formatDateForSQL(new Date(date!))

        var logs: LogItem[] = loggedProjects.map(id => ({
            date: logDate,
            fluidraEmployeeId: employeeId,
            hours: state.projects[id].hours!,
            projectId: Number(id),
            notes: state.projects[id].notes ?? ''
        }))

        const requestBody: PostLogsRequestBody = {
            id: employeeId,
            date: formatDateForSQL(new Date(date!)),
            logs: logs
        }

        setLoading(true)

        // Add a minimum loading time for user feedback
        await Promise.all([
            postLog(requestBody),
            delay(GLOBALS.buttonLoadTime)
        ]);

        setLoading(false)

        // Check if there are other unlogged days in the week
        const updatedLogs = await getLogsAsync(datesList);
        const loggedDates = Object.keys(updatedLogs);
        const unloggedDates = datesList
            .filter(date => !loggedDates.includes(formatDateForSQL(date)))
            .filter(date => !isWeekend(date))
            .map(date => formatDateForSQL(date))
            .sort();
        
        if (unloggedDates.length > 0) {
            console.log("There are more days to log in this week");
            navigate(`/logging/${unloggedDates[unloggedDates.length - 1]}`);
        } else {
            console.log("There are no more days to log in this week");
            setUnsavedChanges(false);
            goToCalendar(true);
        }

        await getLogsAsync(datesList);
    };
    // #endregion

    // Handle drag and drop
    const onDragEnd = (result: any) => {
        dispatch({
            type: ActionType.project_dragged,
            result: result
        })
    };

    // Handle update to project hours
    const updateHours = (id: number, hours: number) => {
        dispatch({
            type: ActionType.update_project_hours,
            projectId: id,
            hours: hours/2
        })
    };

    // Handle update to project notes
    const updateNotes = (id: number, notes:string) => {
        dispatch({
            type: ActionType.update_project_notes,
            projectId: id,
            notes: notes
        })
    }

    return (
        <Page>
            <div style={{'gridColumn': '2'}}>
                <DateList 
                    dates={datesList} 
                    onSelectDate={handleSelectDate} 
                    selectedDate={new Date(date!)} 
                    calendarButtonClicked={goToCalendar}
                    logs={logs}
                    />
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                    <div style={{'gridColumn': '3'}}>
                        <LoggingColumn
                            key={'logging-column'}
                            date={new Date(date!)}
                            state={state}
                            updateHours={updateHours}
                            updateNotes={updateNotes}
                            hoursLogged={Object.values(state.projects).reduce((sum, project) => sum + (project.hours || 0), 0)}
                            logClicked={postLogAsync}
                            loading={loading}
                            updateNoLog={updateNoLog}
                            updateLeave={updateLeave}
                            updatePublicHoliday={updatePublicHoliday} />
                    </div>
                    <div style={{'gridColumn': '4'}}>
                        <FavouriteProjectsList 
                            key={'favourite-projects-column'}
                            state={state}
                            date={new Date(date!)}
                            isDragDisabled={[0, -2].some(i => state.columns.logging_column.projectIds.includes(i))} />
                    </div>
            </DragDropContext>
        </Page>
    );
}

// #region Styles
const Page = styled.div`
    display: grid;
    grid-template-columns: 1fr 330px minmax(Auto, 800px) 330px 1fr;
    width: 100%;
    padding: 10px;
`;
// #endregion

export default LoggingPage;