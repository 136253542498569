import { CapitalisationGroup, CapitalisationGroupCollection } from "../types/CapitalisationGroup";
import { DropdownItems } from "../types/DropdownItems";

export function getCapitalisationGroupName(capitalisationGroups: CapitalisationGroupCollection, id: number) {
    if (id === 0) return "None";
    if (id in capitalisationGroups) return capitalisationGroups[id].name;
    else return "Name not found";
}

export function getFluidraCapitalisationGroupId(capitalisationGroups: CapitalisationGroupCollection, id: number) {
    if (id === 0) return "";
    if (id in capitalisationGroups) return capitalisationGroups[id].fluidraCapitalisationGroupId;
    else return "Id unknown";
}

export const sortByFluidraCapitalisationGroupId = (list: CapitalisationGroup[]) => [...list].sort((a, b) => a.fluidraCapitalisationGroupId > b.fluidraCapitalisationGroupId ? 1 : -1);

export const convertCapitalisationGroupsToDropdownItems = (capitalisationGroups: CapitalisationGroupCollection): DropdownItems => {
    const dropdownItems: DropdownItems = {0: "None"}
    Object.values(capitalisationGroups).forEach(group => dropdownItems[group.id] = group.name)
    return dropdownItems;
}
