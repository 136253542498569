import React from 'react';
import styled from 'styled-components';

interface EntryHeadingProps {
    showHeading: boolean,
    headingText?: string
}

export const EntryHeading: React.FC<EntryHeadingProps> = ({ showHeading, headingText }) => {   
    return showHeading ? <Heading>{headingText}</Heading> : <></>
}

const Heading = styled.span`
    font-size: 14px;
    font-weight: bold;
`;