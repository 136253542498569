import React from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { TabBar, TabButton, Separator } from '../components/common/TabBar';
import ConfirmNavigationPopup from '../components/common/ConfirmNavigationPopup';
import { useAuth } from '../authprovider';

interface AdminDashboardProps {
    unsavedChanges: boolean,
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
}

const AdminDashboard: React.FC<AdminDashboardProps> = ({ unsavedChanges, setUnsavedChanges }) => {
    // const projectsImage = require('../resources/projects.png');
    // const employeesImage = require('../resources/employees.png');
    // const viewLogsImage = require('../resources/view-logs.png');
    // const summaryImage = require('../resources/summary.png');
    // const dollarImage = require('../resources/dollar.png');
    // const downloadImage = require('../resources/download.png');
    
    const auth = useAuth();

    const navigate = useNavigate();

    const location = useLocation();

    const employeeId = useAuth().user;

    return (
        <Page>
            <TabBar>
                <Separator />
                <TabButton
                    text="Summary"
                    onClick={() => { ConfirmNavigationPopup('/admin/summary', unsavedChanges, navigate) }}  
                    isSelected={location.pathname.startsWith('/admin/summary')} />
                <Separator />
                <TabButton
                    text="View Logs"
                    onClick={() => { ConfirmNavigationPopup(`/admin/view-logs/${employeeId}`, unsavedChanges, navigate) }}  
                    isSelected={location.pathname.startsWith('/admin/view-logs')} />
                <Separator />
                {(auth.isAdmin === 1) && (
                    <>
                        <TabButton
                            text="Projects"
                            onClick={() => { ConfirmNavigationPopup('/admin/projects', unsavedChanges, navigate) }}  
                            isSelected={location.pathname.startsWith('/admin/projects')} />
                        <Separator />
                        <TabButton
                            text="Employees"
                            onClick={() => { ConfirmNavigationPopup('/admin/employees', unsavedChanges, navigate) }}  
                            isSelected={location.pathname.startsWith('/admin/employees')} />
                        <Separator />
                        <TabButton
                            text="Capitalisation Groups"
                            onClick={() => { ConfirmNavigationPopup('/admin/capitalisation-groups', unsavedChanges, navigate) }}  
                            isSelected={location.pathname.startsWith('/admin/capitalisation-groups')} />
                        <Separator />
                        <TabButton
                            text="Generate Report"
                            onClick={() => { ConfirmNavigationPopup('/admin/reports', unsavedChanges, navigate) }}  
                            isSelected={location.pathname.startsWith('/admin/reports')} />
                        <Separator />
                    </>
                )}
                
            </TabBar>
            <Outlet />
        </Page>
    )
}

// #region Styles
const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;
// #endregion

export default AdminDashboard;