import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { COLORS } from '../../values/colors'
import { Link } from 'react-router-dom';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

export const Page = styled.div`
    diplay: flex;
`;

export const Card = styled.div`
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    margin: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
`;

export const Separator = styled.hr`
    background: ${COLORS.primary4};
    height: 2px;
    border: none; 
    margin: 10px 24px 10px 10px;
`;

export const Title = styled.h1`
    align-self: center;
    margin: 0px 0px 20px 0px;
`;

export const Heading = styled.h2`
    margin: 20px 10px 10px 10px;
`;

export const Instructions = styled.span`
    font-size: 14pt;
    align-self: left;
    // margin-bottom: 20px;
`;

export const SoftCard = styled.div`
    background-color: ${COLORS.secondaryGray4};
    padding: 20px;
    border-radius: 20px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

// #region Scrollable Area
export const ScrollableAreaFade = styled.div`
    position: absolute;
    width: 270px;
    height: 20px;
`;

export const ScrollableAreaFadeTop = styled(ScrollableAreaFade)<{$position: number}>`
    top: ${props => props.$position}px;
    background: linear-gradient(white, transparent);
`;

export const ScrollableAreaFadeBottom = styled(ScrollableAreaFade)`
    bottom: 0px;
    background: linear-gradient(transparent, white)
`;
// #endregion