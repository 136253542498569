import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ItemList from '../components/common/ItemList';
import CapitalisationGroupWindow from '../components/pages/AdminCapitalisationGroupsPageComponents/CapitalisationGroupWindow';
import SearchBar from '../components/common/SearchBar';
import { getAllProjects } from '../api/projects';
import { getCapitalisationGroups } from '../api/capitalisationGroups';

import {
    CapitalisationGroupListActionType,
    initialCapitalisationGroupListState,
    capitalisationGroupListReducer
} from '../components/pages/AdminCapitalisationGroupsPageComponents/capitalisationGroupList.reducer';
import ConfirmNavigationPopup from '../components/common/ConfirmNavigationPopup';
import { ProjectCollection } from '../shared/types/Project';
import { CapitalisationGroupCollection } from '../shared/types/CapitalisationGroup';

interface CapitalisationGroupPageProps {
    unsavedChanges: boolean,
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
}

const CapitalisationGroupsPage: React.FC<CapitalisationGroupPageProps> = ({ unsavedChanges, setUnsavedChanges }) => {
    const [ capitalisationGroups, setCapitalisationGroups] = useState<CapitalisationGroupCollection>({});
    const [ allProjects, setAllProjects] = useState<ProjectCollection>({});
    
    const [ textFilter, setTextFilter ] = useState<string>('');

    const [ state, dispatch ] = useReducer(capitalisationGroupListReducer, initialCapitalisationGroupListState);

    const [ triggerEffect, setTriggerEffect ] = useState<boolean>(false)

    const location = useLocation();
    const navigate = useNavigate();

    // Runs on first load    
    useEffect(() => {      
        return () => setUnsavedChanges(false)
    }, []);

    useEffect(() => {
        getCapitalisationGroupsAsync();
        getAllProjectsAsync();
    }, [triggerEffect]);

    useEffect(() => {
        dispatch({
            type: CapitalisationGroupListActionType.populate_list,
            allCapitalisationGroups: capitalisationGroups
        })
    }, [capitalisationGroups])

    useEffect(() => {
        dispatch({
            type: CapitalisationGroupListActionType.filter,
            textFilter: textFilter
        })
    }, [textFilter])

    // #region API Callers
    const getCapitalisationGroupsAsync = async () => setCapitalisationGroups(await getCapitalisationGroups());
    const getAllProjectsAsync = async () => setAllProjects(await getAllProjects());
    // #endregion

    const handleRefreshPage = () => {
        setTextFilter('');
        setTriggerEffect(prevState => !prevState);
    }

    // #region Button Handlers
    const capitalisationGroupClicked = (capitalisationGroupId: any) => {
        ConfirmNavigationPopup(`/admin/capitalisation-groups/${capitalisationGroupId}`, unsavedChanges, navigate);
    }

    const addNewCapitalisationGroupClicked = () => {
        ConfirmNavigationPopup(`/admin/capitalisation-groups`, unsavedChanges, navigate);
    }
    // #endregion
    
    return (
        <Page>
            <Row>
                <Column>
                    <SearchBar
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    <ItemList
                        itemType='Capitalisation Group'
                        items={state.filteredCapitalisationGroups}
                        itemClicked={capitalisationGroupClicked}
                        selectedItem={location.pathname.replace('/admin/capitalisation-groups/', '')}
                        heightAdjustment={398}
                        hasBottomButton={true}
                        bottomButtonClicked={addNewCapitalisationGroupClicked} />
                </Column>
                <CapitalisationGroupWindow 
                    capitalisationGroups={capitalisationGroups}
                    allProjects={allProjects}
                    unsavedChanges={unsavedChanges}
                    setUnsavedChanges={setUnsavedChanges}
                    refreshPage={handleRefreshPage} />
            </Row>
        </Page>
    );
}

//#region Styles
const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 200px);
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0px 10px;
    height: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
//#endregion

export default CapitalisationGroupsPage;