import { Employee, emptyEmployee } from "../../../shared/types/Employee";

// #region Actions
export enum EmployeeActionType {
    clear_employee,
    load_employee,
    property_changed,
}

interface ClearEmployeeActionType {
    type: EmployeeActionType.clear_employee;
}

interface LoadEmployeeActionType {
    type: EmployeeActionType.load_employee;
    employee: Employee;
}

interface PropertyChangedActionType {
    type: EmployeeActionType.property_changed;

    id?: Employee['id'];
    fluidraEmployeeId?: Employee['fluidraEmployeeId'];
    firstName?: Employee['firstName'];
    lastName?: Employee['lastName'];
    email?: Employee['email'];
    isAdmin?: Employee['isAdmin'];
    isManager?: Employee['isManager'];
    department?: Employee['department'];
    active?: Employee['active'];
    managerId?: Employee['managerId'];
}

type EmployeeAction = ClearEmployeeActionType | LoadEmployeeActionType | PropertyChangedActionType;
// #endregion

export interface State {
    employee: Employee,
    unsavedChanges: boolean,
    originalState: string
}

export const initialState: State = {
    employee: emptyEmployee,
    unsavedChanges: false,
    originalState: ""
}

const checkForChanges = (currentState: State): boolean => {
    return JSON.stringify(currentState.employee) !== currentState.originalState
}

export function employeeReducer(state: State, action: EmployeeAction): State {
    switch(action.type) {
        case EmployeeActionType.clear_employee:
            return {
                employee: emptyEmployee,
                unsavedChanges: false,
                originalState: JSON.stringify(emptyEmployee)
            }
        case EmployeeActionType.load_employee:
            return {
                employee: action.employee,
                unsavedChanges: false,
                originalState: JSON.stringify(action.employee)
            }
        case EmployeeActionType.property_changed:
            const updatedState = {
                ...state,
                employee: {
                    ...state.employee,
                    id: action.id !== undefined ? action.id : state.employee.id,
                    fluidraEmployeeId: action.fluidraEmployeeId !== undefined ? action.fluidraEmployeeId : state.employee.fluidraEmployeeId,
                    firstName: action.firstName !== undefined ? action.firstName : state.employee.firstName,
                    lastName: action.lastName !== undefined ? action.lastName : state.employee.lastName,
                    email: action.email !== undefined ? action.email : state.employee.email,
                    isAdmin: action.isAdmin !== undefined ? action.isAdmin : state.employee.isAdmin,
                    isManager: action.isManager !== undefined ? action.isManager : state.employee.isManager,
                    department: action.department !== undefined ? action.department : state.employee.department,
                    active: action.active !== undefined ? action.active : state.employee.active,
                    managerId: action.managerId !== undefined ? action.managerId : state.employee.managerId,
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        default:
            throw new Error(`Invalid employee action type.`)
    }
}