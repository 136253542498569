import React, { useEffect } from 'react'
import axios from 'axios'

const localAuthProvider = {
    isAuthenticated: false,
    signin(newUser: string, newPw: string, callback: (res: boolean, userid: string, email: string, firstName: string, lastName: string, isAdmin: string, isManager: string) => void) {
        axios.post('/auth/login', {
            username: newUser,
            password: newPw,
        }).then((response) => {
            if (response.data.hasOwnProperty('msg') && response.data['msg'] === 'User is inactive') {
                alert("Access Denied: Your account is inactive, please contact your manager to activate your account.")
                callback(false, '', '', '', '', '', '')
            } else if (response.status === 200) {
                callback(true, response.data.id, response.data.email, response.data.firstName, response.data.lastName, response.data.isAdmin, response.data.isManager)
            } else {
                callback(false, '', '', '', '', '', '')
            }
        }).catch(error => {
            callback(false, '', '', '', '', '', '')
        })
    },
    signout(callback: VoidFunction) {
        axios.post('/auth/logout', {}).then((response) => {
            callback()
        }).catch(error => {
            callback()
        })
    },
};

export interface AuthContextType {
    user: any;
    email: any;
    firstName: any;
    lastName: any;
    isAdmin: any;
    isManager: any;
    isRegisteredUser: any;
    signin: (user: string, password: string, callback: (res: boolean) => void) => void;
    signout: (callback: VoidFunction) => void;
}
  
let AuthContext = React.createContext<AuthContextType>(null!);
  
function AuthProvider({ children }: { children: React.ReactNode }) {
    let [ user, setUser ] = React.useState<any>(null);
    let [ email, setEmail ] = React.useState<any>(null);
    let [ firstName, setFirstName ] = React.useState<any>(null);
    let [ lastName, setLastName ] = React.useState<any>(null);
    let [ isAdmin, setIsAdmin ] = React.useState<any>(null);
    let [ isManager, setIsManager ] = React.useState<any>(null);
    let [ isRegisteredUser, setIsRegisteredUser ] = React.useState<any>(null);
  
    let signin = (newUser: string, newPw: string, callback: (res: boolean) => void) => {
        return localAuthProvider.signin(newUser, newPw, (res, userId, email, firstName, lastName, isAdmin, isManager) => {
            setUser(userId);
            setEmail(email);
            setFirstName(firstName);
            setLastName(lastName);
            setIsAdmin(isAdmin);
            setIsManager(isManager);
            setIsRegisteredUser(isRegisteredUser)
            callback(res);
        });
    };
  
    let signout = (callback: VoidFunction) => {
      return localAuthProvider.signout(() => {
        setUser(null);
        setEmail(null);
        setFirstName(null);
        setLastName(null);
        setIsAdmin(null);
        setIsManager(null);
        setIsRegisteredUser(null)
        callback();
      });
    };
  
    let value = { user, email, firstName, lastName, isAdmin, isManager, isRegisteredUser, signin, signout };

    useEffect(() => {
        axios.get('/auth/checkAuthentication').then((res) => {
            setUser(res.data.Id);
            setEmail(res.data.Email);
            setFirstName(res.data.FirstName);
            setLastName(res.data.LastName);
            setIsAdmin(res.data.IsAdmin);
            setIsManager(res.data.IsManager);
            setIsRegisteredUser(res.data.IsRegisteredUser)
        }).catch(error => {
            console.log(error)
            setUser(null);
            setEmail(null);
            setFirstName(null);
            setLastName(null);
            setIsAdmin(null);
            setIsManager(null);
            setIsRegisteredUser(null)
        })
    }, []);
  
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
    return React.useContext(AuthContext);
}

export {useAuth, AuthProvider}
