import { Project, ProjectCollection } from "../../../shared/types/Project";
import { Result } from "../../../shared/types/DragAndDropResult";
import { LogItem, Logs } from "../../../shared/types/Logs";
import { formatDateForSQL } from "../../../shared/functions/dateAndTime";


// #region Actions
export enum ActionType {
    populate_state,
    project_dragged,
    update_project_hours,
    update_project_notes,
    toggle_no_log,
    toggle_leave,
    toggle_public_holiday
}

interface PopulateStateAction {
    type: ActionType.populate_state;
    allProjects: ProjectCollection;
    favouriteProjects: number[];
    logs: Logs;
    date: Date;
}

interface ProjectDraggedAction {
    type: ActionType.project_dragged;
    result: Result;
}

interface UpdateProjectHours {
    type: ActionType.update_project_hours;
    projectId: number;
    hours: number;
}

interface UpdateProjectNotes {
    type: ActionType.update_project_notes;
    projectId: number;
    notes: string;
}

interface ToggleNoLog {
    type: ActionType.toggle_no_log;
}

interface ToggleLeave {
    type: ActionType.toggle_leave;
}

interface TogglePublicHoliday {
    type: ActionType.toggle_public_holiday;
}

export type Action = PopulateStateAction | ProjectDraggedAction | UpdateProjectHours | UpdateProjectNotes | ToggleNoLog | ToggleLeave | TogglePublicHoliday;
// #endregion

export enum ColumnType {
    logging_column,
    favourite_projects_column
}

type Column = {
    id: ColumnType;
    title: string;
    projectIds: number[];
}

type Columns = {
    logging_column: Column;
    favourite_projects_column: Column;
}

export interface State {
    projects: ProjectCollection,
    columns: Columns,
    unsavedChanges: boolean,
    originalState: string,
    data: {
        allProjects: ProjectCollection, 
        favouriteProjects: number[], 
        logs: Logs, 
        date: Date
    }
}

const loggingData = (allProjects: ProjectCollection = {}, favouriteProjects: number[] = [], logs: Logs = {}, date: Date = new Date()): State => {

    // Add hours and notes to each project
    const allProjectsWithHoursAndNotes: ProjectCollection = {};

    for (const projectId in allProjects) {
        if (allProjects.hasOwnProperty(projectId)) {
            const project = allProjects[projectId];

            // Create a copy of the project with hours set to 0 and notes set to ""
            const updatedProject: Project = { ...project, hours: 0, notes: "" };

            // Add the updated project to the new collection
            allProjectsWithHoursAndNotes[projectId] = updatedProject;
        }
    }

    // Update the projects object with any logged hours and notes and get a list of all the logged projects
    const loggedProjects: number[] = []
    if (logs.hasOwnProperty(formatDateForSQL(date))) {
        const log = logs[formatDateForSQL(date)]
        for (const project of log) {
            const { hours, notes, projectId } = project;
            if (allProjectsWithHoursAndNotes.hasOwnProperty(projectId)) {
                allProjectsWithHoursAndNotes[projectId].hours = hours;
                allProjectsWithHoursAndNotes[projectId].notes = notes;
                loggedProjects.push(projectId)
            }
        }
    }

    // Remove the logged project from the favourite projects list as they are already in the logging column
    const remainingFavouriteProjects = favouriteProjects.filter(item => !loggedProjects.includes(item));

    return {
        projects: allProjectsWithHoursAndNotes,
        columns: {
            logging_column: {
                id: ColumnType.logging_column,
                title: "Logged",
                projectIds: loggedProjects,
            },
            favourite_projects_column: {
                id: ColumnType.favourite_projects_column,
                title: 'Favourite Projects',
                projectIds: remainingFavouriteProjects,
            },
        },
        unsavedChanges: false,
        originalState: "",
        data: {
            allProjects: allProjects,
            favouriteProjects: favouriteProjects,
            logs: logs,
            date: date
        }
    }
}

const getTotalHoursLogged = (state: State): number => {
    const { projects, columns } = state;
    let totalHours: number = 0;
    for (const projectId in projects) {
        if (columns.logging_column.projectIds.includes(Number(projectId))) {
            var projectHours = projects[Number(projectId)].hours || 0;
            totalHours += projectHours;
        }
    }
    return totalHours;
}

const checkForChanges = (currentState: State): boolean => {
    try {
        const originalState: State = JSON.parse(currentState.originalState)

        const projectsChanged = JSON.stringify(currentState.projects) !== JSON.stringify(originalState.projects)
        const columnsChanged = JSON.stringify(currentState.columns) !== JSON.stringify(originalState.columns)

        return projectsChanged || columnsChanged;
    } catch (error) {
        console.log(error)
        return false
    }
}

export const initialState: State = loggingData();

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.populate_state: {
            const { allProjects, favouriteProjects, logs, date } = action;
            const populatedState: State = loggingData(allProjects, favouriteProjects, logs, date)
            populatedState.originalState = JSON.stringify(populatedState) 
            return populatedState
        }
        case ActionType.project_dragged: {            
            const { result } = action;
            const { draggableId, source, destination } = result;

            if (!destination) return state;

            if (
                destination.droppableId === source.droppableId && 
                destination.index === source.index
            ) return state;

            const columnMap = (index: string) => {
                switch (index) {
                    case '0': return state.columns.logging_column;
                    case '1': return state.columns.favourite_projects_column;
                }
            }

            const start = columnMap(source.droppableId);
            const finish = columnMap(destination.droppableId);

            if (!start || !finish) return state;

            // Reordering a list
            if (start === finish) {
                const newProjectIds = Array.from(start.projectIds);
                newProjectIds.splice(source.index, 1);
                newProjectIds.splice(destination.index, 0, Number(draggableId))

                const columnName = start.id === 0 ? 'logging_column' : 'favourite_projects_column';

                return {
                    ...state,
                    columns: {
                        ...state.columns,
                        [columnName] : {
                            ...start,
                            projectIds: newProjectIds
                        }
                    }
                }
            }

            // Moving from one list to another
            const startProjectIds = Array.from(start.projectIds)
            startProjectIds.splice(source.index, 1);

            const finishProjectIds = Array.from(finish.projectIds)
            finishProjectIds.splice(destination.index, 0, Number(draggableId));

            const startColumnName = start.id === 0 ? 'logging_column' : 'favourite_projects_column';
            const finishColumnName = finish.id === 0 ? 'logging_column' : 'favourite_projects_column';

            const updatedState: State = {
                ...state,
                columns: {
                    ...state.columns,
                    [startColumnName]: {
                        ...start,
                        projectIds: startProjectIds
                    },
                    [finishColumnName]: {
                        ...finish,
                        projectIds: finishProjectIds
                    },
                }
            }
            if (finishColumnName === 'favourite_projects_column') updatedState.projects[Number(draggableId)].hours = 0;
            
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState;
        }
        case ActionType.update_project_hours: {
            const { projectId, hours } = action;
            const updatedState: State = {
                ...state,
                projects: {
                    ...state.projects,
                    [projectId]: {
                        ...state.projects[projectId],
                        hours: hours
                    }
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState;
        }
        case ActionType.update_project_notes: {
            const { projectId, notes } = action;
            const updatedState: State = {
                ...state,
                projects: {
                    ...state.projects,
                    [projectId]: {
                        ...state.projects[projectId],
                        notes: notes
                    }
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState;
        }
        case ActionType.toggle_no_log: {
            const updatedState: State = loggingData(state.data.allProjects, state.data.favouriteProjects, state.data.logs, state.data.date)
            const noLog = state.columns.logging_column.projectIds.includes(0);
            updatedState.columns.logging_column.projectIds = noLog ? [] : [0];
            updatedState.originalState = state.originalState;
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState;
        }
        case ActionType.toggle_leave: {
            var loggingColumnProjectIds = [...state.columns.logging_column.projectIds];

            if (loggingColumnProjectIds.includes(-1)) {
                // Remove leave from logging column project ids
                var index = loggingColumnProjectIds.indexOf(-1)
                loggingColumnProjectIds.splice(index, 1)
                const updatedState: State = {
                    ...state,
                    projects: {
                        ...state.projects,
                        [-1]: {
                            ...state.projects[-1],
                            hours: 0
                        }
                    },
                    columns: {
                        ...state.columns,
                        logging_column: {
                            ...state.columns.logging_column,
                            projectIds: loggingColumnProjectIds
                        }
                    }
                }
                updatedState.unsavedChanges = checkForChanges(updatedState)
                return updatedState
            } else {
                // Remove no log if it is ticked
                var noLogIndex = loggingColumnProjectIds.indexOf(0);
                if (noLogIndex !== -1) loggingColumnProjectIds.splice(noLogIndex,  1);

                // Remove public holiday if it is ticked
                var publicHolidayIndex = loggingColumnProjectIds.indexOf(-2);
                if (publicHolidayIndex !== -1) loggingColumnProjectIds.splice(publicHolidayIndex,  1);

                // Add leave
                loggingColumnProjectIds.push(-1);

                const updatedState: State = {
                    ...state,
                    projects: {
                        ...state.projects,
                        [-1]: {
                            ...state.projects[-1],
                            hours: Math.max(0, 8 - getTotalHoursLogged(state))
                        }
                    },
                    columns: {
                        ...state.columns,
                        logging_column: {
                            ...state.columns.logging_column,
                            projectIds: loggingColumnProjectIds
                        }
                    }
                }
                updatedState.unsavedChanges = checkForChanges(updatedState)
                return updatedState
            }
        }
        case ActionType.toggle_public_holiday: {
            const updatedState: State = loggingData(state.data.allProjects, state.data.favouriteProjects, state.data.logs, state.data.date)
            const publicHoliday = state.columns.logging_column.projectIds.includes(-2);
            updatedState.columns.logging_column.projectIds = publicHoliday ? [] : [-2];
            updatedState.originalState = state.originalState;
            updatedState.unsavedChanges = checkForChanges(updatedState);
            return updatedState;
        }
        default:
            throw new Error(`Invalid action type.`)
    }
}