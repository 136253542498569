import ExcelJS from 'exceljs'

import { getAllProjects } from '../api/projects';
import { getProjectLogs, GetProjectLogsRequestBody } from '../api/logs';
import { Row } from '../shared/types/excel';
import { getFluidraProjectId, getProjectName } from '../shared/functions/projects';
import { downloadExcelFile } from '../shared/functions/excel';
import { formatDate, getCurrentDate } from '../shared/functions/dateAndTime';

const GenerateProjectReport = async (projectId: number) => {
    console.log(`Generating project report for: ${projectId}`)

    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Project Breakdown')

    // Make API calls to get the data
    const allProjects = await getAllProjects();
    const requestBody: GetProjectLogsRequestBody = {
        id: projectId
    }
    const logs = await getProjectLogs(requestBody)

    // Create the header rows
    const row1: Row = [`Project Report - ${getFluidraProjectId(allProjects, projectId)} ${getProjectName(allProjects, projectId)}`]
    const row2: Row = []
    const row3: Row = ["Employee Id", "Employee Name", "Date", "Hours", "Notes"]

    // Add the header rows
    ws.addRow(row1)
    ws.addRow(row2)
    ws.addRow(row3)

    for (const log of logs) {
        const row = [log.fluidraEmployeeId, `${log.firstName} ${log.lastName}`, log.date, log.hours, log.notes]
        ws.addRow(row)
    }

    const buffer = await wb.xlsx.writeBuffer();
    downloadExcelFile(buffer, `Project Report (${formatDate(getCurrentDate(), true)}) - ${getFluidraProjectId(allProjects, projectId)} ${getProjectName(allProjects, projectId)}`)
}

export default GenerateProjectReport;