import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { ProjectStages } from "../../../shared/types/Stages"
import { SQLFormatToDate } from "../../../shared/functions/dateAndTime"
import { AdminCheckbox } from "../../common/FormInputs"
import { DatePickerButton } from "../../common/DatePickerButton"
import { COLORS } from "../../../values/colors"
import { Project } from "../../../shared/types/Project"

interface StageProps {
    stageId: number,
    stageName: string,
    project: Project,
    setStageUsed: (stageId: number, used: boolean) => void,
    setStageStartDate: (stageId: number, startDate: Date | null) => void
}

export const Stage: React.FC<StageProps> = ({ stageId, stageName, project, setStageUsed, setStageStartDate }) => {
    const [ isValid, setIsValid ] = useState<boolean>(false);
    const [ isActive, setIsActive ] = useState<boolean>(false);
    
    const stage = project.stages?.find(stage => stage.stageId === stageId)
    const used = !!stage && stage.used;
    const startDate = stage ? stage.startDate ? SQLFormatToDate(stage.startDate) : null : null 

    useEffect(() => {
        const today = new Date()
        var currentStage = 0
        var currentStartDate = new Date(2000, 0, 1) 
        var valid = false;
        var active = false;
        for (const stage of project.stages.sort((a, b) => a.stageId - b.stageId)) {
            const thisStageId = stage.stageId
            const thisStageUsed = stage.used
            const thisStageStartDate = stage.startDate ? new Date(stage.startDate) : null
            
            if (thisStageUsed && thisStageStartDate) {
                if (thisStageStartDate > currentStartDate) {
                    currentStartDate = thisStageStartDate
                    if (thisStageStartDate < today) currentStage = thisStageId
                    if (thisStageId === stageId) valid = true;
                }
            } else {
                if (thisStageId === stageId) valid = true;
            }
        }
        if (currentStage === stageId) {
            valid = true;
            active = true;
        }
        setIsValid(valid)
        setIsActive(active)
    }, [project, stageId])

    
    const handleActiveChanged = (used: boolean) => setStageUsed(stageId, used)
    const handleStartDateChanged = (date: Date | null) => setStageStartDate(stageId, date)

    return (
        <StagesGrid key={stageId} $isValid={isValid} $isActive={isActive}>
            {/* Checkbox */}
            <StagesItem $column={1} $bold={false} style={{marginLeft: '15px'}}>
                <AdminCheckbox value={used} setValue={handleActiveChanged} />
            </StagesItem>

            {/* Name */}
            <StagesItem $column={2} $bold={false}>
                {stageName}
            </StagesItem>

            {/* Start Date */}
            <StagesItem $column={3} $bold={false}>
                {used
                ? <DatePickerButton date={startDate} setDate={handleStartDateChanged} />
                : <NoButton>-</NoButton>
                }
            </StagesItem>

        </StagesGrid>
    )
}

// #region Styles
const StagesGrid = styled.div<{$isActive: boolean, $isValid: boolean}>`
    display: grid;
    grid-template-columns: 80px 120px 120px;
    height: 35px;
    width: 340px;
    background-color: ${props => props.$isValid 
        ? props.$isActive 
            ? COLORS.secondaryBlue4 
            : 'transparent'
        : COLORS.red4};
    border-radius: 20px;
    margin-bottom: 5px;
`;

const StagesItem = styled.div<{$column: number, $bold: boolean}>`
    grid-column: ${props => props.$column};
    font-weight: ${props => props.$bold ? 'bold' : ''};
    align-self: center;
`;

const NoButton = styled.span`
    display: block;
    text-align: center;
`
// #endregion