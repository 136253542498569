import { post } from "../adapters/apiAdapter";
import { PostFeedbackRequestBody } from "./feedback";
import { PostProjectRequestRequestBody } from "./requests";

export async function sendFeedbackReceivedEmail(requestBody: PostFeedbackRequestBody) {
    return await post(`/api/email/feedback-received`, requestBody);
}

export async function sendProjectRequestedEmail(requestBody: PostProjectRequestRequestBody) {
    return await post(`/api/email/project-requested`, requestBody);
    
}