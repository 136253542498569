import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import '../App.css';
import { Page, Card, Title, Instructions } from "../components/common/CommonStyles";
import { formatDateForSQL } from '../shared/functions/dateAndTime';
import { CancelButton, SaveButton } from '../components/common/Buttons';
import { postProjectRequest } from "../api/requests";
import { getTypes } from "../api/projects";
import { sendProjectRequestedEmail } from "../api/emails";
import { Form, TextEntry, LargeTextEntry, CheckboxWithLabel, DropDownMenu } from "../components/common/FormInputs";

import { useAuth } from '../authprovider'
import { PostProjectRequestRequestBody } from "../api/requests";
import { delay } from "../shared/functions/functions";
import { GLOBALS } from "../values/globals";
import { ProjectTypes } from "../shared/types/ProjectType";

const RequestProjectPage = () => {
    let auth = useAuth();

    const [ projectId, setProjectId ] = useState('')
    const [ projectName, setProjectName ] = useState('')
    const [ projectType, setProjectType ] = useState('')
    const [ projectTypes, setProjectTypes ] = useState<string[]>([])
    const [ additionalInformation, setAdditionalInformation ] = useState('')
    const [ anonymous, setAnonymous ] = useState(false)
    const [ loading, setLoading ] = useState<boolean>(false)

    const navigate = useNavigate();

    useEffect(() => {
        getTypesAsync()
    }, [])

    const getTypesAsync = async () => {
        const projectTypes: ProjectTypes = await getTypes();
        setProjectTypes(Object.values(projectTypes).map((type) => type.name));
        // setProjectTypes(convertProjectTypesToDropdownItems(projectTypes));
    }

    //#region Button Handlers    
    const handleCancel = (event: any) => {
        event.preventDefault();
        navigate(`/logging/${formatDateForSQL(new Date())}`)
    };
    
    const handleSave = async (event: any) =>{
        event.preventDefault();

        console.log(`Sending project request`)

        const requestBody: PostProjectRequestRequestBody = {
            name: anonymous ? "Anonymous" : `${auth.firstName} ${auth.lastName}`,
            date: formatDateForSQL(new Date()),
            projectId: projectId || "Not specified",
            projectName: projectName || "Not specified",
            projectType: projectType || "Not specified",
            additionalInformation: additionalInformation
        }

        setLoading(true)

        // Add a minimum loading time for user feedback
        await Promise.all([
            await postProjectRequest(requestBody),
            await sendProjectRequestedEmail(requestBody),
            delay(GLOBALS.buttonLoadTime)
        ])

        setLoading(false)
        
        await alert("Your project request has been sent to the admins!")
        navigate(`/logging/favourite-projects/${formatDateForSQL(new Date())}`)
    };
    //#endregion

    return (
        <Page>
            <FeedbackCard>
                <Title>Request a project</Title>
                <RequestProjectInstructions>
                    Is there a project you have been working on that is missing from the list?
                    You can request a project here and an admin will be notified.
                    You can leave the project ID entry blank if you don't know it or if an ID hasn't been allocated yet.
                </RequestProjectInstructions>
                <Form>
                    <TextEntry
                        label="Project ID"
                        value={projectId}
                        setValue={setProjectId}
                        errorMessage="" />
                    <TextEntry
                        label="Project Name"
                        value={projectName}
                        setValue={setProjectName}
                        errorMessage="" />
                    <div style={{'height': '10px'}}/>
                    <DropDownMenu 
                        label="Project Type"
                        options={projectTypes}
                        value={projectType}
                        setValue={setProjectType}/>
                    <div style={{'height': '10px'}}/>
                    <LargeTextEntry
                        label="Additional Information"
                        value={additionalInformation}
                        setValue={setAdditionalInformation}
                        rows={5} />
                    <CheckboxWithLabel
                        value={anonymous}
                        setValue={setAnonymous}
                        label="Send anonymously" />

                    {/* Save and Cancel Buttons */}
                    <div style={{'gridRow': '4', 'gridColumn': '2'}}>
                        <RightAlignedRow>
                            <CancelButton onClick={handleCancel}/>
                            <SaveButton onClick={handleSave} text="SEND" loading={loading} />
                        </RightAlignedRow>
                    </div>
                </Form>
            </FeedbackCard>
        </Page>      
    )
}

//#region Styles
const FeedbackCard = styled(Card)`
    width: 600px;
    height: min-content;
    margin-top: 50px;
    padding: 40px;
`;

const RequestProjectInstructions = styled(Instructions)`
    margin-bottom: 20px;
`;

const RightAlignedRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-self: flex-end;
    margin-top: 20px;
`;
//#endregion

export default RequestProjectPage;