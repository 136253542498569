import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { COLORS } from '../../../values/colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ProjectTypes } from '../../../shared/types/ProjectType';

interface ProjectTypeDropdownProps {
    types: ProjectTypes, 
    // types: {id: number, name: string}[], 
    typeFilter: number, 
    setTypeFilter: React.Dispatch<React.SetStateAction<number>>
}

const ProjectTypeDropdown: React.FC<ProjectTypeDropdownProps> = ({ types, typeFilter, setTypeFilter }) => {
    
    const [ open, setOpen ] = useState<boolean>(false) 
    const [ selectedType, setSelectedType ] = useState<string>("All");

    useEffect(() => {
        if (typeFilter in types) setSelectedType(types[typeFilter].name)
    }, [typeFilter, types])

    const handleTypeSelected = (type: number) => {
        setTypeFilter(type);
        setOpen(false);
    }

    const toggleDropdown = () => setOpen(!open)

    return (
        <Dropdown $isOpen={open} >
            <DropdownHeader onClick={toggleDropdown}>
                {selectedType}
                <Icon icon={faChevronRight} $isOpen={open} />
            </DropdownHeader>
            <DropdownBody $isOpen={open}>
                {Object.values(types).map(type => (
                    <DropdownItem key={type.id} onClick={e => handleTypeSelected(type.id)}>
                        <DropdownItemText $isSelected={typeFilter === type.id}>{type.name}</DropdownItemText>
                    </DropdownItem>
                ))}
            </DropdownBody>
        </Dropdown>
    )
}

//#region Styles
const Dropdown = styled.div<{$isOpen: boolean}>`
    width: 150px;
    border-radius: 20px;
    margin-bottom: 10px;
    // background-color: ${props => (props.$isOpen ? COLORS.secondaryGray3 : COLORS.secondaryGray4)};
    background-color: ${COLORS.secondaryGray4};
    border: ${props => (props.$isOpen ? '1px' : '0px')} solid ${COLORS.secondaryGray};
    margin: ${props => (props.$isOpen ? '-1px' : '0px')};
    user-select: none;
    position: absolute;
    z-index: 1;
    right: 0;
`;

const DropdownHeader = styled.div`
    padding: 0px 15px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10pt;
`;

const DropdownBody = styled.div<{$isOpen: boolean}>`
    padding: 5px;
    border-top: 1px solid ${COLORS.secondaryGray};
    display: ${(props) => props.$isOpen ? 'block' : 'none'};
`;

const DropdownItem = styled.div`
    padding: 5px 10px;
    border-radius: 20px;
    &:hover {
        cursor: pointer;
        background-color: ${COLORS.secondaryGray2}
    }
`;

const DropdownItemText = styled.span<{$isSelected: boolean}>`
    font-size: 10pt;
    font-weight: ${(props) => props.$isSelected ? 'bold' : 'none'}
`;

const Icon = styled(FontAwesomeIcon)<{$isOpen: boolean}>`
    font-size: 13px;
    color: ${COLORS.secondaryGray};
    transform: ${(props) => props.$isOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all .2s ease-in-out;
`;
//#endregion

export default ProjectTypeDropdown;
