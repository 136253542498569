import { Project, ProjectCollection } from "../../../shared/types/Project";

// #region Actions
export enum ProjectListActionType {
    populate_list,
    filter,
}

interface PopulateListAction {
    type: ProjectListActionType.populate_list;
    allProjects: any;
}

interface FilterListAction {
    type: ProjectListActionType.filter;
    textFilter: string;
}

type ProjectListAction = PopulateListAction | FilterListAction;
// #endregion

export interface ProjectListState {
    allProjects: ProjectCollection,
    filteredProjects: ProjectCollection
}

export const initialProjectListState: ProjectListState = {
    allProjects: {},
    filteredProjects: {}
};

export function projectListReducer(state: ProjectListState, action: ProjectListAction) : ProjectListState {
    switch(action.type) {
        case ProjectListActionType.populate_list:
            const allProjects: ProjectCollection = action.allProjects;

            // Filter out special projects
            let projectsToRemove: number[] = [-2, -1, 0];
            projectsToRemove.forEach(id => delete allProjects[id])

            return {
                ...state,
                allProjects: allProjects,
                filteredProjects: allProjects
            };
        case ProjectListActionType.filter:
            const textFilter: string = action.textFilter;

            const filteredProjects: ProjectCollection = Object.values(state.allProjects).filter(project => {
                const matchId = project.fluidraProjectId?.toLowerCase().includes(textFilter.toLowerCase());
                const matchName = project.name?.toLowerCase().includes(textFilter.toLowerCase());
                return matchId || matchName;
            })

            return {
                ...state,
                filteredProjects: filteredProjects
            };
        default:
            throw new Error(`Invalid project list action type.`)
    }
}