import React from 'react';
import styled from 'styled-components';

import '../../../App.css';
import { formatDate } from '../../../shared/functions/dateAndTime';
import { COLORS } from '../../../values/colors';

const getButtonIcon = (isLogged: boolean, date: Date) => {
    const loggedIcon = require('../../../resources/complete-primary.png');
    const notLoggedIcon = require('../../../resources/incomplete-primary.png');
    const weekendIcon = require('../../../resources/neutral-primary.png');

    if (isLogged) {
        return loggedIcon;
    }

    const dayNumber = new Date(date).getDay();
    if ([0, 6].includes(dayNumber)) {
        return weekendIcon;
    }

    return notLoggedIcon;
}

interface DateButtonProps {
    date: Date,
    onSelectDate: (date: Date) => void,
    buttonSelected: boolean,
    isLogged: boolean
}

const DateButton: React.FC<DateButtonProps> = ( {date, onSelectDate, buttonSelected, isLogged} ) => {

    const handleButtonClick = (date: Date) => {
        onSelectDate(date);
    };
  
    const dayNumber = new Date(date).getDay();
    const isWeekend = [0, 6].includes(dayNumber);

    const dayOfWeek = new Date(date).toLocaleString('en-GB', {weekday:'long'});
  
    return ( 
        <Button
            $isSelected={buttonSelected}
            $isLogged={isLogged}
            $isWeekend={isWeekend}
            onClick={() => handleButtonClick(date)}
            >
            <Container>
                <Left>
                    <DayOfWeek>{dayOfWeek}</DayOfWeek>
                    <Date1>{formatDate(date)}</Date1>
                </Left>
                <Icon src={getButtonIcon(isLogged, date)} alt="logo" />
            </Container>
      </Button>
    )
}

//#region Styles
const Button = styled.button<{$isLogged: boolean, $isWeekend: boolean, $isSelected: boolean}>`
    border-radius: 20px;
    height: 56px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: ${props => 
        props.$isLogged 
            ? COLORS.secondaryGreen4
            : props.$isWeekend
                ? COLORS.secondaryGray3
                : COLORS.secondaryYellow4};
    border: ${props => 
        props.$isSelected
            ? `3px solid ${COLORS.primary}`
            : `0px solid ${COLORS.primary}`};
    padding: ${props => 
        props.$isSelected
            ? '0px 7px'
            : '0px 10px'};
    &:hover {
        padding: ${props => 
            props.$isSelected
                ? '0px 7px'
                : '0px 8px'};
        border: ${props => 
            props.$isSelected
                ? `3px solid ${COLORS.primary}`
                : `2px solid ${COLORS.primary}`};
    }
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
`;

const Container = styled.div`
    width: 230px;
    display: flex;
    padding: 0px 10px;
    align-items: center;
`;

const DayOfWeek = styled.span`
    text-align: left;
    font-size: 14pt;
    font-weight: bold;
`;

const Date1 = styled.span`
    text-align: left;
    font-size: 12pt;
`;

const Icon = styled.img`
    height: 25px;
    width: 25px;
    margin-left: auto;
    margin-right: 0px;
    display: flex;
`;
//#endregion

export default DateButton;
