import React, { useState, useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Card, Title, Heading, RightAlignedRow } from '../../common/AdminWindowStyles';
import { SoftCard } from '../../common/CommonStyles';
import { DeleteButton, CancelButton } from '../../common/Buttons';
import { postAddCapitalisationGroup, postEditCapitalisationGroup, postDeleteCapitalisationGroup } from '../../../api/capitalisationGroups';
import { PostAddCapitalisationGroupRequestBody, PostEditCapitalisationGroupRequestBody, PostDeleteCapitalisationGroupRequestBody } from '../../../api/capitalisationGroups';
import { AdminSubmitButton } from '../../common/FormInputs';
import { delay } from '../../../shared/functions/functions';
import { getProjectName, getFluidraProjectId } from '../../../shared/functions/projects';

import { CapitalisationGroupCollection } from '../../../shared/types/CapitalisationGroup';
import { CapitalisationGroupActionType, initialState, CapitalisationGroupReducer } from './capitalisationGroup.reducer';
import { ProjectCollection } from '../../../shared/types/Project';
import { GLOBALS } from '../../../values/globals';
import '../../../styles/scrollbar.scss';
import { TextEntry } from '../../molecules/TextEntry';

const Titles = {
    add: "Add New Capitalisation Group",
    edit: "Edit Capitalisation Group"
}

interface CapitalisationGroupWindowProps {
    capitalisationGroups: CapitalisationGroupCollection,
    allProjects: ProjectCollection,
    unsavedChanges: boolean,
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>,
    refreshPage: () => void
}

const CapitalisationGroupWindow: React.FC<CapitalisationGroupWindowProps> = ( {capitalisationGroups, allProjects, unsavedChanges, setUnsavedChanges, refreshPage} ) => {
    const { urlId } = useParams();
    const [ title, setTitle ] = useState(Titles.add)
    const [ state, dispatch ] = useReducer(CapitalisationGroupReducer, initialState)
    
    const [ loading, setLoading ] = useState<boolean>(false)

    const navigate = useNavigate()

    useEffect(() => {
        setUnsavedChanges(state.unsavedChanges)
    }, [state.unsavedChanges, setUnsavedChanges])

    const handleCapitalisationGroupPropertyChanged = (property: string, value: any) => {
        dispatch({
            type: CapitalisationGroupActionType.property_changed,
            [property]: value,
        });
    }

    // #region Load Capitalisation Group
    useEffect(() => {
        if (Object.values(capitalisationGroups).length > 0) loadCapitalisationGroup()
    }, [urlId, capitalisationGroups]);

    const loadCapitalisationGroup = () => {
        if (urlId) {
            const capitalisationGroup = Object.values(capitalisationGroups).find(capitalisationGroup => capitalisationGroup.id.toString() === urlId);
            if (capitalisationGroup) {
                setTitle(Titles.edit)
                dispatch({
                    type: CapitalisationGroupActionType.load_capitalisation_group,
                    capitalisationGroup: capitalisationGroup
                })
            }
        } else {
            setTitle(Titles.add)
            dispatch({
                type: CapitalisationGroupActionType.clear_capitalisation_group
            })
        }
    }
    // #endregion

    //#region Button Handlers
    const addCapitalisationGroup = async () => {
        const requestBody: PostAddCapitalisationGroupRequestBody = {
            fluidraCapitalisationGroupId: state.capitalisationGroup.fluidraCapitalisationGroupId,
            name: state.capitalisationGroup.name,
            description: state.capitalisationGroup.description
        }
        const response = await postAddCapitalisationGroup(requestBody)

        if (response.name === 'AxiosError') {
            alert("An error occurred - No changes were made")
        }
    }

    const editCapitalisationGroup = async () => {
        const requestBody: PostEditCapitalisationGroupRequestBody = {
            id: state.capitalisationGroup.id,
            fluidraCapitalisationGroupId: state.capitalisationGroup.fluidraCapitalisationGroupId,
            name: state.capitalisationGroup.name,
            description: state.capitalisationGroup.description
        }
        const response = await postEditCapitalisationGroup(requestBody)

        if (response.name === 'AxiosError') {
            alert("An error occurred - No changes were made")
        }
    }

    const saveCapitalisationGroup = async (event: any) => {
        event?.preventDefault();
        console.log('Saving Capitalisation group');

        setLoading(true)

        // Add a minimum loading time for user feedback
        await Promise.all([
            urlId ? editCapitalisationGroup() : addCapitalisationGroup(),
            delay(GLOBALS.buttonLoadTime)
        ])
        
        setLoading(false)

        refreshPage()
    }

    const deleteCapitalisationGroup = async (event: any) => {
        event?.preventDefault();

        var answer = window.confirm('Are you sure you want to delete this Capitalisation group?')
        if (answer) {
            console.log('Deleting Capitalisation group');

            // Create the request body
            const requestBody: PostDeleteCapitalisationGroupRequestBody = {
                id: state.capitalisationGroup.id
            }

            const response = await postDeleteCapitalisationGroup(requestBody)

            navigate(`/admin/Capitalisation-groups`)
            refreshPage()
        }
    }

    const cancel = (event: any) => {
        event.preventDefault()
        console.log('Cancelling');
        loadCapitalisationGroup();
    }
    //#endregion

    return (
        <Card>
            <Form onSubmit={saveCapitalisationGroup} style={{justifyContent: 'space-between'}}>
                <Column>
                    <Row style={{justifyContent: 'space-between', marginBottom: '16px'}}>
                        {/* Title */}
                        <Title>{title}</Title>
                    </Row>
                    
                    <Row style={{columnGap: '32px'}}>
                        {/* Details */}
                        <Column style={{rowGap: '8px'}}>
                            <Heading style={{margin: '0px'}}>Details</Heading>
                            <TextEntry
                                showHeading
                                headingText="Fluidra Id"
                                label="Fluidra Id"
                                value={state.capitalisationGroup.fluidraCapitalisationGroupId}
                                setValue={(value: any) => handleCapitalisationGroupPropertyChanged('fluidraCapitalisationGroupId', value)}
                                errorMessage='' />

                            <TextEntry
                                showHeading
                                headingText="Name"
                                label="Name"
                                value={state.capitalisationGroup.name}
                                setValue={(value: any) => handleCapitalisationGroupPropertyChanged('name', value)}
                                errorMessage='' />

                            <TextEntry
                                multiline
                                showHeading
                                headingText="Description"
                                label="Description"
                                value={state.capitalisationGroup.description}
                                setValue={(value: any) => handleCapitalisationGroupPropertyChanged('description', value)}
                                errorMessage='' />
                        </Column>                    
                        
                        {/* Linked Projects */}
                        <Column>     
                            <Row>
                                <Heading>Linked Projects</Heading>
                            </Row>
                            <LinkedProjectsSoftCard className='scrollableList'>
                                <LinkedProjectList>
                                    {state.capitalisationGroup.projects?.sort((a, b) => getFluidraProjectId(allProjects, a).localeCompare(getFluidraProjectId(allProjects, b)))
                                        .map((projectId) => (
                                        <Row key={`${projectId} row`}>
                                            <ProjectId>{getFluidraProjectId(allProjects, projectId)}</ProjectId>
                                            <ProjectName>{getProjectName(allProjects, projectId)}</ProjectName>
                                        </Row>
                                    ))}
                                </LinkedProjectList>
                            </LinkedProjectsSoftCard>
                            <Row>
                                <Info>Projects can be added to the Capitalisation group from the projects tab.</Info>
                            </Row>
                        </Column>
                    </Row>
                </Column>

                {/* Save and Cancel Buttons */}
                <Row style={{alignItems: 'flex-end', justifyContent: 'space-between'}}>
                    <DeleteButton onClick={deleteCapitalisationGroup}/>
                    <Row style={{alignItems: 'flex-end'}}>
                        <CancelButton onClick={cancel}/>
                        <AdminSubmitButton label="SAVE" loading={loading} />
                    </Row>
                </Row>
            </Form>
        </Card>
    );
}

//#region Styles
const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const ProjectId = styled.span`
  font-weight: bold;
  margin-right: 10px;
  user-select: none;
`;

const ProjectName = styled.span`
  user-select: none;
  text-align: left;
`;

const LinkedProjectList = styled.div`
    overflow-y: auto;
    height: 165px;
`;

const LinkedProjectsSoftCard = styled(SoftCard)`
    padding-right: 6px;
`;

const Info = styled.span`
    padding-top: 10px;
`
//#endregion

export default CapitalisationGroupWindow;
