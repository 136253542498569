export type Employee = {
    id: number;
    fluidraEmployeeId: string;
    firstName: string;
    lastName: string;
    email: string;
    isAdmin: boolean;
    isManager: boolean;
    department: number;
    active: boolean;
    managerId: number | null;
};

export const emptyEmployee: Employee = {
    id: 0,
    fluidraEmployeeId: "",
    firstName: "",
    lastName: "",
    email: "",
    isAdmin: false,
    isManager: false,
    department: 0,
    active: true,
    managerId: null
}

export type EmployeeCollection = Record<number, Employee>;