import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from 'styled-components';
import { Button, Menu, MenuItem, Divider, Fade } from '@mui/material'
import axios from 'axios'

import { formatDateForSQL } from "../../shared/functions/dateAndTime";
import { useAuth } from '../../authprovider'
import { COLORS } from "../../values/colors";
import ConfirmNavigationPopup from "./ConfirmNavigationPopup";

interface ProfileMenuProps {
    unsavedChanges: boolean
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ unsavedChanges=false }) => {

    const auth = useAuth();

    const profileImage = require('../../resources/account.png');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const date = formatDateForSQL(new Date());

    const navigate = useNavigate();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    //#region Menu Item Button Handlers
    const handleFeedback = () => {
        setAnchorEl(null);
        ConfirmNavigationPopup(`/feedback`, unsavedChanges, navigate)
    }

    const handleReminderPreferences = () => {
        setAnchorEl(null);
        ConfirmNavigationPopup(`/reminder-preferences`, unsavedChanges, navigate);
    }

    const handleLogging = () => {
        setAnchorEl(null);
        ConfirmNavigationPopup(`/logging/${date}`, unsavedChanges, navigate);
    }

    const handleAdmin = () => {
        setAnchorEl(null);
        ConfirmNavigationPopup('/admin/summary', unsavedChanges, navigate);
    }

    const handleSignOut = async () => {
        setAnchorEl(null);
        
        await axios.post('/auth/signout', {}).then((response) => {
            console.log(response)
            ConfirmNavigationPopup(`/`, unsavedChanges, navigate);
        }).catch(error => {
            console.log(error)
            navigate(`/`);
        })
    }
    //#endregion

    if (!auth.user) {
        return (
            <div style={{width: '40px'}}></div>
        )
    } else {
        return (
            <div style={{display: 'grid'}}>
                <ProfileButton
                    onClick={handleClick}>
                    <Column>
                        <Name>{auth.firstName} {auth.lastName}</Name>
                        <Email>{auth.email}</Email>
                    </Column>
                    <ProfileButtonImage src={profileImage}/>
                </ProfileButton>
                <DropDownMenu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}>
                    <MenuItem key="logging" onClick={handleLogging}><span>Logging</span></MenuItem>
                    {(auth.isAdmin === 1 || auth.isManager === 1) && (
                        [
                            <MenuItem key="admin" onClick={handleAdmin}><span>Admin</span></MenuItem>
                        ]
                    )}
                    <MenuItem onClick={handleReminderPreferences}><span>Reminder Preferences</span></MenuItem>
                    <MenuItem onClick={handleFeedback}><span>Feedback</span></MenuItem>
                    <Divider />
                    {/* <MenuItem key="sign-out" onClick={handleSignOut}><span>Sign Out</span></MenuItem> */}
                    <form action="/auth/signout" method="post">
                        <SignOutButton>Sign Out</SignOutButton>
                    </form>
                    <Info>Version 1.3.3<br /><br />Copyright © 2024<br />Fluidra Group Australia Pty Ltd</Info>
                </DropDownMenu>
            </div>
        )  
    }
}

// #region Styles
const ProfileButton = styled(Button)`
    height: 40px;
    padding: 0px;
    margin: 0px;
    border: 0px;
    background-color: transparent;
    right: 0px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    justify-self: end;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    padding-right: 10px;
`;

const Name = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: white;
    height: 24px !important;
    text-transform: none !important;
`;

const Email = styled.div`
    font-size: 12px;
    color: white;
    text-transform: none !important;
`;

const ProfileButtonImage = styled.img`
    height: 40px;
    width: 40px;
    padding: 0px;
    margin: 0px;
`;

const DropDownMenu = styled(Menu)`
    margin-top: 10px; 
`;

const SignOutButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    color: ${COLORS.primary};
    font-weight: 400;
    font-size: 1rem;
    display: flex;
    align-items: center;
    min-height: 36px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: 24px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const Info = styled.span`
    font-size: 0.6rem;
    margin-top: 5px;
    margin-left: 16px;
    display: inline-block;
    color: ${COLORS.primary3}
`
// #endregion

export default ProfileMenu