import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ITimezone } from 'react-timezone-select'

import '../App.css';
import { Page, Card, Title } from "../components/common/CommonStyles";
import { formatDateForSQL } from '../shared/functions/dateAndTime';
import { CancelButton, SaveButton } from '../components/common/Buttons';
import { PostReminderPreferencesRequestBody, getReminderPreferences, postReminderPreferences } from "../api/reminderPreferences";
import { Form, TimeSelector } from "../components/common/FormInputs";
import TimezoneSelect from "../components/molecules/TimezoneSelect";
import TimePicker from "../components/molecules/TimePicker";

import { useAuth } from '../authprovider'
import { SQLTime } from "../shared/types/SQLTime";
import { delay } from "../shared/functions/functions";
import { GLOBALS } from "../values/globals";
import { DropdownEntry } from "../components/molecules/DropdownEntry";
import { yesNoDropdownItems, daysDropdownItems, reminderFrequencyDropdownItems } from "../shared/types/DropdownItems";
import Row from "../components/atoms/Row";
import Column from "../components/atoms/Column";

const ReminderPreferencesPage = () => {
    let auth = useAuth();

    const [ receiveReminders, setReceiveReminders ] = useState(1);
    const [ reminderFrequency, setReminderFrequency ] = useState(1)    
    const [ reminderDay, setReminderDay ] = useState(4);
    const [ reminderTime, setReminderTime ] = useState<SQLTime>("15:30:00");
    const [ timezone, setTimezone ] = useState<ITimezone>("Australia/Sydney")
    
    const [ loading, setLoading ] = useState<boolean>(false)

    const navigate = useNavigate();

    //#region Get employee's preferences
    useEffect(() => {
        const getReminderPreferencesAsync = async () => {
            try {
                const employeeId = auth.user;
                const reminderPreferences = await getReminderPreferences({id: employeeId});
                setReceiveReminders(reminderPreferences.enabled)
                setReminderFrequency(reminderPreferences.frequency === "Daily" ? 0 : 1)
                setReminderDay(reminderPreferences.dayToSend)
                setReminderTime(reminderPreferences.timeToSend)
                setTimezone(reminderPreferences.timezone)
            } catch (error) {
                console.log(error);
            }
        }

        getReminderPreferencesAsync();
    }, []);
    //#endregion

    //#region Button Handlers    
    const handleCancel = (event: any) => {
        event.preventDefault();
        console.log("Cancelling")
        navigate(`/logging/${formatDateForSQL(new Date())}`)
    };
    
    const handleSave = async (event: any) => {
        event.preventDefault();
        console.log(`Saving`)
        
        const requestBody: PostReminderPreferencesRequestBody = {
            id: auth.user,
            enabled: receiveReminders === 1 ? true : false,
            frequency: reminderFrequencyDropdownItems[reminderFrequency],
            day: reminderDay,
            time: reminderTime,
            timezone: timezone
        }

        setLoading(true)

        // Add a minimum loading time for user feedback
        await Promise.all([
            await postReminderPreferences(requestBody),
            delay(GLOBALS.buttonLoadTime)
        ])

        setLoading(false)

        navigate(`/logging/${formatDateForSQL(new Date())}`)
    };
    //#endregion


    return (
        <Page>
            <ReminderPreferencesCard>
                <Title>Reminder Preferences</Title>
                <Form>
                    <Row gap={32}>
                        <Column gap={16}>
                            <DropdownEntry
                                required
                                showHeading
                                headingText="Would you like to receive email reminders?"
                                placeholder="Choose an option"
                                options={yesNoDropdownItems}
                                value={receiveReminders}
                                setValue={(value: any) => setReceiveReminders(Number(value))} />
                            {receiveReminders === 1 && 
                                <DropdownEntry
                                    required
                                    showHeading
                                    headingText="How often would you like to be reminded?"
                                    placeholder="Choose reminder frequency"
                                    options={reminderFrequencyDropdownItems}
                                    value={reminderFrequency}
                                    setValue={(value: any) => setReminderFrequency(Number(value))} />
                            }
                            {(receiveReminders === 1 && reminderFrequency === 1) ?
                                <DropdownEntry
                                    required
                                    showHeading
                                    headingText="What day would you like to be reminded?"
                                    placeholder="Choose reminder day"
                                    options={daysDropdownItems}
                                    value={reminderDay}
                                    setValue={(value: any) => setReminderDay(Number(value))} />
                                :
                                <div style={{height: '63px'}} />
                            }
                        </Column>
                        <Column gap={16}>
                            {receiveReminders === 1 && 
                                <TimePicker time={reminderTime} setTime={setReminderTime} heading="What time would you like to be reminded?" />
                            }
                            {receiveReminders === 1 &&
                                <TimezoneSelect timezone={timezone} setTimezone={setTimezone} heading="What timezone are you in?" />
                            }
                        </Column>
                    </Row>

                    {/* Save and Cancel Buttons */}
                    <RightAlignedRow>
                        <CancelButton onClick={handleCancel}/>
                        <SaveButton onClick={handleSave} text="SAVE" loading={loading} />
                    </RightAlignedRow>
                </Form>
            </ReminderPreferencesCard>
        </Page>      
    )
}

//#region Styles
const ReminderPreferencesCard = styled(Card)`
    width: min-content;
    height: min-content;
    margin-top: 100px;
    padding: 40px;
`;

const RightAlignedRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-self: flex-end;
    margin-top: 32px;
`;
//#endregion

export default ReminderPreferencesPage;