import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { createTheme, ThemeProvider, Theme, useTheme } from '@mui/material/styles'
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import { EntryHeading } from '../atoms/EntryHeading'
import { DropdownItems } from '../../shared/types/DropdownItems';
import { COLORS, FLUIDRA_COLORS } from '../../values/colors'
import '../../styles/loading.scss'

const DropdownTheme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: FLUIDRA_COLORS.darkblue_100
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: FLUIDRA_COLORS.darkblue_500,
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: FLUIDRA_COLORS.teal_500,
                    },
                    padding: '4px 8px',
                    zIndex: 1,
                    fontSize: '12px',
                    minHeight: '40px',
                    width: '300px',
                    borderRadius: '20px',
                    color: FLUIDRA_COLORS.darkblue_500,
                },
                input: {
                    padding: '0px'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    padding: '8px'
                    // height: '40px'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    padding: '11.375px 16px',
                    position: 'absolute',
                    alignContent: 'center',
                    transform: 'none !important',
                    zIndex: 0,
                    fontSize: '12px',
                    // height: '40px',
                    color: FLUIDRA_COLORS.darkblue_100,
                    [`&.Mui-focused`]: {
                        color: COLORS.secondaryBlue
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '12px'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    height: '60px'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: FLUIDRA_COLORS.darkblue_50,
                    height: '24px'
                }
            }
        }
    }
});

interface MultiSelectDropdownEntryProps {
    showHeading: boolean,
    headingText?: string,
    required: boolean,
    placeholder: string,
    options: DropdownItems,
    values: number[],    
    setValues: (value: number[]) => void
}

const getStyles = (option: string, stringValues: string[], theme: Theme) => {    
    return {
        fontWeight:
            stringValues.indexOf(option) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightBold
    }
}

export const MultiSelectDropdownEntry: React.FC<MultiSelectDropdownEntryProps> = ({ showHeading, headingText, required=false, placeholder, options, values, setValues }) => {   
    const theme = useTheme();

    const [ stringValues, setStringValues ] = useState<string[]>([]);
    const [ stringOptions, setStringOptions ] = useState<string[]>([]);
    const [ inverseOptions, setInverseOptions ] = useState<{[key: string]: number;}>({})

    useEffect(() => {
        const stringOptions: string[] = Object.values(options)
        setStringOptions(stringOptions)
        const result = Object.entries(options).map(([key, value]) => { return [value, key]; })
        setInverseOptions(Object.fromEntries(result))
    }, [options])

    useEffect(() => {
        const stringValues: string[] = values.map((value) => { return options[value]})
        setStringValues(stringValues)
    }, [values])

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        let value = event.target.value 
        const stringList: string[] = typeof value === 'string' ? value.split(',') : value
        const numbersList: number[] = stringList.map((item) => { return Number(inverseOptions[item]) })
        setValues(numbersList)
    }

    return (
        <ThemeProvider theme={DropdownTheme}>
            <Column>
                <EntryHeading showHeading={showHeading} headingText={headingText} />
                <FormControl style={{'position': 'relative'}}>
                    <InputLabel style={{'display': stringValues.length > 0 ? 'none' : 'block'}}>
                        {placeholder}
                    </InputLabel>
                    <Select
                        multiple
                        required={required}
                        value={stringValues}
                        onChange={handleChange}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected
                                    .sort((a, b) => stringOptions.indexOf(a) - stringOptions.indexOf(b))
                                    .map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                            </Box>
                        )}>
                        {stringOptions.map((option) => (
                            <MenuItem key={option} value={option} style={getStyles(option, stringValues, theme)}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Column>
        </ThemeProvider>
    )
}

const Column = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`;