import React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import { COLORS } from '../../../values/colors';


const Track = (props: any, state: any) => <StyledTrack {...props} $index={state.index} />;

interface TimeSliderProps {
    id: number,
    onSliderChange: (value: any) => void,
    startingPosition: number
}

const TimeSlider: React.FC<TimeSliderProps> = ({ id, onSliderChange, startingPosition }) => {

    const handleSliderChange = (value: number | readonly number[], index: number) => {
        onSliderChange([id, value]);
    }

    return (
        <StyledSlider 
            min={0}
            max={20}
            value={[startingPosition]}
            onChange={handleSliderChange}
            renderTrack={Track}
            marks
            markClassName='slider-mark'
        />
    );
}

//#region Styles
const StyledSlider = styled(ReactSlider)`
    width: 250px;
    height: 40px;
    margin: 0px 10px 8px 20px;
`;

const StyledTrack = styled.div<{$index: number}>`
    top: 0;
    bottom: 0;
    position: relative;
    background: ${props => (props.$index === 1 ? COLORS.secondaryGray3 : COLORS.secondaryBlue)};
    z-index: ${props => (props.$index === 1 ? '1' : '3')};   // Hide the marks behind the slider
`;
//#endregion

export default TimeSlider;