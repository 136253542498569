import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import '../App.css';
import { Page, Card, Title, Instructions } from "../components/common/CommonStyles";
import { formatDateForSQL } from '../shared/functions/dateAndTime';
import { CancelButton, SaveButton } from '../components/common/Buttons';
import { postFeedback, PostFeedbackRequestBody } from "../api/feedback";
import { sendFeedbackReceivedEmail } from "../api/emails";
import { Form, LargeTextEntry, CheckboxWithLabel } from "../components/common/FormInputs";

import { useAuth } from '../authprovider'
import { delay } from "../shared/functions/functions";
import { GLOBALS } from "../values/globals";

const FeedbackPage = () => {
    let auth = useAuth();

    const [ feedback, setFeedback ] = useState('')
    const [ anonymous, setAnonymous ] = useState(false)

    const [ loading, setLoading ] = useState<boolean>(false)

    const navigate = useNavigate();

    //#region Button Handlers    
    const handleCancel = (event: any) => {
        event.preventDefault();
        navigate(`/logging/${formatDateForSQL(new Date())}`)
    };
    
    const handleSave = async (event: any) => {
        event.preventDefault();

        console.log(`Sending feedback`)

        const requestBody: PostFeedbackRequestBody = {
            name: anonymous ? "Anonymous" : `${auth.firstName} ${auth.lastName}`,
            date: formatDateForSQL(new Date()),
            feedback: feedback
        }

        setLoading(true)

        // Add a minimum loading time for user feedback
        await Promise.all([
            await postFeedback(requestBody),
            await sendFeedbackReceivedEmail(requestBody),
            delay(GLOBALS.buttonLoadTime)
        ])

        setLoading(false)

        await alert("Thanks for your feedback!")
        navigate(`/logging/${formatDateForSQL(new Date())}`)
    };
    //#endregion

    return (
        <Page>
            <FeedbackCard>
                <Title>Feedback</Title>
                <FeedbackInstructions>
                    Welcome to Fluidra Work Logging.
                    Any ideas, criticisms or bug reports for this website would be greatly appreciated!
                </FeedbackInstructions>
                <Form>
                    <LargeTextEntry
                        label="Feedback"
                        value={feedback}
                        setValue={setFeedback}
                        rows={5} />
                    <CheckboxWithLabel
                        value={anonymous}
                        setValue={setAnonymous}
                        label="Send anonymously" />

                    {/* Save and Cancel Buttons */}
                    <div style={{'gridRow': '4', 'gridColumn': '2'}}>
                        <RightAlignedRow>
                            <CancelButton onClick={handleCancel}/>
                            <SaveButton onClick={handleSave} text="SEND" loading={loading} />
                        </RightAlignedRow>
                    </div>
                </Form>
            </FeedbackCard>
        </Page>      
    )
}

//#region Styles
const FeedbackCard = styled(Card)`
    width: 600px;
    height: min-content;
    margin-top: 100px;
    padding: 40px;
`;

const FeedbackInstructions = styled(Instructions)`
    margin-bottom: 20px;
`;

const RightAlignedRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-self: flex-end;
    margin-top: 20px;
`;
//#endregion

export default FeedbackPage;