import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../../values/colors';

interface TabButtonProps {
    onClick: () => void,
    text: string,
    isSelected: boolean
}

export const TabButton: React.FC<TabButtonProps> = ({ onClick, text, isSelected }) => {
    return (
        <TabButtonStyle 
            onClick={onClick}
            $isSelected={isSelected}>
            <TabButtonText>{text}</TabButtonText>
        </TabButtonStyle>
    )
}

interface TabButtonWithImageProps extends TabButtonProps {
    image: string
}

export const TabButtonWithImage: React.FC<TabButtonWithImageProps> = ({ onClick, image, text, isSelected }) => {
    return (
        <TabButtonWithImageStyle 
            onClick={onClick}
            $isSelected={isSelected}>
            <TabButtonImage src={image} />
            <TabButtonText>{text}</TabButtonText>
        </TabButtonWithImageStyle>
    )
}

//#region Styles
export const TabBar = styled.div`
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    width: 100%;
`;

const TabButtonStyle = styled.button<{$isSelected: boolean}>`
    width: 150px;
    height: 70px;
    padding: 10px;
    margin: 5px;
    border: 0px;
    border-radius: 20px;
    
    background-color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;


    background-color: ${props => (props.$isSelected ? COLORS.secondaryGray2 : "transparent")};
    &:hover {
        background-color: ${props => (props.$isSelected ? COLORS.secondaryGray : COLORS.secondaryGray3)};
    }
`;

const TabButtonWithImageStyle = styled.button<{$isSelected: boolean}>`
    width: 250px;
    padding: 10px;
    margin: 5px;
    border: 0px;
    border-radius: 20px;
    
    background-color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;


    background-color: ${props => (props.$isSelected ? COLORS.secondaryGray2 : "transparent")};
    &:hover {
        background-color: ${props => (props.$isSelected ? COLORS.secondaryGray : COLORS.secondaryGray3)};
    }
`;

const TabButtonImage = styled.img`
    height: 32px;
    width: 32px;
    align-self: center;
`;

const TabButtonText = styled.span`
    font-size: 16pt;
`;

export const Separator = styled.div`
    background: ${COLORS.secondaryYellow};
    width: 2px;
    border: none; 
    margin: 10px 5px;

`;
//#endregion