import React, { useState, FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components';
import { FLUIDRA_COLORS } from '../../values/colors';

interface TooltipProps {
    text: string | null;
    offsetX?: number;
    offsetY?: number;
    children: ReactNode;
}

const Tooltip: FunctionComponent<TooltipProps> = ({ text, offsetX=0, offsetY=0, children }) => {
    const [ visibility, setVisibility ] = useState<boolean>(false)

    const hide = () => setVisibility(false)
    const show = () => {
        if (text !== null && text.length > 0) setVisibility(true)
    }

    return (
        <Container>
            <TooltipStyle $visibility={visibility} $offsetX={offsetX} $offsetY={offsetY}>
                <Text>{text}</Text>
            </TooltipStyle>
            <div
                onMouseEnter={show}
                onMouseLeave={hide}
            >
                {children}
            </div>
        </Container>
    )
}

//#region Styles
const maxWidth = '300px';

const Container = styled.div`
    position: relative;
`;

const TooltipStyle = styled.div<{$visibility: boolean, $offsetX: number, $offsetY: number}>`
    visibility: ${props => (props.$visibility ? 'visible' : 'hidden')};
    position: absolute;
    z-index: 10;
    top: ${props => props.$offsetY + 'px'};
    right: ${props => props.$offsetX + 'px'};

    width: auto;
    max-width: ${maxWidth};

    background: white;
    color: ${FLUIDRA_COLORS.darkblue_500};
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    padding: 8px;
    border-radius: 5px;
    
`;

const Text = styled.span`
    width: max-content;
    max-width: ${maxWidth};
    display: block;
    white-space: normal;
    word-wrap: break-word;
    text-align: left;
`;
//#endregion

export default Tooltip;