import axios from "axios";

export async function get(url: string) {
    try {
        // const response = await instance.get(url);
        const response = await axios.get(url);

        // Check if the response status is in the 2xx range (success)
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            // If the response status is not in the 2xx range, handle it as an error
        }
    } catch (error: any) {
        handleError(error, 'GET')
        return error;
    }
}

export async function post(url: string, requestBody: object) {
    try {
        const response = await axios.post(url, requestBody);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else {
            throw new Error(`Request failed with status code ${response.status}`);
        }
    } catch (error: any) {
        handleError(error, 'POST')
        return error;
    }
}

const handleError = (error: any, requestType: string) => {
    if (error.response.status === 401) {
        console.log("Your session has expired, please log back in.")
        window.location.href = "/session-expired"
    } else {
        console.log(error)
        console.error(`Error during ${requestType} request: ${error.message}`)
    }
}

export async function test() {
    return await get('/api/test')
}