import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { getPreviousDate, getNextDate, addMonth, subtractMonth, getPreviousSunday, getNextSaturday, subtractWeek, addWeek, formatDate } from '../../shared/functions/dateAndTime';
import { TimescaleOptions } from '../../shared/enums/timescale';


interface TimePeriodNavigatorProps {
    timescale: TimescaleOptions, 
    startDate: Date, 
    setTimePeriod: (startDate: Date, endDate: Date) => void
}

const TimePeriodNavigator: React.FC<TimePeriodNavigatorProps> = ({ timescale, startDate, setTimePeriod }) => {
    const leftArrow = require('../../resources/left-arrow-primary.png');
    const rightArrow = require('../../resources/right-arrow-primary.png');

    const [ text, setText ] = useState('')

    useEffect(() => {
        const month = startDate.toLocaleString('en-US', { month: 'long' });
        const year = startDate.getFullYear();

        switch(timescale) {
            case 'Daily':
                setText(formatDate(startDate))
                break;
            case 'Weekly':
                const endDate = getNextSaturday(startDate)
                setText(`${formatDate(startDate)} - ${formatDate(endDate)}`)
                break;
            case 'Monthly':
                setText(`${month} - ${year}`);
                break;
            case 'YTD':
                setText(`${year}`)
                break;
        }
    }, [startDate, timescale])


    const prevButtonClicked = () => {
        var date: Date;
        var newStartDate: Date;
        var newEndDate: Date;
        switch(timescale) {
            case 'Daily':
                date = getPreviousDate(startDate)
                newStartDate = getPreviousDate(startDate)
                newEndDate = getPreviousDate(startDate)
                break;
            case 'Weekly':
                date = subtractWeek(startDate)
                newStartDate = getPreviousSunday(date)
                newEndDate = getNextSaturday(date)
                break;
            case 'Monthly':
                date = subtractMonth(startDate);
                newStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
                newEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                break;
            case 'YTD': 
                const year = startDate.getFullYear();
                newStartDate = new Date(year - 1, 0, 1);
                newEndDate = new Date(year, 0, 0);
                break;
        }

        setTimePeriod(newStartDate, newEndDate);
    }

    const nextButtonClicked = () => {
        var date: Date;
        var newStartDate: Date;
        var newEndDate: Date;
        switch(timescale) {
            case 'Daily':
                date = getNextDate(startDate)
                newStartDate = getNextDate(startDate)
                newEndDate = getNextDate(startDate)
                break;
            case 'Weekly':
                date = addWeek(startDate)
                newStartDate = getPreviousSunday(date)
                newEndDate = getNextSaturday(date)
                break;
            case 'Monthly':
                date = addMonth(startDate);
                newStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
                newEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                break;
            case 'YTD': 
                const year = startDate.getFullYear();
                newStartDate = new Date(year + 1, 0, 1);
                newEndDate = new Date(year + 2, 0, 0);
                break;
        }
        setTimePeriod(newStartDate, newEndDate);
    }

    return (
        <Row>
            <NavigationButton onClick={prevButtonClicked}><LeftArrow src={leftArrow} /></NavigationButton>
            <Frame>{text}</Frame>
            <NavigationButton onClick={nextButtonClicked}><RightArrow src={rightArrow} /></NavigationButton>
        </Row>
    );
}

// #region Styles
const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px;
    width: fit-content;
    user-select: none;
`;

const Arrow = styled.img`
    height: 15px;
    width: 15px;
`;

const LeftArrow = styled(Arrow)`
    margin-left: -3px;
`;

const RightArrow = styled(Arrow)`
    margin-left: 3px;
`;

const NavigationButton = styled.button`
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    background-color: white;
    border-radius: 20px;
    border: none;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: #eee;
    }
`;

const Frame = styled.div`
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    margin: 0 20px;
    background-color: white;
    border-radius: 20px;
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
//#endregion

export default TimePeriodNavigator;