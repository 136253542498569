import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';

import Table from './Table';
import { formatDateForSQL } from "../../../shared/functions/dateAndTime";
import { getAllProjects } from "../../../api/projects";
import { getAllEmployees, getManagerEmployees } from "../../../api/employees";
import { getSummaryByEmployee, getManagerSummaryByEmployee, GetSummaryRequestBody, GetManagerSummaryRequestBody } from "../../../api/summary";
import { Summary, SummaryItem } from "../../../shared/types/Summary";
import { ProjectCollection } from "../../../shared/types/Project";
import { EmployeeCollection } from "../../../shared/types/Employee";
import { Card } from "../../common/CommonStyles";
import { COLORS } from "../../../values/colors";
import { getFluidraProjectId, getProjectName } from "../../../shared/functions/projects";
import { getEmployeeName, getFluidraEmployeeId } from "../../../shared/functions/employees";
import { useAuth } from "../../../authprovider";

interface SummaryByEmployeeProps {
    startDate: Date,
    endDate: Date
}

const SummaryByEmployee: React.FC<SummaryByEmployeeProps> = ({ startDate, endDate }) => {
    const [ allProjects, setAllProjects ] = useState<ProjectCollection>({});
    const [ allEmployees, setAllEmployees ] = useState<EmployeeCollection>({});
    const [ summaryByEmployee, setSummaryByEmployee] = useState<Summary>({});

    const scrollableArea = useRef(null)
    const [ scrollFadeHeight, setScrollFadeHeight ] = useState(0);

    const auth = useAuth();

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            getAllProjectsAsync();
            if (auth.isAdmin === 1) {
                getAllEmployeesAsync();
                getSummaryByEmployeeAsync(startDate, endDate);   
            } else if (auth.isManager === 1) {
                const managerId = Number(auth.user);
                getManagerEmployeesAsync(managerId);
                getManagerSummaryByEmployeeAsync(managerId, startDate, endDate);
            } else {
                console.error(`USer is not an admin or manager`)
            }
        }
    }, [startDate, endDate, auth.isAdmin, auth.isManager, auth.user])

    // Add an event listener to track the scroll position
    useEffect(() => {
        const handleScroll = () => {
            const scrollable: any = scrollableArea.current;
            if (scrollable) setScrollFadeHeight(Math.min(20, scrollable.scrollTop));
        };

        const scrollable: any = scrollableArea.current;
        if (scrollable) scrollable.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollable) scrollable.removeEventListener('scroll', handleScroll);
        };
    }, []);

    //#region API Callers
    const getAllProjectsAsync = async () => setAllProjects(await getAllProjects());
    const getAllEmployeesAsync = async () => setAllEmployees(await getAllEmployees());
    const getManagerEmployeesAsync = async (managerId: number) => setAllEmployees(await getManagerEmployees({id: managerId}))
    
    const getSummaryByEmployeeAsync = async (startDate: Date, endDate: Date) => {
        const requestBody: GetSummaryRequestBody = {
            startDate: formatDateForSQL(startDate),
            endDate: formatDateForSQL(endDate)
        }
        const result = await getSummaryByEmployee(requestBody);
        setSummaryByEmployee(result);
    }

    const getManagerSummaryByEmployeeAsync = async (managerId: number, startDate: Date, endDate: Date) => {
        const requestBody: GetManagerSummaryRequestBody = {
            id: managerId,
            startDate: formatDateForSQL(startDate),
            endDate: formatDateForSQL(endDate)
        }
        const result = await getManagerSummaryByEmployee(requestBody);
        setSummaryByEmployee(result);
    }
    // #endregion

    const calcNumProjectsLogged = (projects: SummaryItem[]) => {
        if (projects) {
            return projects.reduce((projectCount, project) => {
                return project.hours > 0 ? projectCount + 1 : projectCount;
            }, 0);
        }
        return 0;
    };

    const calcTotalHoursLogged = (projects: SummaryItem[]) => {
        return projects.reduce((totalHours, project) => {
            return totalHours + project.hours;
        }, 0);
    };

    const formatProjectsForTable = (projects: SummaryItem[]) => {
        const tableData = []
        for (var project of projects) {
            const row = {
                id: getFluidraProjectId(allProjects, project.id),
                name: getProjectName(allProjects, project.id),
                hours: project.hours,
            }
            tableData.push(row);
        }
        return tableData;
    }

    const sortEmployeeSummary = (summaryByEmployee: Summary): string[] =>
        [...Object.keys(summaryByEmployee)]
            .sort((a, b) => allEmployees[Number(a)].fluidraEmployeeId!.localeCompare(allEmployees[Number(b)].fluidraEmployeeId!));

    const employeeHeading = (employeeId: number): string => {
        return `${getFluidraEmployeeId(allEmployees, employeeId)} ${getEmployeeName(allEmployees, employeeId)}`
    }

    return (
        <Page ref={scrollableArea}>
            <ScrollableAreaFadeTop $height={scrollFadeHeight} />
                {sortEmployeeSummary(summaryByEmployee).map((employeeId) => (
                    <EmployeeCard key={employeeId}>
                        <EmployeeHeading>{employeeHeading(Number(employeeId))}</EmployeeHeading>
                        <span>Number of projects worked on: {calcNumProjectsLogged(summaryByEmployee[employeeId])}</span>
                        <span>Total Hours Logged: {calcTotalHoursLogged(summaryByEmployee[employeeId])}</span>
                        <Table 
                            tHeadData={['Project Id', 'Project Name', 'Hours']} 
                            tBodyData={formatProjectsForTable(summaryByEmployee[employeeId])}
                            tColumnWidths={{id: 100, name: 300, hours: 80}} />
                    </EmployeeCard>
                ))}       
            <ScrollableAreaFadeBottom />         
        </Page>
    )
}

// #region Styles

const Page = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: auto;
    justify-content: center;
    padding-bottom: 10px;
`;

const EmployeeCard = styled(Card)`
    width: 480px;
    height: auto;
`;

const EmployeeHeading = styled.h2`
    margin: 0 0 20px 0;
`;

const ScrollableAreaFade = styled.div`
    position: absolute;
    width: calc(100vw - 20px);
`;

const ScrollableAreaFadeTop = styled(ScrollableAreaFade)<{$height: number}>`
    top: 255px;
    height: ${props => props.$height}px;
    background: linear-gradient(${COLORS.primary4}, transparent)
`;

const ScrollableAreaFadeBottom = styled(ScrollableAreaFade)`
    bottom: 10px;
    height: 20px;
    background: linear-gradient(transparent, ${COLORS.primary4})
`;
// #endregion

export default SummaryByEmployee;