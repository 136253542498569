import React from 'react';
import styled from 'styled-components';
import { Draggable } from '@hello-pangea/dnd';
import { COLORS } from '../../../values/colors';
import { Project } from '../../../shared/types/Project';

interface DraggableProjectSmallProps {
    project: Project,
    index: number,
    isDragDisabled: boolean
}

const DraggableProjectSmall: React.FC<DraggableProjectSmallProps> = ({project, index, isDragDisabled}) => {    
    return (
        <Draggable 
            draggableId={project.id.toString()} 
            index={index}
            isDragDisabled={isDragDisabled}
        >
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    $isDragging={snapshot.isDragging}
                    $isDragDisabled={isDragDisabled}
                >
                    <ProjectID>{project.fluidraProjectId}</ProjectID>
                    <ProjectName>{project.name}</ProjectName>
                </Container>
            )}
        </Draggable>
    );
}

//#region Styles
const Container = styled.div<{$isDragDisabled: boolean, $isDragging: boolean}>`
    border-radius: 10px;
    padding: 0px 10px;
    margin-bottom: 4px;
    width: 350px;
    display: flex;
    align-items: center;
    cursor: grab;
    background-color: ${props => 
        props.$isDragDisabled
            ? COLORS.secondaryGray
            : props.$isDragging 
                ? COLORS.secondaryBlue3 
                : COLORS.secondaryGray2};

    &:hover {
        background-color: ${COLORS.secondaryGray};
    }

`;

const ProjectID = styled.span`
    font-weight: bold;
    margin-right: 10px;
    user-select: none;
`;

const ProjectName = styled.span`
    user-select: none;
    text-align: left;
`;
//#endregion

export default DraggableProjectSmall;