import React from "react";
import styled from 'styled-components';

import '../App.css';
import { Page, Card, Title } from "../components/common/CommonStyles";


const UnassignedUserPage = () => {
    return (
        <Page>
            <UnassignedUserCard>
                <Title>Unassigned User</Title>
                <span>This user is not assigned with okta to Fluidra Timesheets.</span>
                <span>Contact your manager or administrator to be assigned.</span>
            </UnassignedUserCard>
        </Page>      
    )
}

//#region Styles
const UnassignedUserCard = styled(Card)`
    width: 400px;
    height: min-content;
    margin-top: 100px;
    padding: 40px;
`;
//#endregion

export default UnassignedUserPage;