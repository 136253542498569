import React from "react";
import styled from 'styled-components';

import '../App.css';
import { Page, Card, Title } from "../components/common/CommonStyles";
import { COLORS } from "../values/colors";

const SessionExpiredPage = () => {
    return (
        <Page>
            <SessionExpiredCard>
                <Title>Session Expired</Title>
                <span>Your session has expired.</span>
                <span style={{marginBottom: '16px'}}>Click the button below to sign back in.</span>
                <form action="/auth/signin" method="post">
                    <Button>SIGN IN</Button>
                </form>
            </SessionExpiredCard>
        </Page>      
    )
}

//#region Styles
const SessionExpiredCard = styled(Card)`
    width: 400px;
    height: min-content;
    margin-top: 100px;
    padding: 40px;
`;

const Button = styled.button`
    height : 40px;
    width: 100%;
    padding: 0px 10px;
    margin: 10px 0px;

    color: white;
    font-size: 16pt;
    font-weight: bold;

    border: none;
    border-radius: 20px;

    cursor: pointer;

    background-color: ${COLORS.secondaryBlue};
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;
//#endregion

export default SessionExpiredPage;