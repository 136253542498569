import { post } from "../adapters/apiAdapter";
import { SQLDate } from "../shared/types/SQLDate";

export type PostFeedbackRequestBody = {
    name: string,
    date: SQLDate,
    feedback: string
}

export async function postFeedback(requestBody: PostFeedbackRequestBody) {
    return await post(`/api/feedback`, requestBody);
}