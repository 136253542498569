import { EmployeeCollection } from "../../../shared/types/Employee";

// #region Actions
export enum EmployeeListActionType {
    populate_list,
    filter
}

interface PopulateListAction {
    type: EmployeeListActionType.populate_list;
    allEmployees: any;
}

interface FilterListAction {
    type: EmployeeListActionType.filter;
    textFilter: string;
}

type EmployeeListAction = PopulateListAction | FilterListAction;
// #endregion

export interface EmployeeListState {
    allEmployees: EmployeeCollection,
    filteredEmployees: EmployeeCollection
}

export const initialEmployeeListState: EmployeeListState = {
    allEmployees: {},
    filteredEmployees: {}
}

export function employeeListReducer(state: EmployeeListState, action: EmployeeListAction) : EmployeeListState {
    switch(action.type) {
        case EmployeeListActionType.populate_list:
            const allEmployees: EmployeeCollection = action.allEmployees;
            return {
                ...state,
                allEmployees: allEmployees,
                filteredEmployees: allEmployees
            }
        case EmployeeListActionType.filter:
            const textFilter: string = action.textFilter;

            const filteredEmployees: EmployeeCollection = Object.values(state.allEmployees).filter(employee => {
                const matchId = employee.fluidraEmployeeId.toLowerCase().includes(textFilter.toLowerCase());
                const fullName = `${employee.firstName} ${employee.lastName}`
                const matchName = fullName.toLowerCase().includes(textFilter.toLowerCase());
                return matchId || matchName
            })

            return {
                ...state,
                filteredEmployees
            }
        default:
            throw new Error(`Invalid employee list action type.`)
    }
}