export type DropdownItems = {
    [key: number]: string;
}

export const emptyDrowdownItems: DropdownItems = {}

export const yesNoDropdownItems: DropdownItems = {0: 'No', 1: 'Yes'}

export const daysDropdownItems: DropdownItems = {
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday",
    6: "Sunday"
}

export const reminderFrequencyDropdownItems: DropdownItems = {
    0: "Daily",
    1: "Weekly"
}

export const monthsDropdownItems: DropdownItems = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
}