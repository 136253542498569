import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';

import Table from './Table';
import { formatDateForSQL } from "../../../shared/functions/dateAndTime";

import { getAllProjects } from "../../../api/projects";
import { getAllEmployees, getManagerEmployees } from "../../../api/employees";
import { getSummaryByProject, getManagerSummaryByProject, GetSummaryRequestBody, GetManagerSummaryRequestBody } from "../../../api/summary";
import { Summary, SummaryItem } from "../../../shared/types/Summary";
import { ProjectCollection } from "../../../shared/types/Project";
import { EmployeeCollection } from "../../../shared/types/Employee";
import { Card } from "../../common/CommonStyles";
import { COLORS } from "../../../values/colors";
import { getFluidraProjectId, getProjectName } from "../../../shared/functions/projects";
import { getEmployeeName, getFluidraEmployeeId } from "../../../shared/functions/employees";
import { useAuth } from '../../../authprovider';

interface SummaryByProjectProps {
    startDate: Date,
    endDate: Date
}

const SummaryByProject: React.FC<SummaryByProjectProps> = ({ startDate, endDate }) => {
    const [ allProjects, setAllProjects ] = useState<ProjectCollection>({});
    const [ allEmployees, setAllEmployees ] = useState<EmployeeCollection>({});
    const [ summaryByProject, setSummaryByProject] = useState<Summary>({});
    
    const scrollableArea = useRef(null)
    const [ scrollFadeHeight, setScrollFadeHeight ] = useState(0);

    const auth = useAuth();

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            getAllProjectsAsync();
            if (auth.isAdmin === 1) {
                getAllEmployeesAsync();
                getSummaryByProjectAsync(startDate, endDate);
            } else if (auth.isManager === 1) {
                const managerId = Number(auth.user);
                getManagerEmployeesAsync(managerId);
                getManagerSummaryByProjectAsync(managerId, startDate, endDate);
            } else {
                console.error(`User is not an admin or manager`)
            }
        }
    }, [startDate, endDate, auth.isAdmin, auth.isManager, auth.user])

    // Add an event listener to track the scroll position
    useEffect(() => {
        const handleScroll = () => {
            const scrollable: any = scrollableArea.current;
            if (scrollable) setScrollFadeHeight(Math.min(20, scrollable.scrollTop));
        };

        const scrollable: any = scrollableArea.current;
        if (scrollable) scrollable.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollable) scrollable.removeEventListener('scroll', handleScroll);
        };
    }, []);

    //#region API Callers
    const getAllProjectsAsync = async () => setAllProjects(await getAllProjects());
    const getAllEmployeesAsync = async () => setAllEmployees(await getAllEmployees());
    const getManagerEmployeesAsync = async (managerId: number) => setAllEmployees(await getManagerEmployees({id: managerId}));
    
    const getSummaryByProjectAsync = async (startDate: Date, endDate: Date) => {
        const requestBody: GetSummaryRequestBody = {
            startDate: formatDateForSQL(startDate),
            endDate: formatDateForSQL(endDate)
        }
        const result = await getSummaryByProject(requestBody);
        setSummaryByProject(result);
    }

    const getManagerSummaryByProjectAsync = async (managerId: number, startDate: Date, endDate: Date) => {
        const requestBody: GetManagerSummaryRequestBody = {
            id: managerId,
            startDate: formatDateForSQL(startDate),
            endDate: formatDateForSQL(endDate)
        }
        const result = await getManagerSummaryByProject(requestBody);
        setSummaryByProject(result);
    }
    // #endregion

    const calcNumEmployeesLogged = (employees: SummaryItem[]) => {
        if (employees) {
            return employees.reduce((employeeCount, employee) => {
                return employee.hours > 0 ? employeeCount + 1 : employeeCount;
            }, 0);
        }
        return 0;
    };

    const calcTotalHoursLogged = (employees: SummaryItem[]) => {
        if (employees) {
            return employees.reduce((totalHours, employee) => {
                return totalHours + employee.hours;
            }, 0);
        }
        return 0;
    };

    const formatEmployeesForTable = (employees: SummaryItem[]) => {
        const tableData = []
        for (var employee of employees) {
            const row = {
                id: getFluidraEmployeeId(allEmployees, employee.id),
                name: getEmployeeName(allEmployees, employee.id),
                hours: employee.hours
            }
            tableData.push(row);
        }
        return tableData;
    }

    const sortAndFilterProjectSummary = (summaryByProject: Summary): string[] =>
        [...Object.keys(summaryByProject)]
            .filter(id => !['-2', '-1', '0'].includes(id))
            .sort((a, b) => getFluidraProjectId(allProjects, Number(a)).localeCompare(getFluidraProjectId(allProjects, Number(b))));

    const projectHeading = (projectId: number): string => {
        return `${getFluidraProjectId(allProjects, projectId)} ${getProjectName(allProjects, projectId)}`
    }

    return (
        <Page ref={scrollableArea}>
            <ScrollableAreaFadeTop $height={scrollFadeHeight} />
            {sortAndFilterProjectSummary(summaryByProject).map((projectId) => (
                <ProjectCard key={projectId}>
                    <ProjectHeading>{projectHeading(Number(projectId))}</ProjectHeading>
                    <span>Number of employees contributed: {calcNumEmployeesLogged(summaryByProject[projectId])}</span>
                    <span>Total hours logged: {calcTotalHoursLogged(summaryByProject[projectId])}</span>
                    <Table
                        tHeadData={['Employee Id', 'Employee Name', 'Hours']}
                        tBodyData={formatEmployeesForTable(summaryByProject[projectId])}
                        tColumnWidths={{id: 120, name: 300, hours: 80}} />
                </ProjectCard>
            ))}      
            <ScrollableAreaFadeBottom />               
        </Page>
    )
}

// #region Styles
const Page = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: auto;
    justify-content: center;
    padding-bottom: 20px;
`;

const ProjectCard = styled(Card)`
    width: 500px;
    height: auto;
`;

const ProjectHeading = styled.h2`
    margin: 0 0 20px 0;
`;

const ScrollableAreaFade = styled.div`
    position: absolute;
    width: calc(100vw - 20px);
`;

const ScrollableAreaFadeTop = styled(ScrollableAreaFade)<{$height: number}>`
    top: 255px;
    height: ${props => props.$height}px;
    background: linear-gradient(${COLORS.primary4}, transparent)
`;

const ScrollableAreaFadeBottom = styled(ScrollableAreaFade)`
    bottom: 10px;
    height: 20px;
    background: linear-gradient(transparent, ${COLORS.primary4})
`;
// #endregion

export default SummaryByProject;