import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Droppable } from '@hello-pangea/dnd';

import { COLORS } from '../../../values/colors';
import { BlueButton } from '../../common/Buttons';
import { Separator, ScrollableAreaFadeTop, ScrollableAreaFadeBottom } from '../../common/CommonStyles';
import DraggableProject from './DraggableProject';
import ConfirmNavigationPopup from "../../common/ConfirmNavigationPopup";
import { formatDateForSQL } from '../../../shared/functions/dateAndTime';
import { Project } from '../../../shared/types/Project';
import { State } from './logging.reducer';
import '../../../styles/scrollbar.scss'

interface FavouriteProjectsListProps {
    state: State,
    date: Date, 
    isDragDisabled: boolean
}

const FavouriteProjectsList: React.FC<FavouriteProjectsListProps> = ({state, date, isDragDisabled}) => {
    const scrollableArea = useRef(null);
    const [ scrollFadeTopPosition, setScrollFadeTopPosition ] = useState(-20);
    const [ projects, setProjects ] = useState<Project[]>([]);

    const navigate = useNavigate();

    // Add an event listener to track the scroll position
    useEffect(() => {
        const handleScroll = () => {
            const scrollable: any = scrollableArea.current;
            console.log(scrollable)
            console.log(typeof scrollable)
            if (scrollable) setScrollFadeTopPosition(Math.min(0, scrollable.scrollTop - 20));
        };

        const scrollable: any = scrollableArea.current;
        if (scrollable) scrollable.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollable) scrollable.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const column = state.columns.favourite_projects_column;
        if (column.projectIds.length === 0) {
            setProjects([]);
        } else {
            const updatedProjects = []
            for (var projectId of column.projectIds) {
                const project = state.projects[projectId];
                if (project) updatedProjects.push(project);
            }
            setProjects(updatedProjects);
        }
    }, [state])

    const handleAddRemoveProjectsButton = () => {
        ConfirmNavigationPopup(`/logging/favourite-projects/${formatDateForSQL(date)}`, state.unsavedChanges, navigate)
    }

    return (
        <FavouriteProjectsListCard className='scrollableList'>
            <Droppable droppableId={state.columns.favourite_projects_column.id.toString()}>
            {(provided, snapshot) => (
                <FavouriteProjectsListArea
                    ref={provided.innerRef} 
                    {...provided.droppableProps}>
                    <ScrollableAreaFadeTop $position={scrollFadeTopPosition} />
                    {projects.length > 0
                        ? <FavouriteProjectsListStyle ref={scrollableArea}>
                            {projects.map((project, index) => (
                                    <li key={index}>
                                        <DraggableProject key={project.id} project={project} index={index} isDragDisabled={isDragDisabled} />
                                    </li>
                                ))}
                            {provided.placeholder}
                            <Spacer />
                        </FavouriteProjectsListStyle>
                        : <Center><Instructions>Create a list of the projects that you are working on by clicking the button below</Instructions></Center>
                    }
                    <ScrollableAreaFadeBottom />
                </FavouriteProjectsListArea>
            )}
            </Droppable>
            <Separator />
            <BlueButton onClick={handleAddRemoveProjectsButton} buttonText="Add/Remove Projects" />
        </FavouriteProjectsListCard>
    );
}

//#region Styles
const FavouriteProjectsListCard = styled.div`
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    margin: 10px;
    padding: 20px 6px 20px 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
`;

const FavouriteProjectsListArea = styled.div`
    position: relative;
    left: 0px;
    width: 284px;
    height: calc(100vh - 260px);
`;

const FavouriteProjectsListStyle = styled.ul`
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    background-color: white;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
`;

const Spacer = styled.li`
    height: 10px;
`;

const Center = styled.div`
    height: 100%;
    margin-right: 10px;
    text-align: center;
    display: table;
`;

const Instructions = styled.span`
    font-size: 18px;
    color: ${COLORS.secondaryGray};
    display: table-cell;
    vertical-align: middle;
`;
//#endregion

export default FavouriteProjectsList;
