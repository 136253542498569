import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface CardProps {
    style?: {};
    children: ReactNode;
}

const Card: FunctionComponent<CardProps> = ({ style={}, children }) => (
    <CardStyle style={style}>
        {children}
    </CardStyle>
)

const CardStyle = styled.div`
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    margin: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
`

export default Card;