import React, { useState } from 'react';
import styled from 'styled-components';

import { createTheme, ThemeProvider, styled as muiStyled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput, { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DropdownItems } from '../../shared/types/DropdownItems';
import { COLORS } from '../../values/colors'
import '../../styles/loading.scss'

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

//#region Text Entry
const TextEntryStyle = muiStyled(TextField)({
    '& label.Mui-focused': {
        color: COLORS.secondaryBlue,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: COLORS.primary3
        },
        '&.Mui-focused fieldset': {
            borderColor: COLORS.secondaryBlue,
        },
        '&:hover fieldset': {
            borderColor: COLORS.primary
        },
        '&:hover.Mui-focused fieldset': {
            borderColor: COLORS.secondaryBlue2
        },
    },
});

interface TextEntryProps {
    type?: string, 
    value: string,
    setValue: (value: string) => void,
    label?: string,
    error?: boolean,
    errorMessage?: string,
    autoComplete?: string,
    onClick?: any
}

export const TextEntry: React.FC<TextEntryProps> = ({ type="input", value, setValue, label="", error=false, errorMessage=" ", autoComplete="", onClick=()=>{} }) => {   
    return (
        <TextEntryStyle
            label={label}
            type={type}
            autoComplete={autoComplete}
            value={value}
            onChange={e => setValue(e.target.value)} 
            error={error}
            helperText={errorMessage}
            margin="dense"
            onClick={onClick}
            />
    )
}
//#endregion

//#region Large Text Entry
const LargeTextEntryStyle = muiStyled(TextField)({
    '& label.Mui-focused': {
        color: COLORS.secondaryBlue,
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: COLORS.secondaryBlue,
        },
    },
});

interface LargeTextEntryProps { 
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    label?: string,
    rows?: number
}

export const LargeTextEntry: React.FC<LargeTextEntryProps> = ({ value, setValue,  label="", rows=5 }) => {

    return (
        <LargeTextEntryStyle
            multiline
            rows={rows}
            label={label}
            value={value}
            onChange={e => setValue(e.target.value)} />
    )
}
//#endregion

//#region Checkbox
interface CheckboxWithLabelProps { 
    value: boolean,
    setValue: React.Dispatch<React.SetStateAction<boolean>>,
    label: string
}

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({ value, setValue, label }) => {

    const handleCheckbox = () => {
        setValue(value ? false : true)
    }

    return (
        <FormControlLabel
            style={{
                'marginLeft': '0px', 
                'marginTop': '10px', 
                // 'spacing': '10px'
            }}
            label={label}
            control={
                <Checkbox
                    checked={value}
                    onChange={handleCheckbox}
                    sx={{
                        '&.Mui-checked': {
                            color: COLORS.secondaryBlue
                        }
                    }} />
                } />
    )
}
//#endregion

//#region Submit Button
const SubmitButtonStyle = styled.input`
    background-color: ${COLORS.secondaryBlue};
    color: white;
    border: 0px solid black;
    border-radius: 200px;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 5px 10px;
    
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    justify-content: center;
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;

interface SubmitButtonProps {
    label: string
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ label }) => {
    return (
        <SubmitButtonStyle
            type="submit"
            value={label} />
    ) 
}

//#endregion

//#region Time Picker
const TimePickerStyle = muiStyled(TimePicker)({
    '& label.Mui-focused': {
        color: COLORS.secondaryBlue,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: COLORS.primary3
        },
        '&.Mui-focused fieldset': {
            borderColor: COLORS.secondaryBlue,
        },
        '&:hover fieldset': {
            borderColor: COLORS.primary
        },
        '&:hover.Mui-focused fieldset': {
            borderColor: COLORS.secondaryBlue2
        },
    },
    '& .MuiButtonBase-root': {
        height: '40px',
        width: '40px',
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: COLORS.secondaryGray3,
        }
    },
    '& .Mui-focused': {
        '& .MuiSvgIcon-root': {
            color: COLORS.secondaryBlue,
        },
        '&:hover': {
            '& .MuiSvgIcon-root': {
                color: COLORS.secondaryBlue2,
            },
        }
    },
    '& .MuiSvgIcon-root': {
        color: COLORS.primary3,
    },
});

interface TimeSelectorProps {
    value: any,
    setValue: any,
    label?: string
}

export const TimeSelector: React.FC<TimeSelectorProps> = ({ value, setValue, label="" }) => {   
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePickerStyle 
                label={label} 
                value={value}
                onChange={(newValue) => setValue(newValue)} />
        </LocalizationProvider>
    )
}
//#endregion

//#region Drop-down Menu
const DropDownMenuStyle = muiStyled(Select)(() => ({
    '& label.MuiFormLabel-focused': {
        color: COLORS.secondaryBlue,
    },
    '& .MuiFormControl-root': {
        '& fieldset': {
          borderColor: COLORS.primary3
        },
        '&.Mui-focused fieldset': {
            borderColor: COLORS.secondaryBlue,
        },
        '&:hover fieldset': {
            borderColor: COLORS.primary
        },
        '&:hover.Mui-focused fieldset': {
            borderColor: COLORS.secondaryBlue2
        },
    },
}));

const MenuItemStyle = muiStyled(MenuItem)({

});

interface DropDownMenuProps {
    options: any[],
    value: any,
    setValue: any,
    label?: string
}

export const DropDownMenu: React.FC<DropDownMenuProps> = ({ options, value, setValue, label="" }) => {

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <DropDownMenuStyle
                label={label}
                value={value ? value : ""}
                onChange={e => setValue(e.target.value)}>
                {options.map((option) => (
                    <MenuItemStyle 
                        key={option} 
                        value={option}>
                        {option}
                    </MenuItemStyle>
                ))}
            </DropDownMenuStyle>
        </FormControl>
    )
}
//#endregion


const AdminInputTheme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: COLORS.primary3,
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: COLORS.primary
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: COLORS.secondaryBlue,
                    },
                    // padding: '5px 10px',
                    padding: '8.5px 14px',
                    zIndex: 1,
                    fontSize: '10pt',
                },
                input: {
                    padding: '0px'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    opacity: '0.6',
                    top: '8.5px !important',
                    left: '14px !important',
                    position: 'absolute',
                    transform: 'none !important',
                    zIndex: 0,
                    fontSize: '10pt',
                    [`&.Mui-focused`]: {
                        color: COLORS.secondaryBlue
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '10pt'
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    height: '60px'
                }
            }
        }
    }
});

const InputFrame = styled.div`
    display: grid;
    grid-template-columns: 180px 350px;
    // align-items: center;
    margin-bottom: 10px;
`;

const AdminInputLabel = styled.span`
    margin-top: 9px; 
`;

interface AdminTextEntryProps {
    required?: boolean, 
    label?: string, 
    type?: string, 
    value: any, 
    setValue: any, 
    error?: boolean, 
    errorMessage?: string, 
    onClick?: any
}

export const AdminTextEntry: React.FC<AdminTextEntryProps> = ({ required=false, label="", type="input", value, setValue, error=false, errorMessage=" ", onClick=()=>{} }) => {       
    return (
        <ThemeProvider theme={AdminInputTheme}>
            <InputFrame>
                <AdminInputLabel>{label}</AdminInputLabel>
                <OutlinedInput
                    required={required}
                    placeholder={label}
                    value={value !== null ? value : ""}
                    onChange={e => setValue(e.target.value)} 
                    type={type}
                    error={error}
                    // helperText={errorMessage}
                    margin="dense"
                    onClick={onClick}
                    />
                </InputFrame>
        </ThemeProvider>
    )
}

interface AdminLargeTextEntryProps {
    required?: boolean, 
    label?: string, 
    type?: string, 
    value: any, 
    setValue: any, 
    error?: boolean, 
    errorMessage?: string, 
    onClick?: any
}

export const AdminLargeTextEntry: React.FC<AdminLargeTextEntryProps> = ({ required=false, label="", type="input", value, setValue, error=false, errorMessage=" ", onClick=()=>{}}) => {

    return (
        <ThemeProvider theme={AdminInputTheme}>
            <InputFrame>
                <AdminInputLabel>{label}</AdminInputLabel>
                <OutlinedInput
                    multiline
                    required={required}
                    rows={5}
                    placeholder={label}
                    value={value}
                    onChange={e => setValue(e.target.value)} 
                    type={type}
                    error={error}
                    // helperText={errorMessage}
                    margin="dense"
                    onClick={onClick}
                    />
                </InputFrame>
        </ThemeProvider>
    )
}


interface AdminDropDownMenuProps {
    required: boolean, 
    label: string, 
    placeholder: string,
    options: DropdownItems,
    value: number | null,    
    setValue: (value: number) => void
}

export const AdminDropDownMenu: React.FC<AdminDropDownMenuProps> = ({ required=false, label, placeholder, options, value, setValue }) => {   
    return (
        <ThemeProvider theme={AdminInputTheme}>
            <InputFrame>
                <AdminInputLabel>{label}</AdminInputLabel>
                <FormControl style={{'position': 'relative'}}>
                    <InputLabel style={{'display': value !== null ? 'none' : 'block'}}>
                        {placeholder}
                    </InputLabel>
                    <Select
                        required={required}
                        value={value !== null ? value : ""}
                        onChange={e => setValue(e.target.value as number)}>
                        {Object.entries(options).map(([id, option]) => (
                            <MenuItem key={id} value={id}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </InputFrame>
        </ThemeProvider>
    )
}

interface AdminCheckboxWithLabelProps {
    required?: boolean,
    label: string,
    value: any,
    setValue: any
}

export const AdminCheckboxWithLabel: React.FC<AdminCheckboxWithLabelProps> = ({ required=false, label, value, setValue}) => {
    const handleCheckbox = () => {
        setValue(value ? false : true)
    }

    return (
        <FormControlLabel 
            style={{
                'marginLeft': '10px', 
                'marginTop': '0px', 
                // 'spacing': '5px'
            }}
            label={label} 
            control={
            <Checkbox 
                required={required}
                checked={value} 
                onChange={handleCheckbox}
                sx={{
                    marginRight: '20px',
                    height: '30px',
                    width: '30px',
                    color: COLORS.secondaryBlue2,
                    '&.Mui-checked': {
                        color: COLORS.secondaryBlue
                    }
                }} />
            } />
    )
}


const CheckboxLabel = muiStyled(FormControlLabel)(() => ({
    '& .MuiFormControlLabel-label': {
        width: '180px',
        userSelect: 'none'
    },
}))

interface AdminCheckboxWithLabelAlternateProps {
    required?: boolean,
    label: string,
    value: boolean,
    setValue: any
}

export const AdminCheckboxWithLabelAlternate: React.FC<AdminCheckboxWithLabelAlternateProps> = ({ required=false, label, value, setValue}) => {
    const handleCheckbox = () => {
        setValue(value ? false : true)
    }

    return (
        <CheckboxLabel 
            style={{
                'alignSelf': 'start',
                'justifyContent': 'left',
                // 'spacing': '5px',
                // 'marginRight': '10px',
                'marginLeft': '0px',
                'width': '565px'
            }}
            label={label} 
            labelPlacement='start'
            control={
            <Checkbox 
                required={required}
                checked={value} 
                onChange={handleCheckbox}
                sx={{
                    // marginLeft: '20px',
                    height: '30px',
                    width: '30px',
                    color: COLORS.secondaryBlue2,
                    '&.Mui-checked': {
                        color: COLORS.secondaryBlue
                    }
                }} />
            } />
    )
}

interface AdminCheckboxProps {
    value: any,
    setValue: any
}

export const AdminCheckbox: React.FC<AdminCheckboxProps> = ({ value, setValue}) => {
    const handleCheckbox = () => {
        setValue(value ? false : true)
    }

    return (
        <Checkbox 
            checked={value} 
            onChange={handleCheckbox}
            sx={{
                marginRight: '20px',
                height: '30px',
                width: '30px',
                color: COLORS.secondaryBlue2,
                '&.Mui-checked': {
                    color: COLORS.secondaryBlue
                }
            }} 
        />
    )
}

const AdminSubmitButtonStyle = styled.input`
    background-color: ${COLORS.secondaryBlue};
    color: white;
    border: 0px solid black;
    border-radius: 200px;
    box-sizing: border-box;
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 5px 10px;
    
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    justify-content: center;
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;

const AdminLoadingStyle = styled.div`
    background-color: ${COLORS.secondaryBlue};
    color: white;
    border: 0px solid black;
    border-radius: 200px;
    box-sizing: border-box;
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 5px 10px;

    font-size: 14pt;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    justify-content: center;
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;

interface AdminSubmitButtonProps {
    label: string,
    loading?: boolean
}

export const AdminSubmitButton: React.FC<AdminSubmitButtonProps> = ({ label, loading=false }) => {
    
    if (loading) {
        return (
            <AdminLoadingStyle>
                <div className="loading-icon"><div></div><div></div><div></div><div></div></div>
            </AdminLoadingStyle>
        )
    } else {
        return (
            <AdminSubmitButtonStyle
                type="submit"
                value={label} />
        )
    }
}

interface DatePickerButtonProps {
    // date: Date;
    // setDate: any;
}

// export const DatePickerButton: React.FC<DatePickerButtonProps> = () => {
//     const [ isForcePickerOpen, setIsOpen ] = useState(false);
//     // const [ date, setDate ] = useState(dayjs('2024-02-02'))

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs} >
//             <DatePicker 
//                 renderInput={() => (

//                 )} />
//         </LocalizationProvider>
//     )
// }
