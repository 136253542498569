import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Droppable } from '@hello-pangea/dnd';

import { State } from './favourites.reducer'
import SearchBar from './SearchBar';
import Dropdown from './Dropdown';
import { SoftCard, Column, Heading } from '../../common/CommonStyles';
import Project from './DraggableProjectSmall';
import { COLORS } from '../../../values/colors';
import '../../../styles/scrollbar.scss'
import { ProjectTypes } from '../../../shared/types/ProjectType';

interface AllProjectsColumnProps {
    state: State,
    textFilter: string,
    setTextFilter: React.Dispatch<React.SetStateAction<string>>;
    types: ProjectTypes,
    typeFilter: number,
    setTypeFilter: React.Dispatch<React.SetStateAction<number>>;
}

const AllProjectsColumn: React.FC<AllProjectsColumnProps> = ({ state, textFilter, setTextFilter, types, typeFilter, setTypeFilter }) => {
    const scrollableArea: any = useRef(null);
    const [ scrollFadeTopPosition, setScrollFadeTopPosition ] = useState(-20);

    // Add an event listener to track the scroll position
    useEffect(() => {
        const handleScroll = () => {
            const scrollable = scrollableArea.current;
            if (scrollable) setScrollFadeTopPosition(Math.min(0, scrollable.scrollTop - 20));
        };

        const scrollable = scrollableArea.current;
        if (scrollable) scrollable.addEventListener('scroll', handleScroll);
        
        return () => {
            if (scrollable) scrollable.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const column = state.columns.all_projects_column
    const projects = column.projectIds
        .filter(projectId => projectId !== null)
        .map(projectId => state.projects[projectId!]);

    return (
        <ColumnStyle>
            <Heading>All projects</Heading>
            <Row>
                <SearchBar textFilter={textFilter} setTextFilter={setTextFilter} />
                <Dropdown types={types} typeFilter={typeFilter} setTypeFilter={setTypeFilter} />
            </Row>
            <AllProjectsSoftCard className='scrollableList'>
                <Droppable droppableId={column.id.toString()}>
                {(provided, snapshot) => (
                    <ProjectList 
                        ref={provided.innerRef} 
                        {...provided.droppableProps}
                        $isDraggingOver={snapshot.isDraggingOver}
                    >
                        <ScrollableAreaFadeTop $position={scrollFadeTopPosition} />
                        <ProjectListStyle ref={scrollableArea}>
                            {projects.map((project, index) => (
                                <Project key={project.id} project={project} index={index} isDragDisabled={false}/>
                            ))}
                            {provided.placeholder}
                            <Spacer />
                        </ProjectListStyle>
                        <ScrollableAreaFadeBottom />
                    </ProjectList>
                )}
                </Droppable>
            </AllProjectsSoftCard>
        </ColumnStyle>
    );
}

//#region Styles
const ColumnStyle = styled(Column)`
    position: relative;
`

const AllProjectsSoftCard = styled(SoftCard)`
    padding-right: 6px;
`;

interface ProjectListProps {
    $isDraggingOver?: boolean;
}

const ProjectList = styled.div<ProjectListProps>`
    min-height: 100px;
    height: 300px;
    width: 384px;
    position: relative;
    background-color: ${(props) => props.$isDraggingOver ? 'transparent' : 'transparent'}
`;

const ProjectListStyle = styled.ul`
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    overflow-y: auto;
    flex-grow: 1;
    height: 100%;
`;

const ScrollableAreaFade = styled.div`
    position: absolute;
    width: 370px;
    height: 20px;
`;

interface ScrollableAreaFadeTopProps {
    $position: number
}

const ScrollableAreaFadeTop = styled(ScrollableAreaFade)<ScrollableAreaFadeTopProps>`
    top: ${props => props.$position}px;
    background: linear-gradient(${COLORS.secondaryGray4}, transparent);
`;


const ScrollableAreaFadeBottom = styled(ScrollableAreaFade)`
    bottom: 0px;
    background: linear-gradient(transparent, ${COLORS.secondaryGray4});
`;

const Spacer = styled.li`
    height: 10px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
//#endregion

export default AllProjectsColumn;