import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

interface RowProps {
    style?: {};
    gap?: number;
    children: ReactNode;
}

const Row: FunctionComponent<RowProps> = ({ style={}, gap=0, children }) => (
    <RowStyle $gap={gap} style={style}>
        {children}
    </RowStyle>
)

const RowStyle = styled.div<{$gap: number}>`
    display: flex;
    flex-direction: row;
    column-gap: ${props => props.$gap}px;
`

export default Row