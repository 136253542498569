import { Employee, EmployeeCollection } from "../types/Employee";
import { DropdownItems } from "../types/DropdownItems";

export const getEmployeeName = (allEmployees: EmployeeCollection, id: number): string => {
    if (id in allEmployees) {
        return `${allEmployees[id].firstName} ${allEmployees[id].lastName}`
    } else {
        return "Name unknown";
    }
}

export const getFluidraEmployeeId = (allEmployees: EmployeeCollection, id: number): string => {
    if (id in allEmployees) {
        return allEmployees[id].fluidraEmployeeId
    } else {
        return "Id unknown";
    }
}

export const sortByFluidraEmployeeId = (list: Employee[]) => [...list].sort((a, b) => a.fluidraEmployeeId > b.fluidraEmployeeId ? 1 : -1);

export const getEmployeeEmail = (allEmployees: EmployeeCollection, id: number): string => {
    if (id in allEmployees) {
        return allEmployees[id].email
    } else {
        return "Email unknown";
    }
}

export const convertEmployeeCollectionToDropdownItems = (employees: EmployeeCollection): DropdownItems => {
    const dropdownItems: DropdownItems = {}
    Object.values(employees).forEach(employee => dropdownItems[employee.id] = `${employee.firstName} ${employee.lastName}`)
    return dropdownItems;
}