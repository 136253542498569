import React from "react";
import styled from 'styled-components';

import { COLORS } from '../../values/colors';
import { getFluidraProjectId } from "../../shared/functions/projects";
import { LogItem } from "../../shared/types/Logs";
import { ProjectCollection } from "../../shared/types/Project";

interface LogsListProps {
    log: LogItem[],
    allProjects: ProjectCollection
}

const LogsList: React.FC<LogsListProps> = ({log, allProjects}) => {

    const getLogEntryName = (projectId: number) => {
        if (projectId.toString() === '-1') {
            return 'Leave';
        } else if (projectId.toString() === '0') {
            return 'No Logs';
        } else if (projectId.toString() === '-1') {
            return 'Public Holiday'
        }
        return getFluidraProjectId(allProjects, projectId);
    }

    const getLogEntry = (project: any) => {
        if (project.projectId.toString() === '0') {
            return (
                <></>
            )
        } else if (project.projectId.toString() === '-1') {
            return (
                <LogEntryBlue>
                    <LogEntryText>Leave</LogEntryText>
                    <LogEntryText>{project.hours} hour{project.hours > 1 ? 's' : ''}</LogEntryText>
                </LogEntryBlue>
            )
        } else if (project.projectId.toString() === '-2') {
            return (
                <LogEntryBlue>
                    <LogEntryText>Public Holiday</LogEntryText>
                </LogEntryBlue>
            )
        }
        return (
            <LogEntryGreen>
                <LogEntryText>{getLogEntryName(project.projectId)}</LogEntryText>
                <LogEntryText>{project.hours} h{log.length >= 4 ? '' : 'ou'}r{project.hours > 1 ? 's' : ''}</LogEntryText>
            </LogEntryGreen>
        )
    }

    return (
        <LogsListStyle $isLong={log.length >= 4}>
            {log.map((project: LogItem, index: number) => (
                <li key={index}>{getLogEntry(project)}</li>
            ))}
        </LogsListStyle>
    )
    
}

//#region Styles
const LogsListStyle = styled.ul<{$isLong: boolean}>`
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
    columns: ${props => props.$isLong ? '2' : '1'};
    column-gap: 0px;
`;

const LogEntry = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 3px 3px 3px;
    border-radius: 100px;
    padding: 3px 10px;
`;

const LogEntryGreen = styled(LogEntry)`
    background-color: ${COLORS.secondaryGreen};
`;

const LogEntryBlue = styled(LogEntry)`
    background-color: ${COLORS.secondaryBlue};
`;

const LogEntryText = styled.span`
    color: white;
    font-size: 10px;
    font-weight: bold;
`;

//#endregion

export default LogsList;