import { Project, ProjectCollection } from "../types/Project";

export const getProjectName = (allProjects: ProjectCollection, id: number): string => {
    if (id in allProjects) {
        return allProjects[id].name || ""
    } else {
        return "Name Unknown";
    }
}

export const getFluidraProjectId = (allProjects: ProjectCollection, id: number): string => {
    if (id in allProjects) {
        return allProjects[id].fluidraProjectId || ""
    } else {
        return "Id Unknown";
    }
}

export const sortByFluidraProjectId = (list: Project[]) => [...list].sort((a, b) => {
    if (a.fluidraProjectId === null && b.fluidraProjectId === null) return 0;
    else if (a.fluidraProjectId === null) return 1;
    else if (b.fluidraProjectId === null) return -1;
    else return a.fluidraProjectId.localeCompare(b.fluidraProjectId);
});
