import { get, post } from "../adapters/apiAdapter"
import { SQLTime } from "../shared/types/SQLTime"
import { ITimezone } from "react-timezone-select"

export type GetReminderPreferencesRequestBody = {
    id: number
}

export type PostReminderPreferencesRequestBody = {
    id: number,
    enabled: boolean,
    frequency: string,
    day: number,
    time: SQLTime,
    timezone: ITimezone
}

export async function getReminderPreferences(requestBody: GetReminderPreferencesRequestBody) {
    const response = await get(`/api/reminder-preferences?id=${requestBody.id}`);
    return response.data;
}

export async function postReminderPreferences(requestBody: PostReminderPreferencesRequestBody) {
    return await post(`/api/reminder-preferences`, requestBody);
}