import React, { useState, useEffect, FunctionComponent } from "react";
import { useAuth } from "../../authprovider";
import styled from "styled-components";
import Column from "../atoms/Column"
import Row from "../atoms/Row";
import { FormControl, MenuItem, Select, ThemeProvider, createTheme } from "@mui/material";
import { FLUIDRA_COLORS } from "../../values/colors";
import { DropdownItems } from "../../shared/types/DropdownItems";
import { convertEmployeeCollectionToDropdownItems } from "../../shared/functions/employees";
import { getAllEmployees } from "../../api/employees";
import { useLocation } from "react-router-dom";

const DropdownTheme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: 'none'
                },
                root: {
                    padding: '0px 16px',
                    fontSize: '12px',
                    borderRadius: '20px',
                    backgroundColor: 'white',
                    color: FLUIDRA_COLORS.darkblue_500,
                },
                input: {
                    padding: '0px'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    height: '30px',
                    width: '200px'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    padding: '0px 16px',
                    position: 'absolute',
                    alignContent: 'center',
                    transform: 'none !important',
                    zIndex: 0,
                    fontSize: '12px',
                    height: '40px',
                    color: FLUIDRA_COLORS.darkblue_100,
                    [`&.Mui-focused`]: {
                        color: FLUIDRA_COLORS.teal_500
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '12px'
                }
            }
        },
    }
});

interface ActAsUserMenuProps {
    actingAs: number | null,    
    setActingAs: (value: number) => void
}

const ActAsUserMenu: FunctionComponent<ActAsUserMenuProps> = ({ actingAs, setActingAs }) => {
    let auth = useAuth()
    let location = useLocation();

    const [ show, setShow ] = useState<boolean>(false)
    const [ employees, setEmployees ] = useState<DropdownItems>([])
    const getAllEmployeesAsync = async () => setEmployees(convertEmployeeCollectionToDropdownItems(await getAllEmployees()));
    
    useEffect(() => {
        if (auth.isAdmin) getAllEmployeesAsync();
    }, [auth]);

    useEffect(() => {
        if (actingAs === null) setMe()
    }, [actingAs, employees])

    useEffect(() => {
        setShow(location.pathname.startsWith('/logging'))
    }, [location.pathname])

    const setMe = () => {
        if (Object.entries(employees).length > 0) setActingAs(auth.user)
    }

    return (
        auth.isAdmin && show
            ? <Row gap={8}>
                <ThemeProvider theme={DropdownTheme}>
                    <Column gap={4}>
                        <ActingAsLabel>Acting as:</ActingAsLabel>
                        <FormControl style={{'position': 'relative'}}>
                            <Select
                                required
                                value={actingAs !== null ? actingAs : ""}
                                onChange={e => setActingAs(e.target.value as number)}>
                                {Object.entries(employees).map(([id, employee]) => (
                                    <MenuItem key={id} value={id}>
                                        {employee}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Column>
                </ThemeProvider>
                <MeButton onClick={setMe}>Me</MeButton> 
            </Row>
            : <div></div>
    )
}

// #region Styles
const ActingAsLabel = styled.span`
    color: white;
    font-size: 12px;
`

const MeButton = styled.button`
    height: 30px;
    width: 40px;
    border: none;
    border-radius: 15px;

    align-self: end;
`
// #endregion

export default ActAsUserMenu