import { get, post } from "../adapters/apiAdapter";
import { Employee, EmployeeCollection } from "../shared/types/Employee";

//#region Add Employee
export type PostAddEmployeeRequestBody = {
    fluidraEmployeeId: string,
    firstName: string,
    lastName: string,
    email: string,
    isAdmin: boolean,
    isManager: boolean,
    departmentId: number,
    active: boolean,
    managerId: number
}

export async function postAddEmployee(requestBody: PostAddEmployeeRequestBody) {
    return await post(`/api/employees/add`, requestBody);
}
//#endregion

//#region Edit Employee
export type PostEditEmployeeRequestBody = PostAddEmployeeRequestBody & {
    id: number
}

export async function postEditEmployee(requestBody: PostEditEmployeeRequestBody) {
    return await post(`/api/employees/edit`, requestBody);
}
//#endregion

//#region Delete Employee
export type PostDeleteEmployeeRequestBody = {
    id: number
}

export async function postDeleteEmployee(requestBody: PostDeleteEmployeeRequestBody) {
    return await post(`/api/employees/delete`, requestBody);
}
//#endregion

//#region Get All Employees
export async function getAllEmployees(): Promise<EmployeeCollection> {
    const response = await get(`/api/employees/all`);
    const processedResponse: EmployeeCollection = {}
    for (const id in response.data) {
        const employee: Employee = response.data[Number(id)];
        employee.active = response.data[Number(id)].active === 1;
        employee.isAdmin = response.data[Number(id)].isAdmin === 1;
        employee.isManager = response.data[Number(id)].isManager === 1;
        processedResponse[Number(id)] = employee
    }
    return processedResponse;
}

//#endregion

//#region Get Manager Employees
export type GetManagerEmployeesRequestBody = {
    id: number
}

export async function getManagerEmployees(requestBody: GetManagerEmployeesRequestBody): Promise<EmployeeCollection> {
    const response = await get(`/api/employees/manager?id=${requestBody.id}`);
    const processedResponse: EmployeeCollection = {}
    for (const id in response.data) {
        const employee: Employee = response.data[Number(id)];
        employee.active = response.data[Number(id)].active === 1;
        employee.isAdmin = response.data[Number(id)].isAdmin === 1;
        employee.isManager = response.data[Number(id)].isManager === 1;
        processedResponse[Number(id)] = employee
    }
    return processedResponse;
}
//#endregion





