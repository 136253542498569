const MONTHS = {
    JANUARY: 'January', 
    FEBRUARY: 'February', 
    MARCH: 'March', 
    ARPIL: 'April',
    MAY: 'May', 
    JUNE: 'June', 
    JULY: 'July', 
    AUGUST: 'August',
    SEPTEMBER: 'September', 
    OCTOBER: 'October', 
    NOVEMBER: 'November', 
    DECEMBER: 'December'
} as const;

type ObjectValues<T> = T[keyof T];

export type Months = ObjectValues<typeof MONTHS>;

export const monthsList = Object.values(MONTHS);