import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from 'styled-components';

import { formatDateForSQL } from "../../shared/functions/dateAndTime";
import ConfirmNavigationPopup from "./ConfirmNavigationPopup";

interface FluidraTimesheetsButtonProps {
    unsavedChanges: boolean
}

const FluidraTimesheetsButton: React.FC<FluidraTimesheetsButtonProps> = ({ unsavedChanges=false }) => {
    const fluidraTimesheetsLogo = require('../../resources/fluidra-timesheets-logo-transparent.png');
  
    const navigate = useNavigate();

    const handleButtonClick = () => {
        ConfirmNavigationPopup(`/logging/${formatDateForSQL(new Date())}`, unsavedChanges, navigate)
    };

    return (
        <Button onClick={handleButtonClick}>
            <Logo src={fluidraTimesheetsLogo} alt="Fluidra Timesheets"/>
        </Button>
    )  
    
}

// #region Styles
const Button = styled.button`
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
`;

const Logo = styled.img`
    height: 60px;
`;
// #endregion

export default FluidraTimesheetsButton