import { get } from "../adapters/apiAdapter";


//#region Get Departments
export async function getDepartments(): Promise<Record<number, string>> {
    const response = await get(`/api/departments`);
    return response.data;
}
//#endregion

//#region Get Employee Department
export type GetEmployeeDepartmentsRequestBody = {
    id: number
}

export async function getEmployeeDepartment(requestBody: GetEmployeeDepartmentsRequestBody): Promise<number> {
    const response = await get(`/api/employee-department?id=${requestBody.id}`);
    return response.data;
}
//#endregion