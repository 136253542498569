import { ProjectStages } from "./Stages";

export type Project = {
    id: number;
    fluidraProjectId: string | null;
    name: string | null;
    description: string | null;
    active: boolean;
    departments: number[];
    stages: ProjectStages;
    type: number;
    capitalisationGroup: number | null;
    hours: number | null;
    notes: string | null;
};

export const emptyProject: Project = {
    id: 0,
    fluidraProjectId: "",
    name: "",
    description: "",
    active: true,
    departments: [],
    stages: [],
    type: 0,
    capitalisationGroup: null,
    hours: null,
    notes: null
}

export type ProjectCollection = Record<number, Project>;
