import React from 'react';
import styled from 'styled-components';

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { EntryHeading } from '../atoms/EntryHeading'
import { DropdownItems } from '../../shared/types/DropdownItems';
import { COLORS, FLUIDRA_COLORS } from '../../values/colors'
import '../../styles/loading.scss'

export const DropdownTheme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: FLUIDRA_COLORS.darkblue_100
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: FLUIDRA_COLORS.darkblue_500,
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: FLUIDRA_COLORS.teal_500,
                    },
                    padding: '0px 16px',
                    zIndex: 1,
                    fontSize: '12px',
                    height: '40px',
                    width: '320px',
                    borderRadius: '20px',
                    color: FLUIDRA_COLORS.darkblue_500,
                },
                input: {
                    padding: '0px'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    height: '40px'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    // opacity: '0.6',
                    // top: '8.5px !important',
                    // left: '14px !important',
                    padding: '0px 16px',
                    position: 'absolute',
                    alignContent: 'center',
                    transform: 'none !important',
                    zIndex: 0,
                    fontSize: '12px',
                    height: '40px',
                    color: FLUIDRA_COLORS.darkblue_100,
                    [`&.Mui-focused`]: {
                        color: COLORS.secondaryBlue
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '10pt'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    height: '60px'
                }
            }
        }
    }
});

interface DropdownEntryProps {
    showHeading: boolean,
    headingText?: string,
    required: boolean,
    placeholder: string,
    options: DropdownItems,
    value: number | null,    
    setValue: (value: number) => void
}

export const DropdownEntry: React.FC<DropdownEntryProps> = ({ showHeading, headingText, required=false, placeholder, options, value, setValue }) => {       
    return (
        <ThemeProvider theme={DropdownTheme}>
            <Column>
                <EntryHeading showHeading={showHeading} headingText={headingText} />
                <FormControl style={{'position': 'relative'}}>
                    <InputLabel style={{'display': value !== null ? 'none' : 'block'}}>
                        {placeholder}
                    </InputLabel>
                    <Select
                        required={required}
                        value={value !== null ? value : ""}
                        onChange={e => setValue(e.target.value as number)}>
                        {Object.entries(options).map(([id, option]) => (
                            <MenuItem key={id} value={id}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Column>
        </ThemeProvider>
    )
}

const Column = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`;