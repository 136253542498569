export const COLORS = {
    // Fluidra Colors
    primary: "#001a70",
    primary2: "#808cb7",
    primary3: "#bfc6db",
    primary4: "#dfe2ed",
    secondaryBlue: "#2bb4d3",
    secondaryBlue2: "#95dae9",
    secondaryBlue3: "#caecf4",
    secondaryBlue4: "#e5f6f9",
    secondaryYellow: "#ffd000",
    secondaryYellow2: "#ffe880",
    secondaryYellow3: "#fff3bf",
    secondaryYellow4: "#fff9df",
    secondaryGray: "#c6d2ef",
    secondaryGray2: "#e3e8f7",
    secondaryGray3: "#f1f4fb",
    secondaryGray4: "#f8f9fd",
    secondaryGreen: "#15834A",
    secondaryGreen2: "#51AC72",
    secondaryGreen3: "#8DD59A",
    secondaryGreen4: "#C8FFC2",
    red: "#F8333C",
    red2: "#FA757C",
    red3: "#FCB0B4",
    red4: "#FEEBEC"
}

export const FLUIDRA_COLORS = {
    // Primary Blue
    darkblue_50: "#e8e7ee",
    darkblue_100: "#b7b6ca",
    darkblue_200: "#9492b0",
    darkblue_300: "#63608c",
    darkblue_400: "#454176",
    darkblue_500: "#161254",
    darkblue_600: "#14104c",
    darkblue_700: "#100d3c",
    darkblue_800: "#0c0a2e",
    darkblue_900: "#090823",

    // Secondary Teal
    teal_50: "#e7f6f9",
    teal_100: "#b3e4ed",
    teal_200: "#8ed7e5",
    teal_300: "#5bc5d9",
    teal_400: "#3bbad1",
    teal_500: "#0aa9c6",
    teal_600: "#099ab4",
    teal_700: "#07788d",
    teal_800: "#065d6d",
    teal_900: "#033753",
}









