import { get, post } from "../adapters/apiAdapter";
import { Logs, LogItem, ProjectLogs } from "../shared/types/Logs";
import { SQLDate } from "../shared/types/SQLDate";

//#region Get Logs
export type GetLogsRequestBody = {
    id: number,
    startDate: SQLDate,
    endDate: SQLDate
}

export const getLogs = async (requestBody: GetLogsRequestBody): Promise<Logs> => {
    const response = await get(`/api/logs?id=${requestBody.id}&startDate=${requestBody.startDate}&endDate=${requestBody.endDate}`);
    return response.data;
}
//#endregion

//#region Post Log
export type PostLogsRequestBody = {
    id: number,
    date: SQLDate,
    logs: LogItem[]
}

export const postLog = async (requestBody: PostLogsRequestBody) => {
    const response = await post(`/api/logs`, requestBody);
    return response;
}
//#endregion

//#region Get Project Logs
export type GetProjectLogsRequestBody = {
    id: number
}

export const getProjectLogs = async (requestBody: GetProjectLogsRequestBody): Promise<ProjectLogs> => {
    const response = await get(`/api/logs/project?id=${requestBody.id}`)
    return response.data
}

//#endregion