import React, { useState, useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import 'react-dropdown/style.css';
import '../../../styles/dropdown.scss';

import { Card, Title, Heading } from '../../common/AdminWindowStyles';
import { LargeSwitch } from '../../common/CustomSwitch';
import { DeleteButton, CancelButton } from '../../common/Buttons';
import { postAddEmployee, postDeleteEmployee, postEditEmployee, PostAddEmployeeRequestBody, PostDeleteEmployeeRequestBody, PostEditEmployeeRequestBody } from '../../../api/employees';
import { AdminSubmitButton } from '../../common/FormInputs';
import { delay } from '../../../shared/functions/functions';

import { EmployeeCollection } from '../../../shared/types/Employee';
import { DropdownItems, yesNoDropdownItems } from '../../../shared/types/DropdownItems';

import { EmployeeActionType, initialState, employeeReducer } from './employee.reducer';
import { GLOBALS } from '../../../values/globals';
import { TextEntry } from '../../molecules/TextEntry';
import { DropdownEntry } from '../../molecules/DropdownEntry';

const Titles = {
    add: "Add New Employee",
    edit: "Edit Employee"
}

interface EmployeeWindowProps {
    allEmployees: EmployeeCollection,
    departments: DropdownItems,
    managers: DropdownItems
    unsavedChanges: boolean,
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>,
    refreshPage: () => void,
}

const EmployeeWindow: React.FC<EmployeeWindowProps> = ({ allEmployees, departments, managers, unsavedChanges, setUnsavedChanges, refreshPage }) => {
    const { urlId } = useParams();
    const [ state, dispatch ] = useReducer(employeeReducer, initialState)
    const [ title, setTitle ] = useState(Titles.add);
    const [ loading, setLoading ] = useState<boolean>(false)

    const navigate = useNavigate();

    useEffect(() => {
        setUnsavedChanges(state.unsavedChanges)
    }, [state.unsavedChanges, setUnsavedChanges])

    const handleEmployeePropertyChanged = (property: string, value: any) => {
        dispatch({
            type: EmployeeActionType.property_changed,
            [property]: value,
        });
    }

    // #region Load Employee
    useEffect(() => {
        if (Object.values(allEmployees).length > 0) loadEmployee() 
    }, [urlId, allEmployees, managers]);

    const loadEmployee = () => {
        if (urlId) {
            const employee = Object.values(allEmployees).find(employee => employee.id.toString() === urlId);
            if (employee) {
                setTitle(Titles.edit);
                dispatch({
                    type: EmployeeActionType.load_employee,
                    employee: employee
                })
            }
        } else {
            setTitle(Titles.add);
            dispatch({
                type: EmployeeActionType.clear_employee
            })
        }
    }
    // #endregion

    //#region Button Handlers
    const addEmployee = async () => {
        const managerId = state.employee.managerId == null ? 0 : state.employee.managerId;

        const requestBody: PostAddEmployeeRequestBody = {
            fluidraEmployeeId: state.employee.fluidraEmployeeId,
            firstName: state.employee.firstName,
            lastName: state.employee.lastName,
            email: state.employee.email,
            isAdmin: state.employee.isAdmin,
            isManager: state.employee.isManager,
            departmentId: state.employee.department!,
            active: state.employee.active,
            managerId: managerId,
        }
        const response = await postAddEmployee(requestBody)
        if (response.name === 'AxiosError') {
            alert("An error occurred - No changes were made")
        }
    }

    const editEmployee = async () => {
        const managerId = state.employee.managerId == null ? 0 : state.employee.managerId;

        const requestBody: PostEditEmployeeRequestBody = {
            id: state.employee.id,
            fluidraEmployeeId: state.employee.fluidraEmployeeId,
            firstName: state.employee.firstName,
            lastName: state.employee.lastName,
            email: state.employee.email,
            isAdmin: state.employee.isAdmin,
            isManager: state.employee.isManager,
            departmentId: state.employee.department!,
            active: state.employee.active,
            managerId: managerId,
        }
        const response = await postEditEmployee(requestBody);
        if (response.name === 'AxiosError') {
            alert("An error occurred - No changes were made")
        }
    }

    const saveEmployee = async (event: any) => {
        event.preventDefault();
        console.log('Saving employee');
        
        setLoading(true)

        // Add a minimum loading time for user feedback
        await Promise.all([
            urlId ? editEmployee() : addEmployee(),
            delay(GLOBALS.buttonLoadTime)
        ]);

        setLoading(false)

        refreshPage()
    }

    const deleteEmployee = async (event: any) => {
        event.preventDefault()

        const prompt = `If an employee is no longer employed, then they should be made inactive, not deleted.\n\nOnly delete an employee if they don't exist or were created on accident.\n\nAre you sure you want to delete this employee?`
        const answer = window.confirm(prompt)
        if (answer) {
            console.log('Deleting employee');

            const requestBody: PostDeleteEmployeeRequestBody = {
                id: state.employee.id
            }

            const response = await postDeleteEmployee(requestBody)

            navigate(`/admin/employees`)
            refreshPage();
        }
    }

    const cancel = (event: any) => {
        event.preventDefault();
        console.log('Cancelling');
        loadEmployee();
    }
    //#endregion

    return (
        <Card>
            <Form onSubmit={saveEmployee} style={{justifyContent: 'space-between'}}>
                <Column>
                    <Row style={{justifyContent: 'space-between', marginBottom: '16px'}}>
                        {/* Title */}
                        <Title>{title}</Title>

                        {/* Active Switch*/}
                        <LargeSwitch
                            id="active-switch"
                            checked={state.employee.active}
                            onChange={(value: any) => handleEmployeePropertyChanged('active', value)}
                            label={state.employee.active ? "Active" : "Inactive"} />
                    </Row>

                    {/* Details */}
                    <Row style={{columnGap: '20px'}}>
                        <Column style={{rowGap: '8px'}}>
                            <Heading style={{margin: '0px'}}>Details</Heading>
                            <TextEntry
                                showHeading
                                headingText="Fluidra Employee Id"
                                label="Fluidra Employee Id"
                                value={state.employee.fluidraEmployeeId}
                                setValue={(value: any) => handleEmployeePropertyChanged('fluidraEmployeeId', value)}
                                errorMessage='' />

                            <TextEntry
                                showHeading
                                headingText="First Name"
                                label="First Name"
                                value={state.employee.firstName}
                                setValue={(value: any) => handleEmployeePropertyChanged('firstName', value)}
                                errorMessage='' />

                            <TextEntry
                                showHeading
                                headingText="Last Name"
                                label="Last Name"
                                value={state.employee.lastName}
                                setValue={(value: any) => handleEmployeePropertyChanged('lastName', value)}
                                errorMessage='' />

                            <TextEntry
                                showHeading
                                headingText="Email"
                                label="Email"
                                value={state.employee.email}
                                setValue={(value: any) => handleEmployeePropertyChanged('email', value)}
                                errorMessage='' />
                        </Column>
                        <Column style={{rowGap: '8px'}}>
                            <div style={{height: '29px'}} />
                            <DropdownEntry
                                required
                                showHeading
                                headingText="What department is this employee in?"
                                placeholder="Choose deparment"
                                options={departments}
                                value={state.employee.department !== 0 ? state.employee.department : null}
                                setValue={(value: any) => handleEmployeePropertyChanged('department', Number(value))} />

                            <DropdownEntry
                                required
                                showHeading
                                headingText="Is this employee an admin?"
                                placeholder="Choose an option"
                                options={yesNoDropdownItems}
                                value={state.employee.isAdmin ? 1 : 0}
                                setValue={(value: any) => handleEmployeePropertyChanged('isAdmin', Number(value) === 1 ? true : false)} />

                            <DropdownEntry
                                required
                                showHeading
                                headingText="Is this employee a manager?"
                                placeholder="Choose an option"
                                options={yesNoDropdownItems}
                                value={state.employee.isManager ? 1 : 0}
                                setValue={(value: any) => handleEmployeePropertyChanged('isManager', Number(value) === 1 ? true : false)} />
                            
                            <DropdownEntry
                                required
                                showHeading
                                headingText="Who is this employee's manager?"
                                placeholder="Choose manager"
                                options={managers}
                                value={state.employee.managerId == null ? 0 : state.employee.managerId}
                                setValue={(value: any) => handleEmployeePropertyChanged('managerId', Number(value))} />
                        </Column>
                    </Row>
                </Column>

                {/* Save and Cancel Buttons */}
                <Row style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                    <DeleteButton onClick={deleteEmployee} />
                    <Row style={{alignItems: 'flex-end'}}>
                        <CancelButton onClick={cancel}/>
                        <AdminSubmitButton label="SAVE" loading={loading} />
                    </Row>
                </Row>
            </Form>
        </Card>
    );
}

//#region Styles
const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;
//#endregion

export default EmployeeWindow;
