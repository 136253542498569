import React from "react";
import styled from 'styled-components';

import { COLORS } from '../../values/colors';

interface OptionBarProps {
    options: string[],
    selectedOption: string,
    onOptionSelected: (option: any) => void
}

const OptionBar: React.FC<OptionBarProps> = ( {options, selectedOption, onOptionSelected} ) => {

    const handleButtonClick = (button: any) => {
        const option = button.target.id;
        onOptionSelected(option);
      };

    const selectedIndex = options.indexOf(selectedOption);

    return (
        <Frame>
            {options.map((option, index) => (
                <Option key={index}>
                    <Button 
                        id={option} 
                        onClick={handleButtonClick} 
                        $isSelected={selectedIndex === index}
                    >
                        {option}
                    </Button>   
                    {index < options.length - 1 ? (
                        <Separator />
                    ) : <></>}

                </Option>                 
                
            ))}
        </Frame>
    );
}

const Frame = styled.div`
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    margin: 10px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    width: fit-content;
`;

const Button = styled.button<{$isSelected: boolean}>`
    width: 90px;
    height: 30px;
    border: none;
    border-radius: 20px;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
    background-color: ${props => (props.$isSelected ? COLORS.secondaryGray2 : "transparent")};
    &:hover {
        background-color: ${props => (props.$isSelected ? COLORS.secondaryGray : COLORS.secondaryGray3)};
    }
`;

const Separator = styled.div`
    background: ${COLORS.secondaryYellow};
    width: 2px;
    border: none; 
    margin: 5px 0px;
`;

const Option = styled.div`
    display: flex;
    user-select: none;
`;

export default OptionBar;