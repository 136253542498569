import React from "react";
import styled from 'styled-components';

import '../App.css';
import { Page, Card, Title } from "../components/common/CommonStyles";


const UnregisteredUserPage = () => {
    return (
        <Page>
            <UnregisteredUserCard>
                <Title>Unregistered User</Title>
                <span>This user is not registered with Fluidra Timesheets.</span>
                <span>Contact your manager or administrator to register.</span>
            </UnregisteredUserCard>
        </Page>      
    )
}

//#region Styles
const UnregisteredUserCard = styled(Card)`
    width: 400px;
    height: min-content;
    margin-top: 100px;
    padding: 40px;
`;
//#endregion

export default UnregisteredUserPage;