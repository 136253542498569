import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { formatDateForSQL } from '../../../shared/functions/dateAndTime';
import Day from '../../calendar/Day'
import { DateCellWrapperProps } from "react-big-calendar";
import { ProjectCollection } from "../../../shared/types/Project";
import { Logs } from "../../../shared/types/Logs";

interface CalendarDayProps {
    props: DateCellWrapperProps,
    logs: Logs,
    calendarMonth: number,
    allProjects: ProjectCollection
}

const CalendarDay: React.FC<CalendarDayProps> = ({props, logs, calendarMonth, allProjects}) => {
    const navigate = useNavigate();
    const date = new Date(props.value);
    const log = logs[formatDateForSQL(date)] || [];

    const inActiveMonth: boolean = (calendarMonth === date.getMonth());

    return (
        <DayButton 
            onClick={() => {
                navigate(`/logging/${formatDateForSQL(date)}`)
            }}
            $inActiveMonth={inActiveMonth}
        >
            <Day log={log} date={date} allProjects={allProjects} />
        </DayButton>
    );
}

//#region Styles
const DayButton = styled.button<{$inActiveMonth: boolean}>`
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    margin: 5px;
    padding: 0px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    opacity: ${props => props.$inActiveMonth ? '1' : '0.4'};
`;
//#endregion

export default CalendarDay;