import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

interface ColumnProps {
    style?: {};
    gap?: number;
    children: ReactNode;
}

const Column: FunctionComponent<ColumnProps> = ({ style={}, gap=0, children }) => (
    <ColumnStyle $gap={gap} style={style}>
        {children}
    </ColumnStyle>
)

const ColumnStyle = styled.div<{$gap: number}>`
    display: flex;
    flex-direction: column;
    row-gap: ${props => props.$gap}px;
`;

export default Column