import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Droppable } from '@hello-pangea/dnd';

import { longFormatDate, dayOfWeek } from '../../../shared/functions/dateAndTime';
import { RadioButton } from '../../common/Buttons';
import DraggableProject from './DraggableProject';
import NotesButton from './NotesButton';
import TimeSlider from './TimeSlider';
import { COLORS, FLUIDRA_COLORS } from '../../../values/colors';
import { Project } from '../../../shared/types/Project';
import { State } from './logging.reducer';
import '../../../styles/slider.scss'
import '../../../styles/loading.scss'
import '../../../styles/scrollbar.scss'

interface LoggingColumnProps {
    date: Date,
    state: State,
    updateHours: (id: number, hours: number) => void,
    updateNotes: (id: number, notes: string) => void,
    hoursLogged: number,
    logClicked: () => void,
    updateNoLog: () => void, 
    updateLeave: () => void, 
    updatePublicHoliday: () => void,
    loading: boolean
}

const LoggingColumn: React.FC<LoggingColumnProps> = ({date, state, updateHours, updateNotes, hoursLogged, logClicked, updateNoLog, updateLeave, updatePublicHoliday, loading}) => {      
    const [ noLog, setNoLog ] = useState(false)
    const [ leave, setLeave ] = useState(false)
    const [ publicHoliday, setPublicHoliday ] = useState(false)
    const [ projectsToDisplay, setProjectsToDisplay ] = useState<Project[]>([])


    const handleSliderChange = ([id, hours]: [number, number]) => {
        updateHours(id, hours);
    }

    const handleNotesUpdate = (id: number, notes: string) => {
        updateNotes(id, notes)
    }
    
    useEffect(() => {
        const column = state.columns.logging_column;
        const projects = column.projectIds.map(id => state.projects[id]);

        // Do not show if the project is 'No log' or 'Public Holiday'
        const updatedProjectsToDisplay = projects.filter(project => project.id !== 0 && project.id !== -2)
        setProjectsToDisplay(updatedProjectsToDisplay);  

        // Check if no log is selected
        const updatedNoLog = projects.some(project => project.id === 0);
        setNoLog(updatedNoLog)
        
        // Check if leave is selected
        const updatedLeave = projects.some(project => project.id === -1); 
        setLeave(updatedLeave)

        // Check if public holiday is selected
        const updatedPublicHoliday = projects.some(project => project.id === -2);
        setPublicHoliday(updatedPublicHoliday)
    }, [state, date])


    return (
        <LoggingWindowCard className='scrollableList' >
            <DayDate>
                <Day>{dayOfWeek(date)}</Day>
                <Date>{longFormatDate(date)}</Date>
            </DayDate>
            <Droppable droppableId={state.columns.logging_column.id.toString()}>
                {(provided, snapshot) => (
                    <ProjectList
                        ref={provided.innerRef} 
                        {...provided.droppableProps}
                        $isDraggingOver={snapshot.isDraggingOver}
                        $isLocked={noLog || publicHoliday}
                    >
                        {projectsToDisplay.map((project, index) => (
                            <LoggedProject key={project.id}>
                                <Column1>
                                    <DraggableProject key={project.id} project={project} index={index} isDragDisabled={false} />
                                </Column1>
                                <Column2>
                                    <TimeSlider key={project.id} id={project.id} onSliderChange={handleSliderChange} startingPosition={project.hours!*2}/>
                                </Column2>
                                <Column3>
                                    <ProjectHoursValue>{project.hours!}</ProjectHoursValue>
                                    <ProjectHoursLabel>{(project.hours! === 1 ? ' hour' : ' hours')}</ProjectHoursLabel>
                                </Column3>
                                <Column4>
                                    <NotesButton 
                                        id={project.id} 
                                        notes={project.notes} 
                                        onNotesChange={handleNotesUpdate}
                                        projectId={project.fluidraProjectId}
                                        projectName={project.name} />
                                </Column4>
                            </LoggedProject>
                        ))}
                        {projectsToDisplay.length > 0 && (
                            <UnderProjects>
                            <Column1 />
                            <Column2>
                                <span style={{'fontWeight': 'bold', 'fontSize': '10pt', 'marginRight': '27px'}}>Hours</span>
                                <HourMarkerLabel>2</HourMarkerLabel>
                                <HourMarkerLabel>4</HourMarkerLabel>
                                <HourMarkerLabel>6</HourMarkerLabel>
                                <HourMarkerLabel>8</HourMarkerLabel>
                                <HourMarkerLabel>10</HourMarkerLabel>
                            </Column2>
                            </UnderProjects>
                        )}
                        <DroppableArea
                            ref={provided.innerRef} 
                            {...provided.droppableProps}
                            // $isDraggingOver={snapshot.isDraggingOver}
                            // $isLocked={noLog}
                        >
                            {provided.placeholder}
                            <Instructions $isVisible={noLog || publicHoliday}>Drag and drop a project to add it to the day's log</Instructions>
                        </DroppableArea>
                    </ProjectList>
                )}
            </Droppable>
            <TotalHoursRow>
                <TotalHoursText>Total time logged:</TotalHoursText>
                <TotalHoursValue>{hoursLogged}</TotalHoursValue>
                <TotalHoursLabel>{hoursLogged === 1 ? 'hour' : 'hours'}</TotalHoursLabel>
            </TotalHoursRow>
            <Row>
                <RadioButton onClick={updateNoLog} isSelected={noLog} buttonText="Nothing to log" />
                <RadioButton onClick={updateLeave} isSelected={leave} buttonText="On Leave" />
                <RadioButton onClick={updatePublicHoliday} isSelected={publicHoliday} buttonText="Public Holiday" />
            </Row>
            {loading 
            ? <LogButtonLoading><div className="loading-icon" style={{marginTop: '8px'}}><div></div><div></div><div></div><div></div></div></LogButtonLoading>
            : <LogButton onClick={logClicked}>LOG</LogButton>
            }
        </LoggingWindowCard>
    );
}


//#region Styles
const LoggingWindowCard = styled.div`
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    margin: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    min-width: 618px;
    max-width: 800px;
`;

const DayDate = styled.div`
    text-align: left;
    margin-bottom: 30px;
`;

const Day = styled.span`
    font-size: 32pt;
    font-weight: bold;
    margin-right: 20px;
`;

const Date = styled.span`
    font-size: 18pt;
`;

const ProjectList = styled.div<{$isDraggingOver: boolean, $isLocked: boolean}>`
    flex-grow: 1;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 479px);

    border: 4px dashed ${COLORS.secondaryGray};
    border-radius: 20px;
    padding: 8px;
    margin-bottom: 20px;
    background-color: ${props => (props.$isDraggingOver ? COLORS.secondaryGray4 : 'white')};

    background-image: ${props => (props.$isLocked ? `linear-gradient(135deg, transparent 40%, ${COLORS.secondaryGray4} 40%, ${COLORS.secondaryGray4} 50%, transparent 50%, transparent 90%, ${COLORS.secondaryGray4} 90%, ${COLORS.secondaryGray4})` : 'none')};
    background-size: 20px 20px;
    overflow-y: auto;
`;

const LoggedProject = styled.div`
    display: flex;
    align-items: center;
`;

const UnderProjects = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const Column1 = styled.div`
    float: left;
    width: 270px;
`;

const Column2 = styled.div`
    float: left;
`;

const Column3 = styled.div`
    float: right;
    width: 80px;
    margin: 0px 0px 8px 0px;
    display: grid;
    grid-template-columns: 20px 1fr;
`;

const Column4 = styled.div`
    width: 56px;
`

const ProjectHoursValue = styled.span`
    text-align: end;
`;

const ProjectHoursLabel = styled.span`
    padding-left: 5px;
`;

const HourMarkerLabel = styled.span`
    margin-right: 43px;
    font-size: 12px;
`;

const DroppableArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`;

const Instructions = styled.span<{$isVisible: boolean}>`
    font-size: 24px;
    color: ${COLORS.secondaryGray};
    visibility: ${props => (props.$isVisible ? 'hidden': 'visible')};
    user-select: none;
`;

const TotalHoursRow = styled.div`
    display: grid;
    grid-template-columns: auto 50px 60px;
    margin: 0px auto;
`;

const TotalHoursText = styled.span`
    font-size: 16pt;
    font-weight: bold;
    user-select: none;
`;

const TotalHoursValue = styled.span`
    font-size: 16pt;
    text-align: end;
    user-select: none;
`;

const TotalHoursLabel = styled.span`
    font-size: 16pt;
    padding-left: 5px;
    user-select: none;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
`;

const LogButton = styled.button`
    color: white;
    background-color: ${COLORS.secondaryBlue};
    border: 0px solid black;
    border-radius: 20px;
    height: 56px;
    width: 100%;
    margin-top: 20px;
    font-size: 24pt;
    font-weight: bold;
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
    user-select: none;
    cursor: pointer;
`;

const LogButtonLoading = styled.div`
    border-radius: 20px;
    height: 56px;
    width: 100%;
    margin-top: 20px;
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
    background-color: ${COLORS.secondaryBlue};
    color: white;
    border: '0px';
    user-select: none;
    cursor: pointer;

    text-align: center;
`;
//#endregion

export default LoggingColumn;