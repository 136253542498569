import React from 'react';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import { BaseSingleInputFieldProps, DateValidationError, FieldSection } from '@mui/x-date-pickers/models';
import { COLORS } from '../../values/colors';

const DateButton = styled.button`
    border-radius: 20px;
    border: none;
    height: 25px;
    width: 120px;
    color: white;
    background-color: ${COLORS.secondaryBlue};
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;

interface ButtonFieldProps
    extends UseDateFieldProps<Dayjs>,
        BaseSingleInputFieldProps<
            Dayjs | null,
            Dayjs,
            FieldSection,
            DateValidationError
        > {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
    const {
        setOpen,
        label,
        id,
        disabled,
        InputProps: { ref } = {},
    } = props;

    const dateLabel = (label === "Invalid Date") ? "Not Set" : label
    return (

        <DateButton
            id={id}
            type='button'
            disabled={disabled}
            ref={ref}
            onClick={() => setOpen?.((prev) => !prev)}
        >
            {dateLabel}
        </DateButton>
    );
}

function ButtonDatePicker(props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>) {
    const [open, setOpen] = React.useState(false);

    return (
        <DatePicker
            slots={{ field: ButtonField, ...props.slots }}
            slotProps={{ field: { setOpen } as any }}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
    );
}

interface DatePickerButtonProps {
    date: Date | null;
    setDate: (date: Date) => void;
}

export const DatePickerButton: React.FC<DatePickerButtonProps> = ({ date, setDate }) => {

    const handleSetDate = (newDate: Dayjs | null) => {
        if (newDate) {
            setDate(newDate.toDate())
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ButtonDatePicker
                label={dayjs(date).format('DD/MM/YYYY')}
                value={dayjs(date)}
                onChange={(newDate: Dayjs | null) => handleSetDate(newDate)}
            />
        </LocalizationProvider>
    );
}