import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ItemList from '../components/common/ItemList';
import ProjectWindow from '../components/pages/AdminProjectsPageComponents/ProjectWindow';
import SearchBar from '../components/common/SearchBar';
import ConfirmNavigationPopup from '../components/common/ConfirmNavigationPopup';

import { getAllProjects, getStages, getTypes } from '../api/projects';
import { getDepartments } from '../api/departments';
import { getCapitalisationGroups } from '../api/capitalisationGroups';
import { ProjectListActionType, initialProjectListState, projectListReducer } from '../components/pages/AdminProjectsPageComponents/projectList.reducer'
import { CapitalisationGroupCollection, emptyCapitalisationGroup, CapitalisationGroup } from '../shared/types/CapitalisationGroup';
import { DropdownItems, emptyDrowdownItems } from '../shared/types/DropdownItems';
import { ProjectCollection } from '../shared/types/Project';
import { Departments } from '../shared/types/Department';
import { ProjectTypes } from '../shared/types/ProjectType';
import { convertCapitalisationGroupsToDropdownItems } from '../shared/functions/capitalisationGroups';
import { convertProjectTypesToDropdownItems } from '../shared/functions/types';
interface ProjectsPageProps {
    unsavedChanges: boolean,
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
}

const ProjectsPage: React.FC<ProjectsPageProps> = ({ unsavedChanges, setUnsavedChanges }) => {
    const [ allProjects, setAllProjects ] = useState<ProjectCollection>({});
    const [ stages, setStages ] = useState<{[key: number]: string}>([]);
    const [ departments, setDepartments ] = useState<DropdownItems>({});
    const [ projectTypes, setProjectTypes ] = useState<DropdownItems>({});
    const [ capitalisationGroups, setCapitalisationGroups ] = useState<DropdownItems>(emptyDrowdownItems);
    const [ capitalisableTypes, setCapitalisableTypes ] = useState<number[]>([])
    const [ textFilter, setTextFilter ] = useState<string>('')

    const [ state, dispatch ] = useReducer(projectListReducer, initialProjectListState);

    const [ triggerEffect, setTriggerEffect ] = useState<boolean>(false);

    const location = useLocation();
    const navigate = useNavigate();  

    // Runs on first load
    useEffect(() => {
        return () => setUnsavedChanges(false)
    }, [])
    
    useEffect(() => {
        getAllProjectsAsync();
        getDepartmentsAsync();
        getStagesAsync();
        getTypesAsync();
        getCapitalisationGroupsAsync();
    }, [triggerEffect]);

    useEffect(() => {
        dispatch({
            type: ProjectListActionType.populate_list,
            allProjects: allProjects
        })
    }, [allProjects])

    useEffect(() => {
        dispatch({
            type: ProjectListActionType.filter,
            textFilter: textFilter
        })
    }, [textFilter])

    //#region API Callers
    const getAllProjectsAsync = async () => setAllProjects(await getAllProjects());
    const getDepartmentsAsync = async () => setDepartments(await getDepartments());
    const getStagesAsync = async () => setStages(await getStages());
    
    const getTypesAsync = async () => {
        const projectTypes: ProjectTypes = await getTypes();
        const capitalisableTypes: number[] = []
        Object.values(projectTypes).forEach(projectType => {
            if (projectType.capitalisable) capitalisableTypes.push(projectType.id) 
        })
        setProjectTypes(convertProjectTypesToDropdownItems(projectTypes));
        setCapitalisableTypes(capitalisableTypes)
    }

    const getCapitalisationGroupsAsync = async () => {
        const capitalisationGroups: CapitalisationGroupCollection = await getCapitalisationGroups();
        setCapitalisationGroups(convertCapitalisationGroupsToDropdownItems(capitalisationGroups));
    }
    //#endregion

    const handleRefreshPage = () => {
        setTextFilter('');
        setTriggerEffect(prevState => !prevState);
    }

    // #region Button Handlers
    const projectClicked = (projectId: number) => ConfirmNavigationPopup(`/admin/projects/${projectId}`, unsavedChanges, navigate);
    const addNewProjectClicked = () => ConfirmNavigationPopup(`/admin/projects`, unsavedChanges, navigate);
    // #endregion

    return (
        <Page>
            <Row>
                <Column>
                    <SearchBar 
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    <ItemList 
                        itemType='Project'
                        items={state.filteredProjects}
                        itemClicked={projectClicked}
                        selectedItem={location.pathname.replace('/admin/projects/', '')}
                        heightAdjustment={398}
                        hasBottomButton={true}
                        bottomButtonClicked={addNewProjectClicked} />
                </Column>
                <ProjectWindow 
                    allProjects={allProjects}
                    departments={departments}
                    stages={stages}
                    types={projectTypes}
                    capitalisationGroups={capitalisationGroups}
                    capitalisableTypes={capitalisableTypes}
                    unsavedChanges={unsavedChanges}
                    setUnsavedChanges={setUnsavedChanges}
                    refreshPage={handleRefreshPage} />
            </Row>
        </Page>
    );
}

//#region Styles
const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 200px);
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0px 10px;
    height: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
//#endregion

export default ProjectsPage;