import React, { useState, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ItemList from '../components/common/ItemList';
import { getAllEmployees, getManagerEmployees } from '../api/employees';
import { getAllProjects } from '../api/projects';
import { Card } from '../components/common/CommonStyles';
import SearchBar from '../components/common/SearchBar';
import EmployeeCalendar from '../components/pages/AdminViewLogsPageComponents/EmployeeCalendar';

import { useAuth } from '../authprovider'
import { 
    EmployeeListActionType,
    employeeListReducer, 
    initialEmployeeListState 
} from '../components/pages/AdminEmployeesPageComponents/employeeList.reducer';
import { EmployeeCollection } from '../shared/types/Employee';
import { ProjectCollection } from '../shared/types/Project';

const ViewLogsPage = () => {
    const [ allEmployees, setAllEmployees ] = useState<EmployeeCollection>({});
    const [ allProjects, setAllProjects ] = useState<ProjectCollection>({});
    const [ selectedEmployee, setSelectedEmployee ] = useState<number>(useAuth().user);

    const [ textFilter, setTextFilter] = useState<string>('')

    const [ state, dispatch ] = useReducer(employeeListReducer, initialEmployeeListState)

    const auth = useAuth();
    const navigate = useNavigate();  
    const location = useLocation();

    // Runs on first load
    useEffect(() => {      
        if (auth.isAdmin === 1) {
            getAllEmployeesAsync();
        } else if (auth.isManager === 1) {
            const managerId = Number(auth.user);
            getManagerEmployeesAsync(managerId);
        }
                
        getAllProjectsAsync();
    }, []);

    useEffect(() => {
        dispatch({
            type: EmployeeListActionType.populate_list,
            allEmployees: allEmployees
        })
    }, [allEmployees])

    useEffect(() => {
        dispatch({
            type: EmployeeListActionType.filter,
            textFilter: textFilter
        })
    }, [textFilter])

    const getAllEmployeesAsync = async () => setAllEmployees(await getAllEmployees());
    const getManagerEmployeesAsync = async (managerId: number) => setAllEmployees(await getManagerEmployees({id: managerId}));
    const getAllProjectsAsync = async () => setAllProjects(await getAllProjects());

    const employeeClicked = (employeeId: number) => {
        navigate(`/admin/view-logs/${employeeId}`);
        setSelectedEmployee(employeeId);
    }

    return (
        <Page>
            <Row>
                <Column>
                    <SearchBar
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    <ItemList
                        itemType='Employee'
                        items={state.filteredEmployees}
                        itemClicked={employeeClicked}
                        selectedItem={location.pathname.replace('/admin/view-logs/', '')}
                        heightAdjustment={401}
                        hasBottomButton={false}
                        bottomButtonClicked={()=>{}} />
                </Column>
                <CalenderCard>
                    <EmployeeCalendar employeeId={selectedEmployee} allProjects={allProjects} />
                </CalenderCard>
            </Row>
        </Page>
    );
}

//#region Styles
const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 200px);
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0px 10px;
    height: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const CalenderCard = styled(Card)`
    width: 100%;
`;
//#endregion

export default ViewLogsPage;