import React, { useState, useEffect } from "react";
import { styled } from 'styled-components';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';

import SignInPage from "./pages/SignInPage";
import LoggingPage from "./pages/LoggingPage";
import CalendarPage from "./pages/CalendarPage";
import SummaryPage from "./pages/AdminSummaryPage";
import EditFavouriteProjectsPage from "./pages/EditFavouriteProjectsPage";
import FeedbackPage from "./pages/FeedbackPage";
import RequestProjectPage from "./pages/RequestProjectPage";
import ReminderPreferencesPage from "./pages/ReminderPreferencesPage";
import AdminPage from "./pages/AdminPage";
import AdminProjectsPage from "./pages/AdminProjectsPage";
import AdminEmployeesPage from "./pages/AdminEmployeesPage";
import AdminCapitalisationGroupsPage from "./pages/AdminCapitalisationGroupsPage";
import AdminViewLogsPage from "./pages/AdminViewLogsPage";
import AdminReportsPage from "./pages/AdminReportsPage";
import UnregisteredUserPage from "./pages/UnregisteredUserPage";
import UnassignedUserPage from "./pages/UnassignedUserPage";

import FluidraTimesheetsButton from "./components/common/FluidraTimesheetsButton"
import ProfileMenu from "./components/common/ProfileMenu";

import { useAuth, AuthProvider } from './authprovider'
import { formatDateForSQL } from './shared/functions/dateAndTime';
import { COLORS } from "./values/colors";
import SessionExpiredPage from "./pages/SessionExpiredPage";
import ActAsUserMenu from "./components/common/ActAsUserMenu";

function RedirectIfLoggedIn() {
    let auth = useAuth();

    const date = formatDateForSQL(new Date());
    
    if (auth.isRegisteredUser === false) {
        return <Navigate to="/unregistered-user" />;
    }
    if (!auth.user) {
        return <Outlet />;
    }
    else
    {
        return <Navigate to={`/logging/${date}`} />;
    }
}

function RequireAuth() {
    let auth = useAuth();

    if (auth.isRegisteredUser === false) {
        return <Navigate to="/unregistered-user" />;
    }
    if (!auth.user) {
        return <Navigate to="/login" />;
    }
  
    return <Outlet />;
}

function RequireAdmin() {
    let auth = useAuth();

    const date = formatDateForSQL(new Date());
  
    try {
        if (!auth.user) {
            return <Navigate to="/login" />;
        }
        if (!auth.isAdmin) {
            return <Navigate to={`/logging/${date}`} />;
        }
    } catch (error) {
        console.log(error)
    }

    return <Outlet />;
}

function RequireManager() {
    let auth = useAuth();
    const date = formatDateForSQL(new Date());

    try {
        if (!auth.user) {
            return <Navigate to="/login" />;
        }
        if (!auth.isManager && !auth.isAdmin) {
            return <Navigate to={`/logging/${date}`} />;
        }
    } catch (error) {
        console.log(error)
    }

    return <Outlet />;
}

function App() {
    const [ unsavedChanges, setUnsavedChanges ] = useState(false);
    const [ actingAs, setActingAs ] = useState<number | null>(null)

    return (
        <BrowserRouter>
            <AuthProvider>
                <div className="App">
                    <TitleBar>
                        <ActAsUserMenu actingAs={actingAs} setActingAs={setActingAs} />
                        <FluidraTimesheetsButton unsavedChanges={unsavedChanges} />
                        <ProfileMenu unsavedChanges={unsavedChanges} />
                    </TitleBar>
                    <Content>
                        <Routes>
                            <Route element={<RedirectIfLoggedIn />}>
                                <Route path="/" element={<SignInPage />} />
                                <Route path="/login" element={<SignInPage />} />
                                <Route path="/sign-in" element={<SignInPage />} />
                            </Route>
                            <Route path="/session-expired" element={<SessionExpiredPage />} />
                            <Route path="/unregistered-user" element={<UnregisteredUserPage />} />
                            <Route path="/unassigned-user" element={<UnassignedUserPage />} />
                            <Route element={<RequireAuth />}>
                                <Route path="/logging/:date" element={<LoggingPage setUnsavedChanges={setUnsavedChanges} actingAs={actingAs} />} />
                                <Route path="/logging/calendar/:month/:year" element={<CalendarPage actingAs={actingAs} />} />
                                <Route path="/logging/favourite-projects/:returnLoggingDate?" element={<EditFavouriteProjectsPage setUnsavedChanges={setUnsavedChanges} actingAs={actingAs} />} />
                                <Route path="/feedback" element={<FeedbackPage />} />
                                <Route path="/request-project" element={<RequestProjectPage />} />
                                <Route path="/reminder-preferences" element={<ReminderPreferencesPage />} />
                                <Route element={<RequireManager />}>
                                    <Route path="/admin" element={<AdminPage unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />}>
                                        <Route path="view-logs/:urlId?" element={<AdminViewLogsPage />} />
                                        <Route path="summary" element={<SummaryPage />} />
                                        <Route element={<RequireAdmin />}>
                                            <Route path="reports" element={<AdminReportsPage />} />
                                            <Route path="projects/:urlId?" element={<AdminProjectsPage unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />} />
                                            <Route path="employees/:urlId?" element={<AdminEmployeesPage unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />} />
                                            <Route path="capitalisation-groups/:urlId?" element={<AdminCapitalisationGroupsPage unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />} />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Routes>
                    </Content>
                </div>
            </AuthProvider>
        </BrowserRouter>
    );
}

//#region Styles
const Content = styled.div`
    display: flex;
    justify-content: center;
    height: calc(100vh - 100px);
    background-color: ${COLORS.primary4};
`;

const TitleBar = styled.div`
    background-color: ${COLORS.primary};
    height: 100px;
    padding: 0px 40px;
    align-items: center;
    
    display: grid;
    grid-template-columns: 1fr Auto 1fr;
`;
//#endregion

export default App;
