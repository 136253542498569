import { formatDateForSQL } from "../../../shared/functions/dateAndTime";
import { Project, emptyProject } from "../../../shared/types/Project";
import { Stages, ProjectStages } from "../../../shared/types/Stages";

// #region Actions
export enum ProjectActionType {
    clear_project,
    load_project,
    create_stages,
    active_changed,
    fluidra_project_id_changed,
    name_changed,
    description_changed,
    type_changed,
    capitalisation_group_changed,
    departments_changed,
    stage_used_changed,
    stage_start_date_changed,
}

interface ClearProjectActionType {
    type: ProjectActionType.clear_project;
}

interface LoadProjectActionType {
    type: ProjectActionType.load_project;
    project: Project;
}

interface CreateStagesActionType {
    type: ProjectActionType.create_stages;
    stages: Stages;
}

interface ActiveChangedActionType {
    type: ProjectActionType.active_changed;
    active: Project['active'];
}

interface FluidraProjectIdChangedActionType {
    type: ProjectActionType.fluidra_project_id_changed;
    fluidraProjectId: Project['fluidraProjectId'];
}

interface NameChangedActionType {
    type: ProjectActionType.name_changed;
    name: Project['name'];
}

interface DescriptionChangedActionType {
    type: ProjectActionType.description_changed;
    description: Project['description'];
}

interface TypeChangedActionType {
    type: ProjectActionType.type_changed;
    projectType: Project['type'];
}

interface CapitalisationGroupChangedActionType {
    type: ProjectActionType.capitalisation_group_changed;
    capitalisationGroup: Project['capitalisationGroup'];
}

interface DepartmentsChangedActionType {
    type: ProjectActionType.departments_changed;
    departments: number[];
}

interface StageUsedChangedActionType {
    type: ProjectActionType.stage_used_changed;
    stageId: number;
    used: boolean;
}

interface StageStartDateChangedActionType {
    type: ProjectActionType.stage_start_date_changed;
    stageId: number;
    startDate: Date | null;
}

type ProjectAction = 
    ClearProjectActionType                  |
    LoadProjectActionType                   |
    CreateStagesActionType                  |
    ActiveChangedActionType                 |
    FluidraProjectIdChangedActionType       |
    NameChangedActionType                   |
    DescriptionChangedActionType            |
    TypeChangedActionType                   |
    CapitalisationGroupChangedActionType    |
    DepartmentsChangedActionType             |
    StageUsedChangedActionType              |
    StageStartDateChangedActionType;
// #endregion

export interface State {
    project: Project,
    unsavedChanges: boolean,
    originalState: string

}

export const initialState: State = {
    project: emptyProject,
    unsavedChanges: false,
    originalState: ""
}

const checkForChanges = (currentState: State): boolean => {
    return JSON.stringify(currentState.project) !== currentState.originalState
}

export function projectReducer(state: State, action: ProjectAction): State {
    switch(action.type) {
        case ProjectActionType.clear_project:
            return {
                project: emptyProject,
                unsavedChanges: false,
                originalState: JSON.stringify(emptyProject)
            }
        case ProjectActionType.load_project:
            return {
                project: action.project,
                unsavedChanges: false,
                originalState: JSON.stringify(action.project)
            }
        case ProjectActionType.create_stages:
            if (state.project.stages === null || state.project.stages.length === 0) {
                const projectStages: ProjectStages = Object.keys(action.stages).map((stageId) => ({ stageId: Number(stageId), used: false, startDate: null}));
                var updatedState = {
                    ...state,
                    project: {
                        ...state.project,
                        stages: projectStages
                    }
                }
                updatedState.unsavedChanges = false
                updatedState.originalState = JSON.stringify(updatedState.project)
                return updatedState
            } else {
                return state
            }
        case ProjectActionType.active_changed:
            updatedState = {
                ...state,
                project: {
                    ...state.project,
                    active: action.active
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        case ProjectActionType.fluidra_project_id_changed:
            updatedState = {
                ...state,
                project: {
                    ...state.project,
                    fluidraProjectId: action.fluidraProjectId
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        case ProjectActionType.name_changed:
            updatedState = {
                ...state,
                project: {
                    ...state.project,
                    name: action.name
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        case ProjectActionType.description_changed:
            updatedState = {
                ...state,
                project: {
                    ...state.project,
                    description: action.description
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        case ProjectActionType.type_changed:
            updatedState = {
                ...state,
                project: {
                    ...state.project,
                    type: action.projectType
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        case ProjectActionType.capitalisation_group_changed:
            updatedState = {
                ...state,
                project: {
                    ...state.project,
                    capitalisationGroup: action.capitalisationGroup
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        case ProjectActionType.departments_changed:
            updatedState = {
                ...state,
                project: {
                    ...state.project,
                    departments: action.departments
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        case ProjectActionType.stage_used_changed:
            // Make sure the stages array exists
            if (state.project.stages === null || state.project.stages.length === 0) {
                console.log("Stages array is not set")
                return {...state}
            }

            // Find the index of the stage to be updated
            var foundIndex = state.project.stages!.findIndex(item => item.stageId === action.stageId);
            
            // Update the state 
            var updatedStages = state.project.stages;
            if (foundIndex !== -1) {
                updatedStages![foundIndex].used = action.used
                if (!action.used) updatedStages![foundIndex].startDate = null
            } else {
                console.log("Couldn't find stage")
            }
            updatedState = {
                ...state,
                project: {
                    ...state.project,
                    stages: updatedStages,
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        case ProjectActionType.stage_start_date_changed:
            // Make sure the stages array exists
            if (state.project.stages === null || state.project.stages.length === 0) {
                console.log("Stages array is not set")
                return {...state}
            }

            // Find the index of the stage to be updated
            foundIndex = state.project.stages!.findIndex(item => item.stageId === action.stageId);
            
            // Update the state 
            updatedStages = state.project.stages;
            if (foundIndex !== -1 && action.startDate) {
                updatedStages![foundIndex].startDate = formatDateForSQL(action.startDate)
            } else {
                console.log("Couldn't find stage")
            }
            updatedState = {
                ...state,
                project: {
                    ...state.project,
                    stages: updatedStages,
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        default:
            throw new Error(`Invalid project action type.`)
    }
}