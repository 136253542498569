import { Project, ProjectCollection } from "../../../shared/types/Project";
import { Result } from "../../../shared/types/DragAndDropResult";

//#region Actions
export enum ActionType {
    populate_state,
    project_dragged,
    filter,
}

interface PopulateStateAction {
    type: ActionType.populate_state;
    employeeDepartment: number;
    allProjects: ProjectCollection;
    favouriteProjects: number[];
}

interface ProjectDraggedAction {
    type: ActionType.project_dragged;
    result: Result;
}

interface FilterAction {
    type: ActionType.filter;
    textFilter: string;
    typeFilter: number;  
}

export type Action = PopulateStateAction | ProjectDraggedAction | FilterAction;
//#endregion

export enum ColumnType {
    all_projects_column,
    favourite_projects_column
}

type Column = {
    id: ColumnType;
    title: string;
    projectIds: number[];
}

type Columns = {
    all_projects_column: Column;
    favourite_projects_column: Column;
};

export interface State {
    projects: ProjectCollection,
    columns: Columns,
    unsavedChanges: boolean,
    originalState: string
}

const projectsData = (allProjects: ProjectCollection = {}, favouriteProjects: number[] = []) : State => {

    const allProjectsList: number[] = Object.values(allProjects)
        .sort((a, b) => ((a.fluidraProjectId ?? 0) > (b.fluidraProjectId ?? 0) ? 1 : -1))
        .map(project => project.id || 0)
        .filter(item => !favouriteProjects.includes(item));

    return {
        projects: allProjects,
        columns: {
            all_projects_column: {
                id: ColumnType.all_projects_column,
                title: 'All Projects',
                projectIds: allProjectsList,
            },
            favourite_projects_column: {
                id: ColumnType.favourite_projects_column,
                title: 'Favourite Projects',
                projectIds: favouriteProjects,
            },
        },
        unsavedChanges: false,
        originalState: ""
    };
}

const checkForChanges = (currentState: State): boolean => {
    try {
        const originalState: State = JSON.parse(currentState.originalState)

        const projectsChanged = JSON.stringify(currentState.projects) !== JSON.stringify(originalState.projects)
        const columnsChanged = JSON.stringify(currentState.columns) !== JSON.stringify(originalState.columns)

        return projectsChanged || columnsChanged;
    } catch (error) {
        console.log(error)
        return false
    }
}

export const initialState: State = projectsData();

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.populate_state: {
            const { employeeDepartment, allProjects, favouriteProjects } = action;
            
            // Filter out inactive projects and projects from a different department
            let projectsToRemove : number[] = [-2, -1, 0]
            Object.values(allProjects).forEach((project: Project) => {
                if (!project.active) projectsToRemove.push(project.id)
                if (!project.departments?.includes(employeeDepartment)) projectsToRemove.push(project.id)
            })

            // Filter out the project from the all projects list
            const filteredAllProjects: ProjectCollection = Object.fromEntries(
                Object.entries(allProjects).filter(([projectId]) => !projectsToRemove.includes(Number(projectId)))
            )

            // Filter out the projects from the favourite projects list
            const filteredFavouriteProjects = favouriteProjects
                .filter(project => !projectsToRemove.includes(project));
            
            // Create the state
            const populatedState: State = projectsData(filteredAllProjects, filteredFavouriteProjects)
            populatedState.originalState = JSON.stringify(populatedState)
            return populatedState;
        }
        case ActionType.project_dragged: {
            const { result } = action;
            const { draggableId, source, destination } = result;

            if (!destination) return state;

            if (
                destination.droppableId === source.droppableId && 
                destination.index === source.index
            ) return state;

            const columnMap = (index: string) => {
                switch (index) {
                    case '0': return state.columns.all_projects_column;
                    case '1': return state.columns.favourite_projects_column;
                    default: return undefined 
                }
            };

            const start = columnMap(source.droppableId);
            const finish = columnMap(destination.droppableId);
            
            if (!start || !finish) return state;

            // Reordering a list
            if (start === finish) {
                const newProjectIds = Array.from(start.projectIds);
                newProjectIds.splice(source.index, 1)
                newProjectIds.splice(destination.index, 0, Number(draggableId))

                const columnName = start.id === 0 ? 'all_projects_column' : 'favourite_projects_column';

                return {
                    ...state,
                    columns: {
                        ...state.columns,
                        [columnName]: {
                            ...start,
                            projectIds: newProjectIds
                        },
                    },
                }
            }

            // Moving from one list to another
            const startProjectIds = Array.from(start.projectIds)
            startProjectIds.splice(source.index, 1);

            const finishProjectIds = Array.from(finish.projectIds)
            finishProjectIds.splice(destination.index, 0, Number(draggableId));

            const startColumnName = start.id === 0 ? 'all_projects_column' : 'favourite_projects_column';
            const finishColumnName = finish.id === 0 ? 'all_projects_column' : 'favourite_projects_column';
            
            const updatedState: State = {
                ...state,
                columns: {
                    ...state.columns,
                    [startColumnName]: {
                        ...start,
                        projectIds: startProjectIds
                    },
                    [finishColumnName]: {
                        ...finish,
                        projectIds: finishProjectIds
                    },
                }
            };
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState;
        }
        case ActionType.filter: {
            const { textFilter, typeFilter } = action;
            const allProjects = state.projects;
            const favouriteProjects = state.columns.favourite_projects_column.projectIds;
            const allprojectsFiltered: number[] = Object.values(allProjects!)
                .sort((a, b) => ((a.fluidraProjectId ?? 0) > (b.fluidraProjectId ?? 0) ? 1 : -1))
                .filter(function(project) {
                    const matchId = project.fluidraProjectId?.toLowerCase().includes(textFilter!.toLowerCase()); 
                    const matchName = project.name!.toLowerCase().includes(textFilter!.toLowerCase()); 
                    const matchType = typeFilter === 0 || project.type === typeFilter;
                    return (matchId || matchName) && matchType;})
                .map(project => project.id)
                .filter(item => !favouriteProjects!.includes(item!))

            return {
                ...state,
                columns: {
                    ...state.columns,
                    all_projects_column: {
                        ...state.columns.all_projects_column,
                        projectIds: allprojectsFiltered,
                    }
                }
            };
        }
        default:
            throw new Error(`Invalid action type.`)
    }
}