const TIMESCALE_OPTIONS = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'YTD'
} as const;

type ObjectValues<T> = T[keyof T];

export type TimescaleOptions = ObjectValues<typeof TIMESCALE_OPTIONS>;

export const timescaleOptionsList = Object.values(TIMESCALE_OPTIONS);