import ExcelJS from 'exceljs';

import { getStartAndEndDate, getMonthName, formatDateForSQL, addMonth } from "../shared/functions/dateAndTime";
import { rowAndColNumToCell, downloadExcelFile, createOuterBorder } from '../shared/functions/excel';
import { Row } from '../shared/types/excel';
import { sortByFluidraProjectId } from '../shared/functions/projects';
import { getEmployeeName } from '../shared/functions/employees';
import { getAllProjects } from '../api/projects';
import { getAllEmployees } from '../api/employees';
import { getDepartments } from '../api/departments';
import { getCapitalisationGroups } from '../api/capitalisationGroups';
import { getSummaryByProject } from '../api/summary';

const GenerateCapitalisationReport = async (month: number, year: number) => {
    month += 1;
    
    console.log(`Generating Capitalisation Report for ${getMonthName(month)} ${year}...`)

    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('All Projects');
    
    const [startDate, endDate] = getStartAndEndDate(month, year);

    // Make API calls to get the data
    const allProjects = await getAllProjects();
    const allEmployees = await getAllEmployees();
    const departments = await getDepartments();
    const capitalisationGroups = Object.values(await getCapitalisationGroups())
    const summaryByProject = await getSummaryByProject({startDate: formatDateForSQL(startDate), endDate: formatDateForSQL(endDate)});

    // Create a dict of departments and their employees
    const departmentEmployees: Record<number, number[]> = {}
    for (const departmentId in departments) {
        const employees = Object.values(allEmployees)
            .filter(employee => employee.department === Number(departmentId))
            .map(employee => employee.id);
        departmentEmployees[departmentId] = employees
    }

    // Create a map of column numbers
    const columnMap: Record<string, number[]> = {
        "Project Number": [1, 1],
        "Project Name": [2, 2],
        "Capitalisation Group": [3, 3],
        "Total Hours": [4, 4],
        "Total Cost": [5, 5],
    } 

    // Create the header rows
    const row1: Row = [`Capitalisation Report - ${getMonthName(month)} ${year}`]
    const row2: Row = []
    const row3: Row = [null, null, null, null, null]
    const row4: Row = ["All Projects"]
    const row5: Row = [null, null, null, null, null]
    const row6: Row = ['Fluidra Project No.','Project Name', 'Capitalisation Group', 'Total Project Hours', 'Total Project Cost']
    
    // Add department totals
    var currentColumn = 6
    columnMap["Department Hours"] = [currentColumn, 0];
    for (let department of Object.values(departments)) {
        row3.push(null);
        row5.push(null);
        row6.push(`${department} Hours`);
        columnMap[`${department} Hours`] = [currentColumn, currentColumn];
        currentColumn += 1
    }
    columnMap["Department Hours"][1] = currentColumn - 1;

    columnMap["Department Cost"] = [currentColumn, 0];
    for (let department of Object.values(departments)) {
        row3.push(null);
        row5.push(null);
        row6.push(`${department} Cost`)
        columnMap[`${department} Cost`] = [currentColumn, currentColumn];
        currentColumn += 1
    }
    columnMap["Department Cost"][1] = currentColumn - 1;

    row3[row3.length - 2] = 'Hourly salary rate';

    for (const department in departmentEmployees) {
        const employees = departmentEmployees[department]
        const departmentName = departments[Number(department)]
        if (employees.length > 0) {
            columnMap[departmentName] = [0, 0]
            columnMap[departmentName][0] = currentColumn;
            
            for (let employeeId of employees) {
                const employeeName = getEmployeeName(allEmployees, employeeId)
                columnMap[employeeName] = [currentColumn, currentColumn]
                currentColumn += 1

                row3.push(0)
                row5.push(departmentName);
                row6.push(employeeName);
            }
            
            columnMap[departmentName][1] = currentColumn - 1;
        } 
    }

    columnMap["All"] = [1, currentColumn - 1];

    ws.addRow(row1);
    ws.addRow(row2);
    ws.addRow(row3);
    ws.addRow(row4);
    ws.addRow(row5);
    ws.addRow(row6);

    // Sort the projects by Fluidra Project ID
    const sortedProjects = sortByFluidraProjectId(Object.values(allProjects))

    // Add the project data
    const startRow = 7;
    var rowNum = startRow;
    for (let project of sortedProjects) {
        const projectId = project.id;
        const fluidraProjectId = project.fluidraProjectId;
        const projectName = project.name;
        const capitalisationGroupId = project.capitalisationGroup;
        const projectLogs = summaryByProject[projectId]

        const foundCapitalisationGroup = capitalisationGroups.find(group => group.id === capitalisationGroupId);
        var capitalisationGroup = ""
        if (foundCapitalisationGroup) capitalisationGroup = foundCapitalisationGroup.name;

        // Create the row
        var row: Row = [fluidraProjectId, projectName, capitalisationGroup];
        row = row.concat(new Array(columnMap["All"][1] - 3).fill(0));

        // Add the hours to the row
        if (projectLogs) {
            for (let log of projectLogs) {
                const employeeId = log.id;
                const employeeName = getEmployeeName(allEmployees, employeeId)
                const hours = log.hours;
                if (employeeName in columnMap) {
                    const index = columnMap[employeeName][0] - 1;
                    row[index] = hours;
                }
            }
        }

        // Add the row to the worksheet
        ws.addRow(row)

        // Add the department total hours formulas
        for (let department of Object.values(departments)) {
            if (columnMap[department]) {
                const startCell = rowAndColNumToCell(rowNum, columnMap[department][0]);
                const endCell = rowAndColNumToCell(rowNum, columnMap[department][1]);

                ws.getCell(rowAndColNumToCell(rowNum, columnMap[`${department} Hours`][0])).value = {
                    formula: `SUM(${startCell}:${endCell})`,
                    result: 0,
                    // sharedFormula: "",
                    date1904: false
                };
            }
        }

        // Add the department total cost formulas
        for (let department of Object.values(departments)) {
            if (columnMap[department]) {
                const startCell = rowAndColNumToCell(rowNum, columnMap[department][0]);
                const endCell = rowAndColNumToCell(rowNum, columnMap[department][1]);

                const startRateCell = rowAndColNumToCell(3, columnMap[department][0], true);
                const endRateCell = rowAndColNumToCell(3, columnMap[department][1], true);

                ws.getCell(rowAndColNumToCell(rowNum, columnMap[`${department} Cost`][0])).value = { 
                    formula: `SUMPRODUCT(${startCell}:${endCell}, ${startRateCell}:${endRateCell})`,
                    result: 0,
                    date1904: false
                };
            } else {
                ws.getCell(rowAndColNumToCell(rowNum, columnMap[`${department} Cost`][0])).value = 0;
            }
        }

        // Add the total hours formula
        ws.getCell(rowAndColNumToCell(rowNum, columnMap["Total Hours"][0])).value = {
            formula: `SUM(${rowAndColNumToCell(rowNum, columnMap["Department Hours"][0])}:${rowAndColNumToCell(rowNum, columnMap["Department Hours"][1])})`,
            result: 0,
            date1904: false
        }

        // Add the total cost formula
        ws.getCell(rowAndColNumToCell(rowNum, columnMap["Total Cost"][0])).value = {
            formula: `SUM(${rowAndColNumToCell(rowNum, columnMap["Department Cost"][0])}:${rowAndColNumToCell(rowNum, columnMap["Department Cost"][1])})`,
            result: 0,
            date1904: false
        }

        rowNum += 1;
    }

    const endRow = rowNum;

    ws.addRow([])
    ws.addRow([null, null, "Totals"])

    // Add totals
    ws.getCell(rowAndColNumToCell(endRow + 1, columnMap["Total Hours"][0])).value = {
        formula: `SUM(${rowAndColNumToCell(startRow, columnMap["Total Hours"][0])}:${rowAndColNumToCell(endRow - 1, columnMap["Total Hours"][0])})`,
        result: 0,
        date1904: false
    }

    ws.getCell(rowAndColNumToCell(endRow + 1, columnMap["Total Cost"][0])).value = {
        formula: `SUM(${rowAndColNumToCell(startRow, columnMap["Total Cost"][0])}:${rowAndColNumToCell(endRow - 1, columnMap["Total Cost"][0])})`,
        result: 0,
        date1904: false
    }

    for (let department of Object.values(departments)) {
        // Department Hours totals
        ws.getCell(rowAndColNumToCell(endRow + 1, columnMap[`${department} Hours`][0])).value = { 
            formula: `SUM(${rowAndColNumToCell(startRow, columnMap[`${department} Hours`][0])}:${rowAndColNumToCell(endRow - 1, columnMap[`${department} Hours`][0])})`,
            result: 0,
            date1904: false
        };

        // Department Cost totals
        ws.getCell(rowAndColNumToCell(endRow + 1, columnMap[`${department} Cost`][0])).value = { 
            formula: `SUM(${rowAndColNumToCell(startRow, columnMap[`${department} Cost`][0])}:${rowAndColNumToCell(endRow - 1, columnMap[`${department} Cost`][0])})`,
            result: 0,
            date1904: false
        };

        if (columnMap[department]) {
            // Employee hours totals
            for (let i = columnMap[department][0]; i <= columnMap[department][1]; i++) {
                ws.getCell(rowAndColNumToCell(endRow + 1, i)).value = { 
                    formula: `SUM(${rowAndColNumToCell(startRow, i)}:${rowAndColNumToCell(endRow - 1, i)})`,
                    result: 0,
                    date1904: false
                };
            }
        }
    }

    // Add the capitalisation group summary
    ws.addRow([])
    ws.addRow(["Capitalisation Groups"])
    ws.addRow([])
    ws.addRow(["Id", "Name", "Cost"])

    const costRange = `${rowAndColNumToCell(startRow, columnMap["Total Cost"][0], true)}:${rowAndColNumToCell(endRow - 1, columnMap["Total Cost"][0], true)}`
    const capitalisationGroupRange = `${rowAndColNumToCell(startRow, 3, true)}:${rowAndColNumToCell(endRow - 1, 3, true)}`

    const startRow2 = endRow + 6;
    var endRow2 = startRow2;
    for (let group of capitalisationGroups) {
        const id = group.fluidraCapitalisationGroupId;
        const name = group.name;

        ws.addRow([id, name]);
        ws.getCell(rowAndColNumToCell(endRow2, 3)).value = { 
            formula: `SUMIFS(${costRange}, ${capitalisationGroupRange}, B${endRow2})`,
            result: 0,
            date1904: false
        };
        endRow2 += 1;
    }
    endRow2 -= 1;

    ws.addRow([])
    ws.addRow([null, "Total to Capitalise"])

    // Add total formula
    ws.getCell(rowAndColNumToCell(endRow2 + 2, 3)).value = {
        formula: `SUM(${rowAndColNumToCell(startRow2, 3)}:${rowAndColNumToCell(endRow2, 3)})`,
        result: 0,
        date1904: false
    }


    // All Projects Styles

    // Title
    ws.getCell('A1').font = { size: 18, bold: true, color: {argb: '002F75B5'}} 

    // All projects table heading 
    ws.getCell('A4').font = { size: 14, bold: true, color: {argb: '007030A0'}} 

    // Header font and alignment
    ws.getRow(6).alignment = { wrapText: true, vertical: "middle", horizontal: "center" };
    ws.getRow(6).font = { bold: true };

    // Total header font
    ws.getRow(endRow + 1).font = { bold: true};
    ws.getCell(rowAndColNumToCell(endRow + 1, 3)).alignment = { vertical: 'middle', horizontal: 'right' };

    // Merge and bold department header cells
    for (let department of Object.values(departments)) {
        if (columnMap[department]) {
            const [startCol, endCol] = columnMap[department]
            const mergeRange = `${rowAndColNumToCell(5, startCol)}:${rowAndColNumToCell(5, endCol)}`;
            ws.mergeCells(mergeRange);
            ws.getCell(mergeRange).alignment = { vertical: 'bottom', horizontal: 'center' };
            ws.getCell(mergeRange).font = { bold: true };
        }
    }

    // Merge rate header cells
    const mergeRange = `${rowAndColNumToCell(3, columnMap["Department Cost"][1] - 1)}:${rowAndColNumToCell(3, columnMap["Department Cost"][1])}`
    ws.mergeCells(mergeRange);
    ws.getCell(mergeRange).font = { bold: true };
    ws.getCell(mergeRange).alignment = { vertical: 'middle', horizontal: 'right' };

    // Set column widths
    ws.getColumn(1).width = 10;
    ws.getColumn(2).width = 40;
    ws.getColumn(3).width = 20;
    for (let i = 4; i <= columnMap["All"][1]; i++) {
        ws.getColumn(i).width = 12;
    }

    // Add the inner borders
    for (let i = 1; i <= columnMap["All"][1]; i++) {
        createOuterBorder(ws, {row: 6, col: i}, {row: endRow - 1, col: i}, 'thin');
    }
    for (let i = 6; i < endRow - 1; i++) {
        createOuterBorder(ws, {row: i, col: columnMap["All"][0]}, {row: i, col: columnMap["All"][1]}, 'thin');
    }

    // Add inner borders to rates and total
    for (let i = columnMap["Department Cost"][1] + 1; i <= columnMap["All"][1]; i++) {
        createOuterBorder(ws, {row: 3, col: i}, {row: 3, col: i}, 'thin');
    }
    for (let i = 4; i <= columnMap["All"][1]; i++) {
        createOuterBorder(ws, {row: endRow + 1, col: i}, {row: endRow + 1, col: i}, 'thin');
    }

    // Add outer borders to headings, rate and total
    createOuterBorder(ws, {row: 3, col: columnMap["Department Cost"][1] + 1}, {row: 3, col: columnMap["All"][1]}, 'medium');
    createOuterBorder(ws, {row: 5, col: columnMap["Department Cost"][1] + 1}, {row: 5, col: columnMap["All"][1]}, 'medium');
    createOuterBorder(ws, {row: 6, col: columnMap["All"][0]}, {row: 6, col: columnMap["All"][1]}, 'medium');
    createOuterBorder(ws, {row: endRow + 1, col: 4}, {row: endRow + 1, col: columnMap["All"][1]}, 'medium');

    // Add outer borders to data
    createOuterBorder(ws, {row: 6, col: 1}, {row: endRow - 1, col: 3}, 'medium');

    createOuterBorder(ws, {row: 6, col: columnMap["Total Hours"][0]},  {row: endRow - 1, col: columnMap["Total Cost"][1]}, 'medium');
    createOuterBorder(ws, {row: endRow + 1, col: columnMap["Total Hours"][0]},  {row: endRow + 1, col: columnMap["Total Cost"][1]}, 'medium');

    createOuterBorder(ws, {row: 6, col: columnMap["Department Hours"][0]},  {row: endRow - 1, col: columnMap["Department Hours"][1]}, 'medium');
    createOuterBorder(ws, {row: endRow + 1, col: columnMap["Department Hours"][0]},  {row: endRow + 1, col: columnMap["Department Hours"][1]}, 'medium');

    createOuterBorder(ws, {row: 6, col: columnMap["Department Cost"][0]},  {row: endRow - 1, col: columnMap["Department Cost"][1]}, 'medium');
    createOuterBorder(ws, {row: endRow + 1, col: columnMap["Department Cost"][0]},  {row: endRow + 1, col: columnMap["Department Cost"][1]}, 'medium');
    
    for (let department of Object.values(departments)) {
        if (columnMap[department]) {
            const [startCol, endCol] = columnMap[department]
            createOuterBorder(ws, {row: 3, col: startCol}, {row: 3, col: endCol}, 'medium');
            createOuterBorder(ws, {row: 5, col: startCol}, {row: endRow - 1, col: endCol}, 'medium');
            createOuterBorder(ws, {row: endRow + 1, col: startCol}, {row: endRow + 1, col: endCol}, 'medium');
        }
    }

    // Format columns
    ws.getColumn(columnMap["Total Cost"][0]).numFmt = '"$"#,##0.00';
    for (let i = columnMap["Department Cost"][0]; i <= columnMap["Department Cost"][1]; i++) {
        ws.getColumn(i).numFmt = '"$"#,##0.00';
    }
    ws.getRow(3).numFmt = '"$"#,##0.00';

    // Capitalisation Groups styles

    // Capitalisation Groups table heading
    ws.getCell(rowAndColNumToCell(endRow + 3, 1)).font = { size: 14, bold: true, color: {argb: '007030A0'}}    
    
    // Header font and alignment
    ws.getRow(startRow2 - 1).font = { bold: true };

    // Total header font
    ws.getRow(endRow2 + 2).font = { bold: true };
    ws.getCell(rowAndColNumToCell(endRow2 + 2, 2)).alignment = { vertical: 'middle', horizontal: 'right' };

    // Add inner borders
    for (let i = startRow2 - 1; i < endRow2; i++) {
        createOuterBorder(ws, {row: i, col: 1}, {row: i, col: 3}, 'thin');
    }
    for (let i = 1; i <= 3; i++) {
        createOuterBorder(ws, {row: startRow2 - 1, col: i}, {row: endRow2, col: i}, 'thin');
    }

    // Add outer borders
    createOuterBorder(ws, {row: startRow2 - 1, col: 1}, {row: startRow2 - 1, col: 3}, 'medium');
    createOuterBorder(ws, {row: startRow2, col: 1}, {row: endRow2, col: 3}, 'medium');
    createOuterBorder(ws, {row: endRow2 + 2, col: 3}, {row: endRow2 + 2, col: 3}, 'medium');
  
    // Format column
    ws.getColumn(3).numFmt = '"$"#,##0.00';

    // Freeze panes
    ws.views = [
        {state: 'frozen', xSplit: 3, ySplit: 6, topLeftCell: 'D7'}
    ];

    // Download the workbook
    const buffer = await wb.xlsx.writeBuffer();
    downloadExcelFile(buffer, `Capitalisation Report - ${getMonthName(month)} ${year}`);
}

export default GenerateCapitalisationReport;