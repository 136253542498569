import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ItemList from '../components/common/ItemList';
import EmployeeWindow from '../components/pages/AdminEmployeesPageComponents/EmployeeWindow';
import SearchBar from '../components/common/SearchBar';
import ConfirmNavigationPopup from '../components/common/ConfirmNavigationPopup';
import { getAllEmployees } from '../api/employees';
import { getDepartments } from '../api/departments';
import { EmployeeListActionType, initialEmployeeListState, employeeListReducer } from '../components/pages/AdminEmployeesPageComponents/employeeList.reducer';
import { DropdownItems, emptyDrowdownItems } from '../shared/types/DropdownItems';
import { EmployeeCollection } from '../shared/types/Employee';

interface EmployeesPageProps {
    unsavedChanges: boolean,
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
}

const EmployeesPage: React.FC<EmployeesPageProps> = ({ unsavedChanges, setUnsavedChanges }) => {
    const [ allEmployees, setAllEmployees] = useState<EmployeeCollection>({});
    const [ departments, setDepartments ] = useState<DropdownItems>(emptyDrowdownItems);
    const [ managers, setManagers ] = useState<DropdownItems>(emptyDrowdownItems);
    
    const [ textFilter, setTextFilter ] = useState<string>('');

    const [ state, dispatch ] = useReducer(employeeListReducer, initialEmployeeListState);

    const [ triggerEffect, setTriggerEffect ] = useState<boolean>(false)

    const location = useLocation();
    const navigate = useNavigate();

    // Runs on first load
    useEffect(() => {
        return () => {
            setUnsavedChanges(false)
        }
    }, [])
    
    useEffect(() => {
        getAllEmployeesAsync();
        getDepartmentsAsync();
    }, [triggerEffect]);

    useEffect(() => {
        dispatch({
            type: EmployeeListActionType.populate_list,
            allEmployees: allEmployees
        })
    }, [allEmployees])

    useEffect(() => {
        dispatch({
            type: EmployeeListActionType.filter,
            textFilter: textFilter
        })
    }, [textFilter])

    // #region API Callers
    const getAllEmployeesAsync = async () => {
        const allEmployees: EmployeeCollection = await getAllEmployees();
        setAllEmployees(allEmployees);        // Get a list of managers
        
        let managers: DropdownItems = {0: "No one"}
        Object.values(allEmployees).forEach(employee => {
            if (employee.isManager) managers[employee.id] = `${employee.firstName} ${employee.lastName}` 
        })
        setManagers(managers)
    }
    const getDepartmentsAsync = async () => setDepartments(await getDepartments());
    // #endregion

    const handleRefreshPage = () => {
        setTextFilter('')
        setTriggerEffect(prevState => !prevState);
    }

    // #region Button Handlers
    const employeeClicked = (fluidraEmployeeId: any) => {
        ConfirmNavigationPopup(`/admin/employees/${fluidraEmployeeId}`, unsavedChanges, navigate);
    }

    const addNewEmployeeClicked = () => {
        ConfirmNavigationPopup(`/admin/employees`, unsavedChanges, navigate);
    }
    // #endregion

    return (
        <Page>
            <Row>
                <Column>
                    <SearchBar
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    <ItemList 
                        itemType='Employee'
                        items={state.filteredEmployees}
                        itemClicked={employeeClicked}
                        selectedItem={location.pathname.replace('/admin/employees/', '')}
                        heightAdjustment={398}
                        hasBottomButton={true}
                        bottomButtonClicked={addNewEmployeeClicked} />
                </Column>
                <EmployeeWindow 
                    allEmployees={allEmployees}
                    departments={departments}
                    managers={managers}
                    unsavedChanges={unsavedChanges}
                    setUnsavedChanges={setUnsavedChanges}
                    refreshPage={handleRefreshPage} />
            </Row>
        </Page>
    );
}

//#region Styles
const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 200px);
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0px 10px;
    height: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
//#endregion

export default EmployeesPage;