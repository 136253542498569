export type CapitalisationGroup = {
    id: number;
    fluidraCapitalisationGroupId: string;
    name: string;
    description: string;
    projects: number[];
};

export const emptyCapitalisationGroup: CapitalisationGroup = {
    id: 0,
    fluidraCapitalisationGroupId: "",
    name: "",
    description: "",
    projects: []
}

export type CapitalisationGroupCollection = Record<number, CapitalisationGroup>;