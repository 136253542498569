import React, { useState, useEffect, FunctionComponent } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from "moment";
import styled from 'styled-components';

import "react-big-calendar/lib/css/react-big-calendar.css";
import '../styles/calendar.scss'
import { Card } from '../components/common/CommonStyles';
import { addMonth, subtractMonth, getMonthNumber, getMonthName } from '../shared/functions/dateAndTime';
import { getAllProjects } from "../api/projects";
import { getLogs, GetLogsRequestBody } from "../api/logs";


import CalendarDay from '../components/pages/CalendarPageComponents/CalendarDay';
import CustomToolbar from '../components/calendar/CustomToolbar';

import {useAuth, AuthProvider} from '../authprovider'
import { SQLDate } from "../shared/types/SQLDate";
import { Months } from "../shared/enums/months";
import { ProjectCollection } from "../shared/types/Project";
import { Logs } from "../shared/types/Logs";


//#region Functions
const firstOfMonth = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

const getStartDate = (date: Date): SQLDate => {
    // Clone the input date to avoid modifying the original date
    const newDate = new Date(subtractMonth(date));
    const mm = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const yyyy = newDate.getFullYear();

    return `${yyyy}-${mm}-23`
}

const getEndDate = (date: Date): SQLDate => {
    // Clone the input date to avoid modifying the original date
    const newDate = new Date(addMonth(date));

    const mm = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const yyyy = newDate.getFullYear();

    return `${yyyy}-${mm}-06`
}
// #endregion

const localizer = momentLocalizer(moment);

interface CalendarProps {
    actingAs: number | null;
}

const CalendarPage: FunctionComponent<CalendarProps> = ({ actingAs }) => {

    const auth = useAuth()

    const { month, year } = useParams();
    
    const [ date, setDate ] = useState<Date>(firstOfMonth(new Date()));
    const [ logs, setLogs ] = useState<Logs>({})
    const [ allProjects, setAllProjects ] = useState<ProjectCollection>({});

    useEffect(() => {
        const formattedMonth: Months = month as Months;
        const formattedYear: number = Number(year);
        const date = new Date(formattedYear, getMonthNumber(formattedMonth) - 1, 1);
        setDate(date);

        getLogsAsync(date);
        getAllProjectsAsync();
    }, [month, year, actingAs])

    const getLogsAsync = async (date: Date) => {
        const id = Number(actingAs ? actingAs : auth.user)

        const requestBody: GetLogsRequestBody = {
            id: id,
            startDate: getStartDate(date),
            endDate: getEndDate(date)
        }

        const result = await getLogs(requestBody);
        setLogs(result);
    }

    const getAllProjectsAsync = async () => {
        setAllProjects(await getAllProjects());
    }

    const navigate = useNavigate(); 

    const goToPrevMonth = () => {
        const newDate = subtractMonth(date);
        const month = getMonthName(newDate.getMonth() + 1);
        const year = newDate.getFullYear();

        console.log(`SETTING VIEW TO PREVIOUS MONTH: ${month} ${year}`)
        navigate(`/logging/calendar/${month}/${year}`);
    }

    const goToNextMonth = () => {
        const newDate = addMonth(date);
        const month = getMonthName(newDate.getMonth() + 1);
        const year = newDate.getFullYear();

        console.log(`SETTING VIEW TO NEXT MONTH: ${month} ${year}`)
        navigate(`/logging/calendar/${month}/${year}`);
    }

    return (
        <CalendarCard>
            <Calendar
                localizer={localizer}
                date={date}
                onNavigate={() => {}}
                defaultView="month"
                events={[]}
                components={{
                    dateCellWrapper: (props) => {
                        return (
                            <CalendarDay props={props} logs={logs} calendarMonth={date.getMonth()} allProjects={allProjects} />
                        );
                    },
                    month: {
                        dateHeader: () => {
                            return <></>
                        }
                    },
                    toolbar: (props) => {
                        return (
                            <CustomToolbar props={props} goToPrevMonth={goToPrevMonth} goToNextMonth={goToNextMonth} />
                        )
                    }
                }}
            />
        </CalendarCard>
    );
}

//#region Styles
const CalendarCard = styled(Card)`
    margin: 20px;
    width: 100%;
`;
//#endregion

export default CalendarPage;