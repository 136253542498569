import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../values/colors';

interface SearchBarProps {
    textFilter: string,
    setTextFilter: React.Dispatch<React.SetStateAction<string>>
}

const SearchBar: React.FC<SearchBarProps> = ({textFilter, setTextFilter}) => {
    const searchIcon = require('../../../resources/search-primary.png');

    const [ isFocused, setIsFocused ] = useState(false);

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    return (
        <Bar $isFocused={isFocused}>
            <SearchIcon src={searchIcon} alt="search" />
            <SearchBarStyle 
                value={textFilter} 
                placeholder="Search by ID or Name"
                onChange={e => setTextFilter(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur} />
        </Bar>
    );
}

// #region Styles
const SearchBarStyle = styled.input`
    height: 40px;
    width: 200px;
    background-color: transparent;
    border: 0px;

    &:focus-visible {
        outline: 0px;
    }
`;

const Bar = styled.div<{$isFocused: boolean}>`
    display: grid;
    grid-template-columns: 40px 1fr;
    background-color: ${props => (props.$isFocused ? COLORS.secondaryGray3 : COLORS.secondaryGray4)};
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
`;

const SearchIcon = styled.img`
    height: 20px;
    width: 20px;
    margin: 10px;
`;
// #endregion

export default SearchBar;
