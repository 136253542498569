import { get } from "../adapters/apiAdapter";
import { Summary } from "../shared/types/Summary";
import { SQLDate } from "../shared/types/SQLDate";


export type GetSummaryRequestBody = {
    startDate: SQLDate,
    endDate: SQLDate
}
export type GetManagerSummaryRequestBody = {
    id: number,
    startDate: SQLDate,
    endDate: SQLDate
}

export async function getSummaryByEmployee(requestBody: GetSummaryRequestBody): Promise<Summary> {
    const response = await get(`/api/summary/by-employee?startDate=${requestBody.startDate}&endDate=${requestBody.endDate}`);
    return response.data;
}

export async function getManagerSummaryByEmployee(requestBody: GetManagerSummaryRequestBody): Promise<Summary> {
    const response = await get(`/api/summary/by-employee/manager?id=${requestBody.id}&startDate=${requestBody.startDate}&endDate=${requestBody.endDate}`);
    return response.data;
}

export async function getSummaryByProject(requestBody: GetSummaryRequestBody): Promise<Summary> {
    const response = await get(`/api/summary/by-project?startDate=${requestBody.startDate}&endDate=${requestBody.endDate}`);
    return response.data;
}

export async function getManagerSummaryByProject(requestBody: GetManagerSummaryRequestBody): Promise<Summary> {
    const response = await get(`/api/summary/by-project/manager?id=${requestBody.id}&startDate=${requestBody.startDate}&endDate=${requestBody.endDate}`);
    return response.data;
}