import { get, post } from "../adapters/apiAdapter";

export type GetFavouriteProjectsRequestBody = {
    id: number
}

export type PostFavouriteProjectsRequestBody = {
    id: number,
    favourites: number[]
}

export async function getFavouriteProjects(requestBody: GetFavouriteProjectsRequestBody) {
    const response = await get(`/api/favourites?id=${requestBody.id}`);
    return response.data;
}

export async function postFavouriteProjects(requestBody: PostFavouriteProjectsRequestBody) {
    return await post(`/api/favourites`, requestBody);
};