import { confirmAlert } from 'react-confirm-alert';
import '../../styles/confirm.scss'

export const ConfirmNavigationPopup = (url: string, unsavedChanges: boolean, navigate: (url: string) => void) => {
    if (unsavedChanges) {
        confirmAlert({
            title: "Are you sure?",
            message: "Are you sure you want to leave this page? Any unsaved changes will be lost.",
            buttons: [
                {
                    label: "No",
                    className: 'react-confirm-no-button',
                    onClick: () => console.log("Cancelling navigation")
                },
                {
                    label: "Yes",
                    className: 'react-confirm-yes-button',
                    onClick: () => navigate(url)
                }
            ]
        })
    } else {
        navigate(url)
    }
}

export default ConfirmNavigationPopup