import React, { useState } from "react";
import styled from 'styled-components';

import OptionBar from '../components/common/OptionBar';
import TimePeriodNavigator from '../components/common/TimePeriodNavigator';
import { getPreviousSunday, getNextSaturday } from "../shared/functions/dateAndTime";
import { TimescaleOptions, timescaleOptionsList } from "../shared/enums/timescale";
import { SummaryGroupingOptions, summaryGroupingOptionsList } from "../shared/enums/summaryGrouping";
import SummaryByProject from '../components/pages/AdminSummaryPageComponents/SummaryByProject'
import SummaryByEmployee from '../components/pages/AdminSummaryPageComponents/SummaryByEmployee'


const SummaryPage = () => { 
    const currentDate = new Date();
    const firstDayOfThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const [ startDate, setStartDate ] = useState<Date>(firstDayOfThisMonth);
    const [ endDate, setEndDate ] = useState<Date>(lastDayOfThisMonth);

    const [ grouping, setGrouping ] = useState<SummaryGroupingOptions>('Project');
    const [ timescale, setTimescale ] = useState<TimescaleOptions>('Monthly')

    const onGroupingSelected = (selectedOption: SummaryGroupingOptions) => {
        setGrouping(selectedOption);
      };

    const onTimescaleSelected = (selectedTimescale: TimescaleOptions) => {
        const currentDate: Date = new Date();
        var newStartDate: Date;
        var newEndDate: Date;

        switch(selectedTimescale) {
            case 'Daily':
                newStartDate = currentDate
                newEndDate = currentDate
                break;
            case 'Weekly':
                newStartDate = getPreviousSunday(currentDate)
                newEndDate = getNextSaturday(currentDate)
                break;
            case 'Monthly':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                break;
            case 'YTD': 
                const year = currentDate.getFullYear();
                newStartDate = new Date(year, 0, 1);
                newEndDate = new Date(year + 1, 0, 0);
                break;
        }
        
        setTimescale(selectedTimescale);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const onTimePeriodChanged = (newStartDate: Date, newEndDate: Date) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }

    return (
        <Page className="scrollableList2">
            <Row>
                <OptionBar options={summaryGroupingOptionsList} selectedOption={grouping} onOptionSelected={onGroupingSelected}/>
                <OptionBar options={Object.values(timescaleOptionsList)} selectedOption={timescale} onOptionSelected={onTimescaleSelected}/>
                <TimePeriodNavigator timescale={timescale} startDate={startDate} setTimePeriod={onTimePeriodChanged} />
            </Row>
            {grouping === "Employee" 
            ? <SummaryByEmployee startDate={startDate} endDate={endDate} />
            : <SummaryByProject startDate={startDate} endDate={endDate} />
            }
        </Page>
    );
}

//#region Styles
const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 10px 0px;
    height: calc(100vh - 200px);
    width: calc(100vw - 10px);
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 5px;
`;
//#endregion

export default SummaryPage;