import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from '../../values/colors';

// #region Blue Button
const BlueButtonStyle = styled.button`
    background-color: ${COLORS.secondaryBlue};
    color: white;
    border: 0px solid black;
    border-radius: 20px;
    box-sizing: border-box;
    height: 56px;
    width: 270px;
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 5px 10px;

    font-size: 14pt;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;

    cursor: pointer;
    justify-content: center;
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;

export const Button = styled.button`
    height : 40px;
    width: 100%;
    padding: 0px 10px;
    margin: 10px 0px;

    color: white;
    font-size: 16pt;
    font-weight: bold;

    border: none;
    border-radius: 20px;

    cursor: pointer;

    background-color: ${COLORS.secondaryBlue};
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;

interface BlueButtonProps {
    onClick: any,
    buttonText: string
}

export const BlueButton: React.FC<BlueButtonProps> = ({ onClick, buttonText }) => {
    return (
        <BlueButtonStyle onClick={onClick} >
            {buttonText}
        </BlueButtonStyle>
    );
};
// #endregion

// #region Link Button
const StyledLink = styled(Link)`
    text-decoration: none;
`;

interface LinkButtonProps {
    to: string,
    buttonText: string
}

export const LinkButton: React.FC<LinkButtonProps> = ({ to, buttonText }) => {
    return (
        <StyledLink to={to}>
            <BlueButtonStyle>{buttonText}</BlueButtonStyle>
        </StyledLink>
    );
};
// #endregion

// #region Text Link
const TextLinkStyle = styled(Link)<{$width: number}>`
    box-shadow: inset 0 0 0 0 ${COLORS.secondaryBlue};
    color: ${COLORS.secondaryBlue};
    padding: 0 .25rem;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    &:hover {
        box-shadow: inset ${(props) => props.$width}px 0 0 ${COLORS.secondaryBlue};
        color: white;
        cursor: pointer;
    }
`;

interface TextLinkProps {
    to: string,
    text: string,
    width: number
}

export const TextLink: React.FC<TextLinkProps> = ({to, text, width}) => {
    return (
        <TextLinkStyle 
            to={to} 
            $width={width}>
            {text}
        </TextLinkStyle>
    )
}
// #endregion

// #region Cancel Button
const CancelButtonStyle = styled.button`
    height : 40px;
    width: 200px;
    padding: 0px 10px;
    margin-right: 10px;
    font-size: 16pt;
    font-weight: bold;

    border: 3px solid ${COLORS.secondaryBlue};
    border-radius: 20px;

    color: ${COLORS.secondaryBlue};
    background-color: white;
    cursor: pointer;
    &:hover {
        color: white;
        background-color: ${COLORS.secondaryBlue};
    }
`;

interface CancelButtonProps {
    onClick: any
}

export const CancelButton: React.FC<CancelButtonProps> = ({onClick}) => {
    return (
        <CancelButtonStyle onClick={onClick}>CANCEL</CancelButtonStyle>
    )
};

export const WideCancelButton: React.FC<CancelButtonProps> = ({onClick}) => {
    return (
        <CancelButtonStyle style={{'width': '100%', 'marginTop': '20px'}} onClick={onClick}>CANCEL</CancelButtonStyle>
    )
}
// #endregion

// #region Delete Button
const DeleteButtonStyle = styled.button`
    height : 40px;
    width: 150px;
    padding: 0px 10px;

    color: white;
    font-size: 16pt;
    font-weight: bold;

    border: 3px solid ${COLORS.red};
    border-radius: 20px;

    color: ${COLORS.red};
    background-color: white;
    cursor: pointer;
    &:hover {
        color: white;
        background-color: ${COLORS.red};
    }
`;

interface DeleteButtonProps {
    onClick: any
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({onClick}) => {
    return (
        <DeleteButtonStyle onClick={onClick}>
            DELETE
        </DeleteButtonStyle>
    )
};
// #endregion

// #region Save Button
const SaveButtonStyle = styled.button`
    height : 40px;
    width: 200px;
    padding: 0px 10px;
    margin-left: 10px;

    color: white;
    font-size: 16pt;
    font-weight: bold;

    border: none;
    border-radius: 20px;
    cursor: pointer;

    background-color: ${COLORS.secondaryBlue};
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;

const SaveButtonLoadingStyle = styled.div`
    height : 40px;
    width: 200px;
    padding: 0px 0px;
    margin-left: 10px;
    text-align: center;

    color: white;
    font-size: 16pt;
    font-weight: bold;

    border: none;
    border-radius: 20px;
    cursor: pointer;

    background-color: ${COLORS.secondaryBlue};
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;

interface SaveButtonProps {
    onClick: any,
    text?: string,
    loading?: boolean
}

export const SaveButton: React.FC<SaveButtonProps> = ({onClick, text="SAVE", loading=false}) => {
    
    if (loading) {
        return (
            <SaveButtonLoadingStyle>
                <div className="loading-icon"><div></div><div></div><div></div><div></div></div>
            </SaveButtonLoadingStyle>
        )
    } else {
        return (
            <SaveButtonStyle onClick={onClick}>
                {text}
            </SaveButtonStyle>
        )
    }

    
};
// #endregion

// #region Image Button
const ImageButtonStyle = styled.button`
    height : 150px;
    width: 150px;
    padding: 20px 10px 10px 10px;
    margin: 20px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    background-color: white;
    border: 0px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
`;

const ImageButtonImage = styled.img`
    height: 80px;
    width: 80px;
    align-self: center;
`;

const ImageButtonText = styled.span`
    font-size: 16pt;
`;

interface ImageButtonProps {
    onClick: any,
    image: any,
    text: string
}

export const ImageButton: React.FC<ImageButtonProps> = ({ onClick, image, text }) => {
    return (
        <ImageButtonStyle onClick={onClick}>
            <ImageButtonImage src={image} />
            <ImageButtonText>{text}</ImageButtonText>
        </ImageButtonStyle>
    )
}
// #endregion

// #region Small Buttons
export const SmallButton = styled.button`
    color: white;
    border: 0px solid black;
    border-radius: 20px;
    height: 25px;
    padding: 0 10px; 
    font-size: 10pt;
    font-weight: bold;
`;

export const SmallGreenButton = styled(SmallButton)`
    background-color: #44AF69;
    &:hover {
        background-color: #6CC68C;
    }
    margin: 0 10px;
`;

export const SmallRedButton = styled(SmallButton)`
    background-color: #F8333C;
    &:hover {
        background-color: #FA757C;
    }
`;
// #endregion

// #region Download Spreadsheet Button
const DownloadSpreadsheetButtonStyle = styled.button`
    background-color: #15834A;
    height: 60px;
    width: 300px;
    margin: 20px auto 10px auto;
    padding: 5px;
    border: 0px solid black;
    border-radius: 30px;
    display: grid;
    grid-template-rows: 50px 250px;
    align-items: center;
    &:hover {
        background-color: #1A9E5A
    }
`;

const IconCircle = styled.div`
    grid-column: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ExcelIcon = styled.img`
    width: 30px;
    height: 30px;
    margin-left: -5px;
`;

const DownloadSpreadsheetText = styled.span`
    grid-column: 2;
    margin: 0 10px;
    color: white;
    font-size: 14pt;
    font-weight: bold;
    cursor: pointer;
`;

const DownloadSpreadsheetLoading = styled.div`
    grid-column: 2;

    cursor: pointer;

    height : 40px;
    width: 230px;
    padding: 0px 0px;
    text-align: center;
`;

interface DownloadSpreadsheetButtonProps {
    onClick: any,
    buttonText: string,
    loading?: boolean
}

export const DownloadSpreadsheetButton: React.FC<DownloadSpreadsheetButtonProps> = ({ onClick, buttonText, loading=false }) => {
    const excelIcon = require('../../resources/excel-icon.png');
    
    if (loading) {
        return (
            <DownloadSpreadsheetButtonStyle onClick={onClick}>
                <IconCircle><ExcelIcon src={excelIcon}/></IconCircle>
                <DownloadSpreadsheetLoading><div className="loading-icon"><div></div><div></div><div></div><div></div></div></DownloadSpreadsheetLoading>
            </DownloadSpreadsheetButtonStyle>
        )
    } else {
        return (
            <DownloadSpreadsheetButtonStyle onClick={onClick}>
                <IconCircle><ExcelIcon src={excelIcon}/></IconCircle>
                <DownloadSpreadsheetText>{buttonText}</DownloadSpreadsheetText>
            </DownloadSpreadsheetButtonStyle>
        )
    }
}

// #endregion

// #region Radio Button
const RadioButtonStyle = styled.button<{$isSelected: boolean}>`
    background-color: ${props => (props.$isSelected ? COLORS.secondaryBlue : 'white')};
    color: ${props => (props.$isSelected ? 'white' : COLORS.secondaryBlue)};
    border: 3px solid ${COLORS.secondaryBlue};
    border-radius: 20px;

    height : 40px;
    width: 100%;
    padding: 0px 10px;
    margin: 20px 0px 0px 0px;

    font-size: 16pt;
    font-weight: bold;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;

    &:hover {
        background-color: ${props => (props.$isSelected ? COLORS.secondaryBlue2 : COLORS.secondaryBlue4)};
    }
`;

interface RadioButtonProps {
    onClick: any,
    isSelected: boolean,
    buttonText: string
}

export const RadioButton: React.FC<RadioButtonProps> = ({onClick, isSelected, buttonText}) => {
    return (
        <RadioButtonStyle
            onClick={onClick}
            $isSelected={isSelected}
        >
            {buttonText}
        </RadioButtonStyle>
    )
}
// #endregion