import { CapitalisationGroup, CapitalisationGroupCollection } from "../../../shared/types/CapitalisationGroup";

// #region Actions
export enum CapitalisationGroupListActionType {
    populate_list,
    filter
}

interface PopulateListAction {
    type: CapitalisationGroupListActionType.populate_list;
    allCapitalisationGroups: any;
}

interface FilterListAction {
    type: CapitalisationGroupListActionType.filter;
    textFilter: string;
}

type CapitalisationGroupListAction = PopulateListAction | FilterListAction;
// #endregion

export interface CapitalisationGroupListState {
    allCapitalisationGroups: CapitalisationGroupCollection,
    filteredCapitalisationGroups: CapitalisationGroupCollection
}

export const initialCapitalisationGroupListState: CapitalisationGroupListState = {
    allCapitalisationGroups: {},
    filteredCapitalisationGroups: {}
}

export function capitalisationGroupListReducer(state: CapitalisationGroupListState, action: CapitalisationGroupListAction) : CapitalisationGroupListState {
    switch(action.type) {
        case CapitalisationGroupListActionType.populate_list:
            const allCapitalisationGroups: CapitalisationGroup[] = action.allCapitalisationGroups;
            return {
                ...state,
                allCapitalisationGroups: allCapitalisationGroups,
                filteredCapitalisationGroups: allCapitalisationGroups
            }
        case CapitalisationGroupListActionType.filter:
            const textFilter: string = action.textFilter;

            const filteredCapitalisationGroups: CapitalisationGroup[] = Object.values(state.allCapitalisationGroups).filter(capitalisationGroup => {
                const matchId = capitalisationGroup.fluidraCapitalisationGroupId.toLowerCase().includes(textFilter.toLowerCase());
                const matchName = capitalisationGroup.name.toLowerCase().includes(textFilter.toLowerCase());
                return matchId || matchName;
            });

            return {
                ...state,
                filteredCapitalisationGroups
            }
        default:
            throw new Error(`Invalid capitalisation group list action type.`)
    }
}
