import React, { useState } from "react";
import styled from "styled-components";

import Column from "../../atoms/Column";
import Card from "../../atoms/Card";
import { DropdownEntry } from "../../molecules/DropdownEntry";
import { subtractMonth } from "../../../shared/functions/dateAndTime";
import { DropdownItems, monthsDropdownItems } from "../../../shared/types/DropdownItems";
import { DownloadSpreadsheetButton } from "../../common/Buttons";
import GenerateCapitalisationReport from "../../../reports/capitalisationReport";
import { delay } from "../../../shared/functions/functions";
import { GLOBALS } from "../../../values/globals";

const CapitalisationReport = () => {
    const currentDate: Date = subtractMonth(new Date())
    const currentMonth: number = currentDate.getMonth()
    const currentYear: number = currentDate.getFullYear();

    const [ month, setMonth ] = useState(currentMonth)
    const [ year, setYear ] = useState<number>(currentYear)
    const [ loading, setLoading ] = useState<boolean>(false)

    const startYear = 2023;
    const years: DropdownItems = {}
    for (let year = startYear; year <= currentYear; year++) {
        years[year] = year.toString();
    }

    const handleDownloadCapitalisationReport = async () => {
        setLoading(true)

        // Add a minimum loading time for user feedback
        await Promise.all([
            await GenerateCapitalisationReport(month, year),
            delay(GLOBALS.buttonLoadTime)
        ])

        setLoading(false)
    }

    return (
        <Card>
            <Column style={{height: '100%', justifyContent: 'space-between'}}>
                <Column>
                    <Title>Capitalisation Report</Title>
                    <DropdownEntry
                        required
                        showHeading
                        headingText="Month"
                        placeholder="Select a month"
                        options={monthsDropdownItems}
                        value={month}
                        setValue={(value: any) => setMonth(Number(value))} />
                    <div style={{height: '16px'}} />
                    <DropdownEntry
                        required
                        showHeading
                        headingText="Year"
                        placeholder="Select a year"
                        options={years}
                        value={year}
                        setValue={(value: any) => setYear(Number(value))} />
                </Column>
                <DownloadSpreadsheetButton
                    onClick={handleDownloadCapitalisationReport}
                    buttonText={"Download Capitalisation Report"}
                    loading={loading} />
            </Column>
        </Card>
    )
}

const Title = styled.span`
    font-size: 24pt;
    font-weight: bold;
    text-align: start;
    margin-bottom: 20px;
`;

export default CapitalisationReport;