import React, { useState, useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import 'react-dropdown/style.css';
import '../../../styles/dropdown.scss';

import { Card, Title, Heading } from '../../common/AdminWindowStyles';
import { LargeSwitch } from '../../common/CustomSwitch';
import { DeleteButton, CancelButton } from '../../common/Buttons';
import { postAddProject, postDeleteProject, postEditProject, PostAddProjectRequestBody, PostDeleteProjectRequestBody, PostEditProjectRequestBody } from '../../../api/projects';
import { AdminSubmitButton } from '../../common/FormInputs';
import { ProjectActionType, initialState, projectReducer } from './project.reducer';
import { Project, ProjectCollection } from '../../../shared/types/Project';
import { Stages } from '../../../shared/types/Stages';
import { Departments } from '../../../shared/types/Department';
import { delay } from '../../../shared/functions/functions';
import { GLOBALS } from '../../../values/globals';
import { Stage } from './Stage';
import { DropdownItems } from '../../../shared/types/DropdownItems';
import { TextEntry } from '../../molecules/TextEntry';
import { DropdownEntry } from '../../molecules/DropdownEntry';
import { MultiSelectDropdownEntry } from '../../molecules/MultiSelectDropdownEntry';

const Titles = {
    add: "Add New Project",
    edit: "Edit Project"
}

interface ProjectWindowProps {
    allProjects: ProjectCollection,
    departments: Departments, 
    stages: Stages, 
    types: DropdownItems, 
    capitalisationGroups: DropdownItems,
    capitalisableTypes: number[],
    unsavedChanges: boolean,
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>,
    refreshPage: () => void,
}


const ProjectWindow: React.FC<ProjectWindowProps> = ({ allProjects, departments, stages, types, capitalisationGroups, capitalisableTypes, unsavedChanges, setUnsavedChanges, refreshPage }) => {
    const { urlId } = useParams();
    const [ title, setTitle ] = useState(Titles.add);
    const [ state, dispatch ] = useReducer(projectReducer, initialState);
    const [ capitalisable, setCapitalisable ] = useState<boolean>(true);

    const [ loading, setLoading ] = useState(false)

    const navigate = useNavigate(); 
    
    useEffect(() => {
        setUnsavedChanges(state.unsavedChanges)
    }, [state.unsavedChanges, setUnsavedChanges])

    useEffect(() => {
        if (state.project.type in types) {
            const capitalisable = capitalisableTypes.includes(state.project.type)
            setCapitalisable(capitalisable)
            if (!capitalisable) {
                handleCapitalisationGroupChanged(0)
            }
        } else {
            setCapitalisable(true)
        }
    }, [state.project.type])

    // #region Handle property changes
    const handleActiveChanged = (active: Project['active']) => {
        dispatch({
            type: ProjectActionType.active_changed,
            active: active
        })
    }

    const handleFluidraProjectIdChanged = (fluidraProjectId: Project['fluidraProjectId']) => {
        dispatch({
            type: ProjectActionType.fluidra_project_id_changed,
            fluidraProjectId: fluidraProjectId
        })
    }
    
    const handleNameChanged = (name: Project['name']) => {
        dispatch({
            type: ProjectActionType.name_changed,
            name: name
        })
    }
    
    const handleDescriptionChanged = (description: Project['description']) => {
        dispatch({
            type: ProjectActionType.description_changed,
            description: description
        })
    }
    
    const handleTypeChanged = (projectType: Project['type']) => {
        dispatch({
            type: ProjectActionType.type_changed,
            projectType: projectType
        })
    }
    
    const handleCapitalisationGroupChanged = (capitalisationGroup: Project['capitalisationGroup']) => {
        dispatch({
            type: ProjectActionType.capitalisation_group_changed,
            capitalisationGroup: capitalisationGroup
        })
    }
    
    const handleDepartmentChanged = (departments: any) => {
        dispatch({
            type: ProjectActionType.departments_changed,
            departments: departments
        })
    }
    
    const handleStageUsedChanged = (stageId: number, used: boolean) => {
        dispatch({
            type: ProjectActionType.stage_used_changed,
            stageId: stageId,
            used: used
        })
    }
    
    const handleStageStartDateChanged = (stageId: number, startDate: Date | null) => {
        dispatch({
            type: ProjectActionType.stage_start_date_changed,
            stageId: stageId,
            startDate: startDate
        })
    }
    // #endregion
    
    // #region Load Project
    useEffect(() => {
        if (Object.values(allProjects).length > 0) loadProject()
    }, [urlId, allProjects]);

    const loadProject = () => {
        if (urlId) {
            const project = Object.values(allProjects).find(project => project.id.toString() === urlId);
            if (project) {
                setTitle(Titles.edit);
                dispatch({
                    type: ProjectActionType.load_project,
                    project: project
                })
            }            
        } else {
            setTitle(Titles.add);
            dispatch({
                type: ProjectActionType.clear_project
            })
        }
    }

    useEffect(() => {
        dispatch({
            type: ProjectActionType.create_stages,
            stages: stages
        })
    }, [stages, urlId])
    // #endregion

    //#region Button Handlers
    const addProject = async () => {
        const requestBody: PostAddProjectRequestBody = {
            fluidraProjectId: state.project.fluidraProjectId!,
            active: state.project.active!,
            name: state.project.name!,
            description: state.project.description,
            type: state.project.type,
            stages: state.project.stages,
            departments: state.project.departments,
            capitalisationGroup: state.project.capitalisationGroup!,
        }
        const response = await postAddProject(requestBody);
        if (response.name === 'AxiosError') {
            alert("An error occurred - No changes were made")
        }
    }

    const editProject = async () => {
        const requestBody: PostEditProjectRequestBody = {
            id: state.project.id,
            fluidraProjectId: state.project.fluidraProjectId!,
            active: state.project.active,
            name: state.project.name!,
            description: state.project.description,
            type: state.project.type,
            stages: state.project.stages,
            departments: state.project.departments,
            capitalisationGroup: state.project.capitalisationGroup!,
        }
        const response = await postEditProject(requestBody);
        if (response.name === 'AxiosError') {
            alert("An error occurred - No changes were made")
        }
    }

    const saveProject = async (event: any) => {       
        event.preventDefault();

        if (!stagesValid()) {
            alert("Warning: Invalid stages\nPlease fix the start dates and then try again.\nNo changes were made.")
        } else {

            console.log('Saving project');

            setLoading(true)

            // Add a minimum loading time for user feedback
            await Promise.all([
                urlId ? editProject() : addProject(),
                delay(GLOBALS.buttonLoadTime)
            ]);

            setLoading(false)

            refreshPage()

        }
    }

    const deleteProject = async (event: any) => {
        event.preventDefault();

        const prompt = `If a project is no longer used, then it should be made inactive, not deleted.\n\nOnly delete a project if it doesn't exist or was created on accident.\n\nAre you sure you want to delete this project?`
        const answer = window.confirm(prompt)
        if (answer) {
            console.log('Deleting project');

            const requestBody: PostDeleteProjectRequestBody = {
                "id": state.project.id
            }
            const response = await postDeleteProject(requestBody)
            console.log(response)
            
            navigate(`/admin/projects`);
            refreshPage()
        }
    }

    const cancel = (event: any) => {
        event.preventDefault();
        console.log('Cancelling');
        loadProject();
    }
    //#endregion

    const stagesValid = () => {
        var currentStartDate = new Date(2000, 0, 1) 
        for (const stage of state.project.stages.sort((a, b) => a.stageId - b.stageId)) {
            const thisStageUsed = stage.used
            const thisStageStartDate = stage.startDate ? new Date(stage.startDate) : null

            if (thisStageUsed && thisStageStartDate) {
                if (thisStageStartDate <= currentStartDate) {
                    return false
                } else {
                    currentStartDate = thisStageStartDate
                }
            }
        }
        return true
    }

    return (
        <Card>
            <Form onSubmit={saveProject} style={{justifyContent: 'space-between'}}>
                <Column>
                    <Row style={{justifyContent: 'space-between', marginBottom: '16px'}}>
                        {/* Title */}
                        <Title>{title}</Title>

                        {/* Active Switch */}
                        <LargeSwitch
                            id="active-switch"
                            checked={state.project.active}
                            onChange={(value: boolean) => handleActiveChanged(value)}
                            label={state.project.active ? "Active" : "Inactive"} />
                    </Row>
                                
                    <Row style={{columnGap: '16px'}}>
                        {/* Details 1 */}
                        <Column style={{rowGap: '8px'}}>
                            <Heading style={{margin: '0px'}}>Details</Heading>
                            <TextEntry
                                showHeading
                                headingText="Fluidra Project Id"
                                label="Fluidra Project Id"
                                value={state.project.fluidraProjectId}
                                setValue={(value: string) => handleFluidraProjectIdChanged(value)}
                                errorMessage='' />

                            <TextEntry
                                showHeading
                                headingText="Project Name"
                                label="Name"
                                value={state.project.name}
                                setValue={(value: string) => handleNameChanged(value)}
                                errorMessage='' />

                            <TextEntry
                                multiline
                                showHeading
                                headingText="Project Description"
                                label="Description"
                                value={state.project.description}
                                setValue={(value: string) => handleDescriptionChanged(value)}
                                errorMessage=''
                                rows={3} />

                            <DropdownEntry
                                required
                                showHeading
                                headingText='What type of project is this?'
                                placeholder="Select a type"
                                options={types}
                                value={state.project.type !== 0 ? state.project.type : null}
                                setValue={(value: number) => handleTypeChanged(Number(value))} />
                        </Column>
                    
                        {/* Details 2 */}
                        <Column style={{rowGap: '8px'}}>
                            <div style={{height: '29px'}} />
                            {capitalisable
                                ? <DropdownEntry
                                    required
                                    showHeading
                                    headingText='What capitalisation group is this project in?'
                                    placeholder="Select a capitalisation group"
                                    options={capitalisationGroups}
                                    value={state.project.capitalisationGroup}
                                    setValue={(value: number) => handleCapitalisationGroupChanged(Number(value))} />
                                : <></>}
                                
                            <MultiSelectDropdownEntry
                                required
                                showHeading
                                headingText="Which departments can log to this project?"
                                placeholder="Select one or more departments"
                                options={departments}
                                values={state.project.departments}
                                setValues={(values: number[]) => handleDepartmentChanged(values)}
                                />
                        </Column>
                    
                        {/* Stages */}
                        <Column style={{marginLeft: '32px'}}>
                            <Heading>Stages</Heading>
                            <StagesHeaderGrid>
                                <StagesHeaderItem $column={1}>Include</StagesHeaderItem>
                                <StagesHeaderItem $column={2}>Name</StagesHeaderItem>
                                <StagesHeaderItem $column={3}>Start Date</StagesHeaderItem>
                            </StagesHeaderGrid>
                                {Object.entries(stages).map(([stageId, stageName]) => (
                                    <Stage
                                        key={stageId}
                                        stageId={Number(stageId)} 
                                        stageName={stageName} 
                                        project={state.project}
                                        setStageUsed={handleStageUsedChanged}
                                        setStageStartDate={handleStageStartDateChanged} />
                                ))}
                            {/* </StagesGrid> */}
                        </Column>
                    </Row>
                </Column>

                {/* Save and Cancel Buttons */}
                <Row style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                    <DeleteButton onClick={deleteProject} />
                    <Row style={{alignItems: 'flex-end'}}>
                        <CancelButton onClick={cancel}/>
                        <AdminSubmitButton label="SAVE" loading={loading} />
                    </Row>
                </Row>
            </Form>
        </Card>
    );
}

//#region Styles
const Form = styled.form`
    display: flex;
    flex-direction: column;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const StagesHeaderGrid = styled.div`
    display: grid;
    grid-template-columns: 80px 120px 120px;
`;

const StagesHeaderItem = styled.div<{$column: number}>`
    grid-column: ${props => props.$column};
    font-weight: bold;
    align-self: center;
`
//#endregion

export default ProjectWindow;
