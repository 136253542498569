import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Column from "../../atoms/Column";
import Card from "../../atoms/Card";
import { DropdownEntry } from "../../molecules/DropdownEntry";
import { DropdownItems } from "../../../shared/types/DropdownItems";
import { DownloadSpreadsheetButton } from "../../common/Buttons";
import { delay } from "../../../shared/functions/functions";
import { GLOBALS } from "../../../values/globals";
import GenerateProjectReport from "../../../reports/projectReport";
import { getAllProjects } from "../../../api/projects";

const ProjectReport = () => {
    const [ projectId, setProjectId ] = useState<number | null>(null)
    const [ projects, setProjects ] = useState<DropdownItems>({})
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ loaded, setLoaded ] = useState(false)

    useEffect(() => {
        const getAllProjectsAsync = async () => {
            const allProjects = await getAllProjects();
            const projects: DropdownItems = {} 
            Object.values(allProjects).forEach((project) => {
                if (project.fluidraProjectId === null) project.fluidraProjectId = "" 
                if (!(project.id === -2 || project.id === 0)) {
                    projects[project.id] = `${project.fluidraProjectId} ${project.name}`
                }
            })
            setProjects(projects)
            setLoaded(true)
        }

        getAllProjectsAsync();    
    }, [])


    const handleDownloadProjectReport = async () => {
        if (projectId) {
            setLoading(true)

            // Add a minimum loading time for user feedback
            await Promise.all([
                await GenerateProjectReport(projectId),
                delay(GLOBALS.buttonLoadTime)
            ])

            setLoading(false)
        }
    }

    return (
        <Card style={{height: '320px'}}>
            <Column style={{height: '100%', justifyContent: 'space-between'}}>
                <Column>
                    <Title>Project Report</Title>
                    {loaded ? 
                        <DropdownEntry
                            required
                            showHeading
                            headingText="Project"
                            placeholder="Select a project"
                            options={projects}
                            value={projectId}
                            setValue={(value: any) => setProjectId(Number(value))} />
                        : 
                        <div style={{height: '63px', width: '320px'}} />
                    }
                </Column>
                <DownloadSpreadsheetButton
                    onClick={handleDownloadProjectReport}
                    buttonText={"Download Project Report"}
                    loading={loading} />
            </Column>
        </Card>
    )
}

const Title = styled.span`
    font-size: 24pt;
    font-weight: bold;
    text-align: start;
    margin-bottom: 20px;
`;

export default ProjectReport;