import { CapitalisationGroup, emptyCapitalisationGroup } from "../../../shared/types/CapitalisationGroup";
import { emptyEmployee } from "../../../shared/types/Employee";

// #region Actions
export enum CapitalisationGroupActionType {
    clear_capitalisation_group,
    load_capitalisation_group,
    property_changed
}

interface ClearCapitalisationGroupActionType {
    type: CapitalisationGroupActionType.clear_capitalisation_group;
}

interface LoadCapitalisationGroupActionType {
    type: CapitalisationGroupActionType.load_capitalisation_group;
    capitalisationGroup: CapitalisationGroup;
}

interface PropertyChangedActionType {
    type: CapitalisationGroupActionType.property_changed;

    id?: CapitalisationGroup['id']
    fluidraCapitalisationGroupId?: CapitalisationGroup['fluidraCapitalisationGroupId']
    name?: CapitalisationGroup['name']
    description?: CapitalisationGroup['description']
    projects?: CapitalisationGroup['projects']
}

type CapitalisationGroupAction = 
    ClearCapitalisationGroupActionType |
    LoadCapitalisationGroupActionType  |
    PropertyChangedActionType;
// #endregion

export interface State {
    capitalisationGroup: CapitalisationGroup,
    unsavedChanges: boolean,
    originalState: string
}

export const initialState: State = {
    capitalisationGroup: emptyCapitalisationGroup,
    unsavedChanges: false,
    originalState: ""
}

const checkForChanges = (currentState: State) => {
    return JSON.stringify(currentState.capitalisationGroup) !== currentState.originalState
}

export function CapitalisationGroupReducer(state: State, action: CapitalisationGroupAction): State {
    switch(action.type) {
        case CapitalisationGroupActionType.clear_capitalisation_group:
            return {
                capitalisationGroup: emptyCapitalisationGroup,
                unsavedChanges: false,
                originalState: JSON.stringify(emptyEmployee)
            }
        case CapitalisationGroupActionType.load_capitalisation_group:
            return {
                capitalisationGroup: action.capitalisationGroup,
                unsavedChanges: false,
                originalState: JSON.stringify(action.capitalisationGroup)
            }
        case CapitalisationGroupActionType.property_changed:
            const updatedState = {
                ...state,
                capitalisationGroup: {
                    ...state.capitalisationGroup,
                    id: action.id !== undefined ? action.id : state.capitalisationGroup.id,
                    fluidraCapitalisationGroupId: action.fluidraCapitalisationGroupId !== undefined ? action.fluidraCapitalisationGroupId : state.capitalisationGroup.fluidraCapitalisationGroupId,
                    name: action.name !== undefined ? action.name : state.capitalisationGroup.name,
                    description: action.description !== undefined ? action.description : state.capitalisationGroup.description,
                    projects: action.projects !== undefined ? action.projects : state.capitalisationGroup.projects,
                }
            }
            updatedState.unsavedChanges = checkForChanges(updatedState)
            return updatedState
        default:
            throw new Error(`Invalid capitalisation group action type.`)
    }
} 

