import React from "react";
import styled from 'styled-components';

import { formatDateForSQL } from '../../shared/functions/dateAndTime';
import { COLORS } from '../../values/colors';

import LogsList from './LogsList'
import { LogItem } from "../../shared/types/Logs";
import { ProjectCollection } from "../../shared/types/Project";

interface DayProps {
    log: LogItem[],
    date: Date,
    allProjects: ProjectCollection
}

const Day: React.FC<DayProps> = ({log, date, allProjects}) => {
    const notLoggedIcon = require('../../resources/incomplete-primary.png');

    const day = date.getDate();

    const isToday = formatDateForSQL(date) === formatDateForSQL(new Date());
    const isLogged = log.length > 0;
    const isFuture = date > new Date();
    const isWeekend = [0, 6].includes(date.getDay());

    if (isLogged) {
        return (
            <LoggedDay $isToday={isToday}>
                <LogsList log={log} allProjects={allProjects} />
                <DayNumber>{day}</DayNumber>
            </LoggedDay>
        )
    } else if (isWeekend || isFuture) {
        return (
            <BlankDay $isToday={isToday}>
                <DayNumber>{day}</DayNumber>
            </BlankDay>
        );
    } else {
        return (
            <NotLoggedDay $isToday={isToday}>
                <Icon src={notLoggedIcon} alt="logo" />
                <DayNumber>{day}</DayNumber>
            </NotLoggedDay>
        );
    }
}

//#region Styles
const DayStyle = styled.div<{$isToday: boolean}>`
    width: 100%; 
    height: 100%;
    min-width: 120px;
    padding: 5px; 
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    border: ${props => 
        props.$isToday 
            ? '3px solid black' 
            :  '0px'};
    padding: ${props => 
        props.$isToday
            ? '2px'
            : '5px'};
    &:hover {
        padding: ${props => 
            props.$isToday
                ? '2px'
                : '3px'};
        border: ${props => 
            props.$isToday
                ? '3px solid black'
                : '2px solid black'};
    }
`;

const BlankDay = styled(DayStyle)`
    background-color: ${COLORS.secondaryGray3};
    justify-content: start;
`;

const LoggedDay = styled(DayStyle)`
    background-color: ${COLORS.secondaryGreen4};
    justify-content: start;
`;

const NotLoggedDay = styled(DayStyle)`
    background-color: ${COLORS.secondaryYellow4};
    justify-content: center;
`;

const Icon = styled.img`
    height: 50px;
    width: 50px;
    align-self: center;
`;

const DayNumber = styled.span`
    font-size: 14px;
    position: absolute;
    bottom: 10px;
`;
//#endregion

export default Day;