import { get, post } from "../adapters/apiAdapter";
import { CapitalisationGroupCollection } from "../shared/types/CapitalisationGroup";

//#region Add Captialisation Group
export type PostAddCapitalisationGroupRequestBody = {
    fluidraCapitalisationGroupId: string,
    name: string,
    description: string
}

export async function postAddCapitalisationGroup(requestBody: PostAddCapitalisationGroupRequestBody) {
    return await post(`/api/capitalisation-groups/add`, requestBody);
}
//#endregion

//#region Edit Capitalisation Group
export type PostEditCapitalisationGroupRequestBody = PostAddCapitalisationGroupRequestBody & {
    id: number
}

export async function postEditCapitalisationGroup(requestBody: PostEditCapitalisationGroupRequestBody) {
    return await post(`/api/capitalisation-groups/edit`, requestBody);
}
//#endregion

//#region Delete Capitalisation Group
export type PostDeleteCapitalisationGroupRequestBody = {
    id: number
}

export async function postDeleteCapitalisationGroup(requestBody: PostDeleteCapitalisationGroupRequestBody) {
    return await post(`/api/capitalisation-groups/delete`, requestBody);
}
//#endregion

//#region Get Capitalisation Groups
export async function getCapitalisationGroups(): Promise<CapitalisationGroupCollection> {
    const response = await get(`/api/capitalisation-groups`);
    return response.data;
}
//#endregion