import React, { useState, useEffect, useReducer, FunctionComponent } from "react";
import { DragDropContext } from '@hello-pangea/dnd';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getAllProjects } from "../api/projects";
import { getFavouriteProjects, postFavouriteProjects, GetFavouriteProjectsRequestBody, PostFavouriteProjectsRequestBody } from "../api/favourites";
import { getEmployeeDepartment } from "../api/departments";
import { getTypes } from "../api/projects";
import { Card, Title, Instructions } from '../components/common/CommonStyles'
import { CancelButton, SaveButton, TextLink } from '../components/common/Buttons'
import AllProjectsColumn from '../components/pages/EditFavouriteProjectsPageComponents/AllProjectsColumn';
import SelectFavouriteProjectsColumn from '../components/pages/EditFavouriteProjectsPageComponents/FavouriteProjectsColumn';
import { COLORS } from '../values/colors';
import { formatDateForSQL } from "../shared/functions/dateAndTime";

import { useAuth } from '../authprovider';

import { 
    ActionType, 
    Action,
    ColumnType,
    initialState, 
    reducer, 
    State, 
} from '../components/pages/EditFavouriteProjectsPageComponents/favourites.reducer'
import { ProjectTypes } from "../shared/types/ProjectType";
import { delay } from "../shared/functions/functions";
import { GLOBALS } from "../values/globals";

interface EditFavouriteProjectsPageProps {
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    actingAs: number | null;
}

const EditFavouriteProjectsPage: FunctionComponent<EditFavouriteProjectsPageProps> = ({ setUnsavedChanges, actingAs }) => {
    const auth = useAuth()
    
    const [ state, dispatch ] = useReducer(reducer, initialState);
    const [ types, setTypes ] = useState<ProjectTypes>({0: {id: 0, name: 'All', capitalisable: true}})
    const [ textFilter, setTextFilter ] = useState('');
    const [ typeFilter, setTypeFilter ] = useState<number>(0);

    const [ loading, setLoading ] = useState<boolean>(false);

    const { returnLoggingDate } = useParams() || formatDateForSQL(new Date());

    // Runs on first load    
    useEffect(() => {      
        return () => setUnsavedChanges(false)
    }, []);

    // Tells App.js that there are unsaved changes
    useEffect(() => {
        setUnsavedChanges(state.unsavedChanges)
    }, [state.unsavedChanges, setUnsavedChanges])

    useEffect(() => {
        const getProjectsData = async () => {            
            const id = Number(actingAs ? actingAs : auth.user)
            const employeeDepartment = await getEmployeeDepartmentAsync(id);
            const allProjects = await getAllProjects();           
            const favouriteProjects = await getFavouriteProjects({id: id});
            await getTypesAsync();

            const action: Action = {
                type: ActionType.populate_state,
                employeeDepartment: employeeDepartment,
                allProjects: allProjects,
                favouriteProjects: favouriteProjects
            }

            dispatch(action)
        }
        getProjectsData();
    }, [auth.user]);

    //#region Filter all projects
    useEffect(() => {
        dispatch({
            type: ActionType.filter,
            textFilter: textFilter,
            typeFilter: typeFilter
        })
    }, [textFilter, typeFilter])
    //#endregion

    const onDragEnd = (result: any) => {    
        dispatch({
            type: ActionType.project_dragged,
            result: result
        })
    };

    // #region API Callers
    const getEmployeeDepartmentAsync = async (id : number): Promise<number> => await getEmployeeDepartment({id: id});
    const getTypesAsync = async () => setTypes({...(await getTypes()), 0: {id: 0, name: 'All', capitalisable: true}});
    // #endregion

    const navigate = useNavigate();

    // #region Button Handlers
    const cancelClicked = () => {
        console.log('Cancelling project selection');
        navigate(`/logging/${returnLoggingDate}`);
    }

    const saveClicked = async () => {
        console.log('Saving project selection...');
        const id = Number(actingAs ? actingAs : auth.user)
        const newFavourites = state.columns.favourite_projects_column.projectIds;
        const requestBody: PostFavouriteProjectsRequestBody = {
            id: id,
            favourites: newFavourites
        }

        setLoading(true)

        // Add a minimum loading time for user feedback
        await Promise.all([
            await postFavouriteProjects(requestBody),
            delay(GLOBALS.buttonLoadTime)
        ])

        setLoading(false)

        navigate(`/logging/${returnLoggingDate}`);
    }
    // #endregion

    return (
        <FavouriteProjectsCard>
            <Title>Select Favourite Projects</Title>
            <Instructions>
                Add any projects that you work on to your favourite projects.
                To do this drag and drop a project from the all projects column to the favourite project column.
                You can also rearrange your favourite projects. Don't forget to press save after you have made changes.
            </Instructions>
            <DragDropContext onDragEnd={onDragEnd}>
                <DragAndDropArea>
                    <AllProjectsColumn
                        key={'all-projects-column'}
                        state={state}
                        textFilter={textFilter}
                        setTextFilter={setTextFilter}
                        types={types}
                        typeFilter={typeFilter}
                        setTypeFilter={setTypeFilter} />
                    <ColumnSeparator />
                    <SelectFavouriteProjectsColumn 
                        key={'favourite-projects-column'}
                        state={state} />
                </DragAndDropArea>
            </DragDropContext>
            <Instructions style={{'marginTop': '10px'}}>
                Working on a project that isn't in the list?&nbsp;
                <TextLink to={`/request-project`} text="Request a project." width={200} />
            </Instructions>
            <ButtonRow>
                <CancelButton onClick={cancelClicked} />
                <SaveButton onClick={saveClicked} loading={loading} />
            </ButtonRow>
        </FavouriteProjectsCard>
    );
}


//#region Styles
const FavouriteProjectsCard = styled(Card)`
    width: min-content;
    height: min-content;
    margin-top: 40px;
`;

const DragAndDropArea = styled.div`
    display: flex;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
`;

const ColumnSeparator = styled.div`
    width: 3px;
    height: 430px;
    border-left: 3px dashed ${COLORS.primary4};
    margin: 20px 20px 0 20px;
`;
//#endregion

export default EditFavouriteProjectsPage;