import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { formatDate, formatDateForSQL } from '../../../shared/functions/dateAndTime';
import { Separator, ScrollableAreaFadeTop, ScrollableAreaFadeBottom } from '../../common/CommonStyles';
import { BlueButton } from '../../common/Buttons';
import DateButton from './DateButton';
import { LogItem } from '../../../shared/types/Logs';
import '../../../styles/scrollbar.scss'

interface DateListProps {
    dates: Date[],
    onSelectDate: (date: Date) => void,
    selectedDate: Date,
    calendarButtonClicked: () => void,
    logs: {[key: string]: LogItem[]}
}

const DateList: React.FC<DateListProps> = ({ dates, onSelectDate, selectedDate, calendarButtonClicked, logs }) => {
    const scrollableArea = useRef(null);
    const [ scrollFadeTopPosition, setScrollFadeTopPosition ] = useState(-20);
    
    // Add an event listener to track the scroll position
    useEffect(() => {
        const handleScroll = () => {
            const scrollable: any = scrollableArea.current;
            if (scrollable) setScrollFadeTopPosition(Math.min(0, scrollable.scrollTop - 20));
        };

        const scrollable: any = scrollableArea.current;
        if (scrollable) scrollable.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollable) scrollable.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleButtonClick = (date: Date) => {
        onSelectDate(date);
    };
      
    return (
        <DateListCard className='scrollableList'>
            <DateListArea>
                <ScrollableAreaFadeTop $position={scrollFadeTopPosition} />
                <DateListStyle ref={scrollableArea}>
                    {dates.map((date, index) => (
                    <li key={index}>
                        <DateButton 
                            date={date}
                            onSelectDate={handleButtonClick} 
                            buttonSelected={formatDate(selectedDate) === formatDate(date) ? true : false}
                            isLogged={logs?.hasOwnProperty(formatDateForSQL(date))}
                        />
                    </li>
                    ))}
                </DateListStyle>
                <ScrollableAreaFadeBottom />
            </DateListArea>
            <Separator />
            <BlueButton
                onClick={calendarButtonClicked}
                buttonText="Select from Calendar" />
        </DateListCard>
    );
}

//#region Styles
const DateListCard = styled.div`
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    margin: 10px;
    padding: 20px 6px 20px 20px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
`;

const DateListArea = styled.div`
    position: relative;
    left: 0;
    width: 284px;
    height: calc(100vh - 260px);
`

const DateListStyle = styled.ul`
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    background-color: white;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
`;
//#endregion

export default DateList;