import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';

import { COLORS, FLUIDRA_COLORS } from '../../../values/colors';
import { TextEntry } from '../../molecules/TextEntry';
import Column from '../../atoms/Column';
import Tooltip from '../../atoms/Tooltip';
import 'reactjs-popup/dist/index.css';

interface NotesButtonProps {
    id: number,
    notes: string | null,
    onNotesChange: (id: number, notes: string) => void,
    projectId: string | null,
    projectName: string | null
}

const NotesButton: FunctionComponent<NotesButtonProps> = ({ id, notes="", onNotesChange, projectId, projectName }) => {
    const notesIcon = require('../../../resources/notes-icon.png')

    const [ isOpen, setIsOpen ] = useState<boolean>(false)

    const closePopup = () => setIsOpen(false)
    const openPopup = () => setIsOpen(true)

    const updateNotes = (notes: any) => onNotesChange(id, notes) 
    const clearNotes = () => onNotesChange(id, "")

    return (
        <StyledPopup 
            trigger={
                <Button>
                    <Tooltip text={notes} offsetY={60}>
                        <IconCircle $hasNotes={notes !== null && notes.length > 0}>
                            <Icon src={notesIcon} alt="notes" />
                        </IconCircle>
                    </Tooltip>
                </Button>
            } 
            modal
            open={isOpen}
            onOpen={openPopup}
        >
            <Column>
                <span><b>Project Id:</b> {projectId}</span>
                <span style={{maxWidth: '320px'}}><b>Project Name:</b> {projectName}</span>
                <div style={{height: '16px'}} />
                <TextEntry 
                    multiline
                    rows={4}
                    showHeading
                    headingText="Notes"
                    label="Enter notes about this log"
                    errorMessage=''
                    value={notes}
                    setValue={updateNotes}
                    />
                    <div style={{width: '100%', textAlign: 'right'}}><ClearButton onClick={clearNotes}>CLEAR</ClearButton></div>
                    <DoneButton onClick={closePopup}>DONE</DoneButton>
            </Column>
        </StyledPopup>
    )
}

// #region Styles
const Button = styled.button`
    background: none;
    border: none;
    margin: 0px;
    padding: 0px;
`;

const IconCircle = styled.div<{$hasNotes: boolean}>`
    height: 48px;
    width: 48px;
    background-color: ${COLORS.secondaryGray2};
    border: ${props => (props.$hasNotes ? '2px solid ' + FLUIDRA_COLORS.teal_500 : 'none')};
    border-radius: 30px;
    margin: 0px;
    padding: ${props => (props.$hasNotes ? '2px' : '4px')};

    &:hover {
        background-color: ${COLORS.secondaryGray}
    }
`;

const Icon = styled.img`
    height: 48px;
    width: 48px;
`;

const StyledPopup = styled(Popup)`
    &-content {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
        padding: 16px !important;
        background-color: white;
        border: none !important;
        border-radius: 20px;
        display: flex;
        flex-direction: column;

        width: fit-content !important;
    }    
`;

const ClearButton = styled.button`
    height: 24px;
    width: 60px;
    margin-top: 8px;
    border: none;
    border-radius: 12px;
    background-color: ${FLUIDRA_COLORS.darkblue_50};
    color: ${FLUIDRA_COLORS.darkblue_500};
    font-weight: bold;
`;

const DoneButton = styled.button`
    height: 40px;
    width: 100%;
    padding: 0px 10px;
    margin-top: 16px;

    color: white;
    font-size: 16pt;
    font-weight: bold;

    border: none;
    border-radius: 20px;

    cursor: pointer;

    background-color: ${COLORS.secondaryBlue};
    &:hover {
        background-color: ${COLORS.secondaryBlue2};
    }
`;
// #endregion

export default NotesButton;