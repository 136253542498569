import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField'

import { FLUIDRA_COLORS } from '../../values/colors'
import '../../styles/loading.scss'
import { EntryHeading } from '../atoms/EntryHeading';

export const TextEntryTheme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: FLUIDRA_COLORS.darkblue_100
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: FLUIDRA_COLORS.darkblue_500,
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: FLUIDRA_COLORS.teal_500,
                    },
                    fontSize: '12px',
                    padding: '11.375px 16px',
                    margin: '0px',
                    width: '320px',
                    borderRadius: '20px',
                    color: FLUIDRA_COLORS.darkblue_500,
                },
                input: {
                    padding: '0px'
                },
            }
        },
    }
});

interface TextEntryProps {
    showHeading: boolean,
    headingText?: string,
    multiline?: boolean,
    value: string | null,
    setValue: (value: string) => void,
    label?: string,
    error?: boolean,
    errorMessage?: string,
    autoComplete?: string,
    onClick?: any,
    rows?: number
}

export const TextEntry: React.FC<TextEntryProps> = ({ showHeading, headingText="", multiline=false, value, setValue, label="", error=false, errorMessage=" ", autoComplete="", onClick=()=>{}, rows=1 }) => {   
    const [ _value, _setValue ] = useState<string>("");
    
    const updateValue = (_value: string) => {
        _setValue(_value);
        setValue(_value)
    }
    
    useEffect(() => {
        _setValue(value ? value : "")
    }, [value])

    return (
        <ThemeProvider theme={TextEntryTheme}>
            <Column>
                <EntryHeading showHeading={showHeading} headingText={headingText} />
                <TextField
                    type="input"
                    autoComplete={autoComplete}
                    multiline={multiline}
                    rows={rows}
                    error={error}
                    helperText={errorMessage}
                    onClick={onClick}
                    placeholder={label}
                    value={_value}
                    onChange={e => updateValue(e.target.value)} />
                    {/* onChange={e => setValue(e.target.value)} /> */}
            </Column>
        </ThemeProvider>
    )
}

const Column = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`;