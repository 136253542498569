const DAYS = {
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
} as const;

type ObjectValues<T> = T[keyof T];

export type Days = ObjectValues<typeof DAYS>;

export const daysList = Object.values(DAYS);