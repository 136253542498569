import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';

export const ColToLetter = (col: number) => {
    let columnLetter = '';
  
    while (col > 0) {
        const remainder = (col - 1) % 26;
        columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
        col = Math.floor((col - 1) / 26);
    }
  
  return columnLetter;
}


export const rowAndColNumToCell = (row: number, col: number, absolute=false) => {
    if (absolute) {
        return `$${ColToLetter(col)}$${row}`
    } else {
        return `${ColToLetter(col)}${row}`
    }
}


export const downloadExcelFile = (buffer: ExcelJS.Buffer, filename: string) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const data = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, filename + fileExtension)
} 

export const createOuterBorder = (worksheet: ExcelJS.Worksheet, start = {row: 1, col: 1}, end = {row: 1, col: 1}, borderWidth: ExcelJS.BorderStyle = 'medium') => {

    const borderStyle: Partial<ExcelJS.Border> = {
        style: borderWidth
    };
    for (let i = start.row; i <= end.row; i++) {
        const leftBorderCell = worksheet.getCell(i, start.col);
        const rightBorderCell = worksheet.getCell(i, end.col);
        leftBorderCell.border = {
            ...leftBorderCell.border,
            left: borderStyle
        };
        rightBorderCell.border = {
            ...rightBorderCell.border,
            right: borderStyle
        };
    }

    for (let i = start.col; i <= end.col; i++) {
        const topBorderCell = worksheet.getCell(start.row, i);
        const bottomBorderCell = worksheet.getCell(end.row, i);
        topBorderCell.border = {
            ...topBorderCell.border,
            top: borderStyle
        };
        bottomBorderCell.border = {
            ...bottomBorderCell.border,
            bottom: borderStyle
        };
    }
};