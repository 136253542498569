import styled from 'styled-components';

export const Card = styled.div`
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 8px 4px;
    margin: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    display: grid;
`;

export const Title = styled.span`
    font-size: 24pt;
    font-weight: bold;
    text-align: start;
`;

export const Heading = styled.span`
    font-size: 16pt;
    font-weight: bold;
    text-align: start;
    margin-bottom: 10px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-self: flex-end;
`;

export const EntryLabel = styled.span`
    width: 180px;
`;

export const CustomInput = styled.input`
    width: 228px; 
    margin: 0px;
    padding: 8px 10px;
    border: 1px solid #9B9B9B;
    border-radius: 2px;
`;

export const RightAlignedRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-self: flex-end;
`;