import React from "react";
import styled from 'styled-components';

import '../App.css';
import { Page, Card, Title } from "../components/common/CommonStyles";
import { COLORS } from "../values/colors";
import { test } from "../adapters/apiAdapter";

const SignInPage = () => {
    const fluidraMyIdImage = require('../resources/fluidra-my-id.png');

    const callTestEndpoint = async () => {
        await test()
    }

    return (
        <Page>
            <SignInCard>
                <Title>Sign in</Title>
                <form action="/auth/signin" method="post">
                    <SignInButton type="submit">
                        <SignInButtonImage src={fluidraMyIdImage} />
                    </SignInButton>
                </form>
            </SignInCard>
        </Page>      
    )
}

//#region Styles
const SignInCard = styled(Card)`
    width: 400px;
    height: min-content;
    margin-top: 100px;
    padding: 40px;
`;

const SignInButton = styled.button`
    height : 60px;
    width: 100%;
    padding: 0px 10px;
    margin: 10px 0px;

    color: white;
    font-size: 16pt;
    font-weight: bold;

    border: none;
    border-radius: 30px;

    cursor: pointer;

    background-color: ${COLORS.primary4};
    &:hover {
        background-color: ${COLORS.primary3};
    }
`

const SignInButtonImage = styled.img`
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
`
//#endregion

export default SignInPage;