import React, { FunctionComponent, useState } from 'react'
import { useTimezoneSelect, allTimezones, ITimezoneOption, ITimezone } from 'react-timezone-select'
import { EntryHeading } from '../atoms/EntryHeading'
import Column from '../atoms/Column'
import { DropdownTheme } from './DropdownEntry'
import { ThemeProvider } from '@mui/material'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const labelStyle = "original"
const timezones = {...allTimezones}

interface TimezoneSelectProps {
    timezone: ITimezone,
    setTimezone: React.Dispatch<React.SetStateAction<ITimezone>>,
    heading: string,
}

const TimezoneSelect: FunctionComponent<TimezoneSelectProps> = ({ timezone, setTimezone, heading="Timezone" }) => {
    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })

    const onChange = (timezone: ITimezone) => {
        setTimezone(timezone)
    }

    return (
        <ThemeProvider theme={DropdownTheme}>
            <Column gap={4}>
                <EntryHeading showHeading={true} headingText={heading} />
                <FormControl style={{'position': 'relative'}}>
                    <Select
                        required={true}
                        value={timezone}
                        onChange={e => onChange(e.target.value)}>
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Column>
        </ThemeProvider>
    )
}

export default TimezoneSelect;